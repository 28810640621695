<template>
  <v-container fluid class="ma-0 pa-0">
    <router-view></router-view>
    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
        Uma atualização está disponível!
      <v-btn text @click="refreshApp">
        Atualizar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import update from './mixins/update'

export default {
  components: {
  },
  mixins: [update],
  data: () => ({
  }),
};
</script>
