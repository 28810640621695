<template>
    <v-container fluid v-if="!loading">
        <v-row>
            <v-col cols="12">
            <v-row v-if="$root.session.hasRole(['ceo']) || $root.session.hasRole(['super']) || $root.session.hasRole(['dto'])">
                <v-col cols="12">
                    <span class="text-h6">Filtrar por:</span>
                </v-col>
                <v-col cols="12" md="auto" >
                    <v-autocomplete
                    v-model="filter.encarregado_id"
                    label="Encarregado"
                    dense outlined
                    item-value="id"
                    :items="commissioners"
                    :item-text="item => item.name"
                    hide-selected
                    clearable
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="auto"  v-if="$root.session.hasRole(['ceo']) || $root.session.hasRole(['super'])">
                    <v-autocomplete
                    v-model="filter.dto_id"
                    label="DTO"
                    dense outlined
                    item-value="id"
                    :items="engineers"
                    :item-text="item => item.name"
                    hide-selected
                    clearable
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="auto">
                    <v-btn class="success" @click="reloadGraphs++">
                    <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            </v-col>
            <v-col cols="12">
                <ObrasCurso :key="reloadGraphs" :filter="filter"/>
            </v-col>
            <v-col cols="12">
                <ObrasTerminadas :key="reloadGraphs" :filter="filter"/>
            </v-col>
        </v-row>

        <v-row :key="reloadGraphs">
            <v-col cols="12">
                <v-card>
                    <v-card-title class="primary white--text">Total de performances Obras em curso</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <Chart                                    
                                    class="mt-16"
                                    id="first-chart"
                                    :options="globalWorkOptions"
                                    :series="globalWorkSeries"
                                    height="200"
                                    width="100%"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" v-if="false">
                <v-card>
                    <v-card-title class="primary white--text">Total de performances Obras em curso</v-card-title>
                    <v-card-text>
                        <TotalPerformance work_status="STARTED" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" >
                <v-card>
                    <v-card-title class="primary white--text">Total de performances Obras terminadas</v-card-title>
                    <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <Chart
                                        class="mt-16"
                                        id="first-chart"
                                        :options="globalWorkOptions"
                                        :series="finishedGlobalWorkSeries"
                                        height="200"
                                        width="100%"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
            </v-col>
            <v-col cols="12" v-if="false">
                <v-card>
                    <v-card-title class="primary white--text">Total de performances Obras terminadas</v-card-title>
                    <v-card-text>
                        <TotalPerformance work_status="FINISHED" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <!-- CARTOES COM CONTADORES -->
        <v-row v-if="showWorkCounters">
            <v-col cols="12" md="4" lg="3">
                <v-card class="mt-4 mb-4">
                    <v-card-title class="text-subtitle-1 font-weight-bold">Obras DTO / Total Obras</v-card-title>
                    
                    <v-card-text>
                        <h3>{{worksCounters.user_works}} / {{worksCounters.total_works}}</h3>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="3">
                <v-card class="mt-4 mb-4">
                    <v-card-title class="text-subtitle-1 font-weight-bold">Iniciadas DTO / Total</v-card-title>
                    <v-card-text>
                        <h3>{{worksCounters.total_initiated_works_user}} / {{worksCounters.total_initiated_works}}</h3>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="3">
                <v-card class="mt-4 mb-4">
                    <v-card-title class="text-subtitle-1 font-weight-bold">Por Iniciar DTO / Total</v-card-title>
                    <v-card-text>
                        <h3>{{worksCounters.total_to_init_works_user}} / {{worksCounters.total_to_init_works}}</h3>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" lg="3">
                <v-card class="mt-4 mb-4">
                    <v-card-title class="text-subtitle-1 font-weight-bold">Terminadas DTO / Total</v-card-title>
                    <v-card-text>
                        <h3>{{worksCounters.total_ended_works_user}} / {{worksCounters.total_ended_works}}</h3>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- CARTOES COM CONTADORES -->

        <!-- PARTES DIARIAS -->
        <v-row v-if="showDiariesCounters">
            <v-col cols="12">
                <v-toolbar class="primary rounded-pill">
                    <span class="white--text text-h6 font-weight-bold">Partes diárias</span>
                </v-toolbar>            
            </v-col>
            <v-col cols="12" md="3">
                <v-card class="mt-4 mb-4">
                    <v-card-title class="text-subtitle-1 font-weight-bold">Total Partes Diárias</v-card-title>
                    <v-card-text>
                        <h3>{{diariesCounters.total}}</h3>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card class="mt-4 mb-4">
                    <v-card-title class="text-subtitle-1 font-weight-bold">Aprovadas</v-card-title>
                    <v-card-text>
                        <h3>{{diariesCounters.finished}}</h3>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card class="mt-4 mb-4">
                    <v-card-title class="text-subtitle-1 font-weight-bold">Por Aprovar</v-card-title>
                    <v-card-text>
                        <h3>{{diariesCounters.total- diariesCounters.finished}}</h3>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="3" v-if="$root.session.hasRole(['encarregado', 'dto'])">
                <v-card class="mt-4 mb-4">
                    <v-card-title class="text-subtitle-1 font-weight-bold">Score</v-card-title>
                    <v-card-text>
                        <h3>{{$root.session.score}}</h3>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- PARTES DIARIAS -->


        <!-- PARTES DIARIAS EM ATRASO -->
        <v-row v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['dto']) || $root.session.hasRole(['ceo'])">
            <v-col cols="12">
                <v-toolbar class="primary rounded-pill">
                <span class="white--text text-h6 font-weight-bold">Partes diárias em atraso (48 horas)</span>
                </v-toolbar>            
            </v-col>
            <v-col cols="12" md="auto">
                <v-autocomplete
                v-model="filter.commissioner_id"
                :items="commissioners"
                menu-props="auto"
                label="Encarregados"
                item-text="name"
                item-value="id"
                persistent-hint
                prepend-icon="mdi-account"
                clearable
                single-line
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="auto">
                <v-btn color="primary" @click="searchDelayedDiaries">
                <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table
                :headers="headers"
                :items="delayedDiaries"
                :server-items-length="totalDelayed"
                :loading="loadingDelayedDiaries"
                :footer-props="tableFooter"
                :options.sync="options"
                locale="pt-pt"
                >

                <template v-slot:item.actions="{ item }">
                    <IconView
                        class="mr-2"
                        @click="$router.push('/admin/diaries/create/' + item.work.id + '/' + item.missing_day)"
                    />
                </template>


                </v-data-table>
            </v-col>
        </v-row>
    <!-- PARTES DIARIAS EM ATRASO -->
        


    </v-container>
</template>
<script>
import ObrasCurso from '@/components/dashboard/internals/ObrasCurso.vue'
import ObrasTerminadas from '@/components/dashboard/internals/ObrasTerminadas.vue'
import Chart from '@/components/ui/Chart.vue';
import DashboardV2 from '@/api/DashboardV2.js'
import DelayedDiaries from '@/api/DelayedDiaries.js'
import IconView from '@/components/ui/IconView.vue'
import TotalPerformance from '@/components/dashboard/works/TotalPerformance.vue'

export default{
    name: "WorksTab",
    components:{
        ObrasCurso,
        ObrasTerminadas,
        Chart,
        IconView,
        TotalPerformance
    },
    props:{
        engineers: Array,
        commissioners: Array
    },
    data(){
        return{
            loading: true,
            loadingDelayedDiaries: true,
            dashboardStatistics: null,
            worksCounters: null,
            diariesCounters: null,
            tableFooter: {
                'items-per-page-options': [5, 10, 15, 20],
                'items-per-page-text': "Linhas por página"
            },
            options: {},
            delayedDiaries: [],
            totalDelayed: 0,
            filter:{
                dto_id: null,
                encarregado_id: null,
            },
            reloadGraphs: 0,
            headers: [
                {
                    text: 'Obra',
                    sortable: true,
                    value: 'work.name',
                },
                {
                    text: 'Dia',
                    sortable: true,
                    value: 'missing_day',
                },   
                {
                    text: 'Opções',
                    value: 'actions',
                },          
            ],
        }
    },
    mounted(){
        this.fillBaseData();

    },
    methods:{
        async fillBaseData(){
            await DashboardV2.getWorksTab({}).then((resp) =>  {
                this.dashboardStatistics = resp.data
            })

            DashboardV2.getWorksCounters({}).then((resp)    =>   {
                this.worksCounters = resp.data
            })

            DashboardV2.getDiaryCounters({}).then((resp)    =>   {
                this.diariesCounters = resp.data
            })

            this.searchDelayedDiaries()

            this.loading = false
        },        
        searchDelayedDiaries(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }

            this.loadingDelayedDiaries = true;

            let filter = {...this.filter};

            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
            };
        
            Object.assign(request, filter);

            localStorage["delayed-diaries-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });

            DelayedDiaries.search(request).then(response => {

                let data = response.data;

                this.totalDelayed = data.total;


                this.delayedDiaries = data.data.map((item) => {

                    return item;
                });

                this.loadingDelayedDiaries = false;

            });
        },
    },
    computed:{        
        globalWorkSeries(){
            if(this.dashboardStatistics == null)
                return []
                
            return [{
                data: [this.dashboardStatistics.average_works_course]
            }]
        },
        finishedGlobalWorkSeries(){
            if(this.dashboardStatistics == null)
                return []
                
            return [{
                data: [this.dashboardStatistics.average_works_finished]
            }]
        },        
        globalWorkOptions(){
            return {
                chart: {
                type: 'bar',
                height: 350
                },
                plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: false,
                }
                },
                dataLabels: {
                    enabled: true,
                    offsetX: -6,
                    textAnchor: 'end',
                    formatter: function (val) {
                        if(val == 0)
                            return ''
                        return val + '%'
                    },
                    style: {
                        fontSize: '12px',
                        colors: ['#fff'],
                    }
                },
                xaxis: {
                    categories: ['Total'],
                }
            }
        },
        showWorkCounters(){
            if(this.worksCounters == null)
                return false;

            return this.$root.session.hasPermission(['super']) || this.$root.session.hasRole(['dto']) || this.$root.session.hasRole(['ceo'])
        },
        showDiariesCounters(){
            if(this.diariesCounters == null)
                return false;

            return this.$root.session.hasPermission(['super']) || this.$root.session.hasRole(['dto']) || this.$root.session.hasRole(['ceo'])
        }
    }
}
</script>