<template>
    <v-container fluid>
        <div id="chart" v-if="!loading">
            <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
    </v-container>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import DashboardV2 from '@/api/DashboardV2.js'

export default{
    name: "TotalPerformance",
    components:{
        apexchart: VueApexCharts
    },
    props:{
        work_status: String,
    },
    data(){
        return{
            data: [],
            loading: true
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        fillBaseData(){
            DashboardV2.getWorksTotalPerformance({status: this.work_status}).then((resp)    =>  {
                this.data = resp.data

                this.loading = false
            })
        }
    },
    computed:{
        chartOptions(){
            let current_date = new Date();

            let current_year = current_date.getFullYear();
            let ob = {
                chart: {
                height: 350,
                type: 'bar',
                },
                plotOptions: {
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                    position: 'center', // top, center, bottom
                    },
                }
                },
                dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val + "%";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
                },
                
                xaxis: {
                categories: [current_year-2, current_year-1, current_year],
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: true
                },
                crosshairs: {
                    fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                    }
                },
                tooltip: {
                    enabled: true,
                }
                },
                yaxis: {
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: true,
                },
                labels: {
                    show: false,
                    formatter: function (val) {
                    return val + "%";
                    }
                }
                
                },
            }     
                  
            return ob
        },
        series(){

            return [{
                name: 'Performance',
                data: this.data
            }]
        }
        
    }
}
</script>