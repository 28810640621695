import { openDB } from 'idb';

const DB_NAME = 'gpd-idb';
const DB_VERSION = 2;

const initDB = async () => {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('diaries')) {
        db.createObjectStore('diaries', { keyPath: 'id', autoIncrement: true });
      }
    },
  });
  return db;
};

// DIARIES

export const saveDiaryOffline = async (diary) => {
  const db = await initDB();
  if (diary.id == null) {
    delete diary.id;
  }
  await db.put('diaries', diary);
};

export const getDiariesOffline = async () => {
  const db = await initDB();
  return await db.getAll('diaries');
};

export const clearDiariesWorks = async () => {
  const db = await initDB();
  await db.clear('diaries');
};

export const deleteDiaryOffline = async (id) => {
  const db = await initDB();
  await db.delete('diaries', id);
};
