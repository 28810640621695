<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <v-form @submit.prevent="submit"  class="mb-16">
       <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>
      <fieldset :disabled="loading">
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
              <v-text-field
                v-model="fields.name"
                prepend-inner-icon="mdi-form-textbox"
                label="Nome *"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="value" name="Custo" rules="required|min_value:0">
              <v-currency-field
                  v-model="fields.value"
                  prepend-inner-icon="mdi-currency-eur"
                  label="Custo *"
                  clearable
                  :error-messages="errors"
                />
              </validation-provider>
          </v-col>
          <v-col cols="12" md="2">
            <validation-provider v-slot="{ errors }" vid="lunch" name="Hora de almoço" rules="between_hour">
              <v-text-field
                  v-model="fields.lunch_hour"
                  prepend-inner-icon="mdi-timer"
                  type="number"
                  label="Hora de almoço"
                  clearable
                  :error-messages="errors"
                />
              </validation-provider>
          </v-col>
        </v-row>
        <v-row class="mt-6" align="center" justify="space-around">
          <v-col align="center" justify="space-around">
            <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
              Gravar
            </v-btn>
          </v-col>
        </v-row>
      </fieldset>
    </v-form>
    <DialogSuccess :opened="success" @on-ok="onSuccess" >
        Categoria gravada com sucesso!
    </DialogSuccess>
  </validation-observer>
</template>
<script>
import Category from "@/api/Category.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default {
  components: {
    DialogSuccess
  },
  props: {
   category: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
    category: {
      immediate: true, 
      handler (val) {
        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;
      }
    },
  },
  beforeCreate(){
  },
  data: () => ({
    fields: {
      id: null,
      name:null,
      value:null,
      lunch_hour: null
    },
    loading:false,
    error: {
      title: '',
      message: '',
    },
    success: false,
  }),
  methods:{
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/admin/workers-settings/categories');
    },
    submit() {

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        Category[this.method](this.fields, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    
  }
};
</script>
