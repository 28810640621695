import BaseApi from "./BaseApi";

export default class DelayedDiaries extends BaseApi{
    construct() {}
  

    static async search(params) {
        return await DelayedDiaries.getAxiosInstance().get(DelayedDiaries.getApiUrl(),{params: params});
    }


    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/delayed-diaries/";
    }
}