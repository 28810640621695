import BaseApi from "./BaseApi";

export default class Score extends BaseApi{
    construct() {}

    static async search(params) {
        return await Score.getAxiosInstance().get(Score.getApiUrl(),{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/scores/";
    }
}