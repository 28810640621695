<template>
    <span>
      <Tooltip
        class="my-4 mr-4"
        color="success"
        :text="text"
        top
      >
        <v-icon
          small
          color="warning"
          v-bind="$attrs"
          v-on="$listeners"
          @click="$emit('click')"
        >
          mdi-briefcase-download
        </v-icon>
      </Tooltip>
    </span>
  </template>
  
  <script>
    import Tooltip from '@/components/ui/Tooltip.vue';
  
    export default {
      name: 'uiIconDownload',
      props: {
        text: String
      },
      components: {
        Tooltip
      },
    }
  </script>
  
  <style lang="scss" scoped>
  </style>