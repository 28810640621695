var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-32"},[_c('div',{staticStyle:{"position":"relative","margin-top":"6rem"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-date-picker',{attrs:{"full-width":"","event-color":"purple lighten-1","events":_vm.getEvents},on:{"click:date":_vm.contextMenu},model:{value:(_vm.selectedDay),callback:function ($$v) {_vm.selectedDay=$$v},expression:"selectedDay"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"selectedDay","name":"Dia selecionado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":true,"label":"Dia selecionado","clearable":"","error-messages":errors},model:{value:(_vm.selectedDay),callback:function ($$v) {_vm.selectedDay=$$v},expression:"selectedDay"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"type","name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.tiposRegisto,"menu-props":"auto","label":"Tipo de registo","prepend-icon":"mdi-hard-hat","single-line":"","error-messages":errors},model:{value:(_vm.registerType),callback:function ($$v) {_vm.registerType=$$v},expression:"registerType"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"description","name":"Descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Descrição do evento","error-messages":errors,"clearable":""},model:{value:(_vm.eventDescription),callback:function ($$v) {_vm.eventDescription=$$v},expression:"eventDescription"}})]}}],null,true)})],1)],1)],1),(_vm.$root.session.hasPermission(['super', 'calendar.create', 'calendar.update']))?_c('v-card-text',[_c('v-btn',{attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Registar ")])],1):_vm._e()],1)],1)],1)],1),_c('v-card-title',[_vm._v(" Feriados registados ")]),_c('v-card-text',[(_vm.events.length > 0)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Dia ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Tipo ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Evento ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Opções ")])])]),_c('tbody',_vm._l((_vm.events),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.day_event))]),_c('td',[_vm._v(_vm._s(item.type))]),_c('td',[_vm._v(_vm._s(item.event))]),_c('td',[(_vm.$root.session.hasPermission(['super', 'calendar.delete']))?_c('IconRemove',{staticClass:"mr-2",on:{"on-submit":function($event){return _vm.deleteEvent(item)}}}):_vm._e()],1)])}),0)]},proxy:true}],null,true)}):_c('span',[_vm._v("Sem eventos registados")])],1)],1)]}}])})],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":function($event){_vm.success = false}}},[_vm._v(" "+_vm._s(_vm.msgSuccess)+" ")]),_c('ErrorSnackbar',{attrs:{"message":_vm.error.message},model:{value:(_vm.error.show),callback:function ($$v) {_vm.$set(_vm.error, "show", $$v)},expression:"error.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }