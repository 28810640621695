<template>
    <ValidationProvider :vid="vid" :name="$attrs.label" :rules="rules" v-slot="{errors}">
        <v-autocomplete
            v-bind="$attrs"
            v-on="$listeners"
            color="primary"
            :error-messages="errors"
        />
    </ValidationProvider>
</template>
<script>
export default {
    props: {
        rules: String,
        vid: String,
    },
    computed: {
    },
    methods: {
    }
}
</script>
