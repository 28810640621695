<template>
  <section class="mt-16">       
    <v-card v-if="!loading">
        <v-card-title class="primary white--text">
          Alertas de manutenção
        </v-card-title>
        <v-card-text>
          <v-simple-table height="300px" v-if="messages.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center"></th>
                  <th class="text-left">
                    Código
                  </th>
                  <th class="text-left">
                    Máquina
                  </th>
                  <th class="text-left">
                    Próx. Manutenção
                  </th>
                  <th class="text-left">
                    Mensagem
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in messages"
                  :key="item.machine"
                >
                  <td>
                    <IconView
                      class="mr-2 ml-2 mt-4"
                      @click="openMachine(item)"
                    />
                  </td>
                  <td>
                    {{ item.code }}
                  </td>
                  <td>{{ item.machine }}</td>
                  <td>{{ item.time_alert }}</td>
                  <td>{{ item.msg }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <span v-else>Sem dados a apresentar</span>
        </v-card-text>
      </v-card>
  </section>
</template>
<script>
import Dashboard from "@/api/Dashboard.js";
import IconView from '@/components/ui/IconView.vue';
  

export default {
  name:"Maintenance",
  components:{
    IconView
  },
  data(){
    return{
      messages:[],
      loading: true
    }
  },
  mounted(){
    this.fillBaseData()
  },
  methods:{
    openMachine(m){
      this.$router.push('/admin/machines/' + m.machine_id)
    },
    fillBaseData(){
      this.loading = true

      Dashboard.getMaintenance({}).then((resp)  =>  {
        this.messages = resp.data

        this.loading = false
      })

    }
  }
}
</script>
  