<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-hard-hat</v-icon> <span>Edição de Obra</span> {{ getWorkName }}
      </h1>
    
    

      <div class="mt-16">
        <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>

        <template v-if="loading">
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-skeleton-loader
                type="button"
              ></v-skeleton-loader>
          </v-row>
        </template>
        <work v-else method="update" @update-object="getWork" :work="work"></work>
      </div>
  </section>
</template>
<script>
import Work from '@/components/works/Work'
import WorkApi from "@/api/Work.js";

export default {
  components: {
    Work
  },
  beforeCreate(){
    document.title = "GPD - Edição de Obras";
  },
  data: () => ({
      work: {},
      loading:true
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "works.update"])) {
      this.$router.push('/admin');
    }

    this.getWork()
  },
  methods:{
    getWork(){
      
      WorkApi.find(this.$route.params.id).then(({data}) => { 
        this.work = data; 
        this.loading = false;
        let ar = []
        if(this.work.methereology != null)
          this.work.methereology.forEach(element => {
            ar.push(JSON.parse(element.metadata))
          });

        let dates_auxiliar = this.work.methereology
        let i = 0;        
        this.work.methereology = []
        ar.forEach(element => {
          this.work.methereology.push(
            {
              date: dates_auxiliar[i].date,
              icon: element.day.condition.icon,
              maxtemp_c: element.day.maxtemp_c,
              mintemp_c: element.day.mintemp_c,
              condition: element.day.condition.text,
              totalprecip_mm: element.day.totalprecip_mm,
              maxwind_kph: element.day.maxwind_kph
            }
          )
          i++

        });
      });
    }
  },
  computed: {
    getWorkName(){
      if(this.work == null)
        return ''

      if(this.work == undefined)
        return ''

      if(this.work.name == undefined)
        return ''

      return ' - ' + this.work.name
    },
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Obras',
          disabled: false,
          to: '/admin/works-settings/works',
          exact: true,
        },
        {
          text: 'Edição de Obra',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
