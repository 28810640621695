<template>
<v-theme-provider root>
  <v-app >
      <v-navigation-drawer
          style="z-index: 100;"
          app
          :mini-variant="showMini"
          v-model="showMenu"
          width="300"
      >
      <v-list nav>
        <v-container class="fill-height fill-width" fluid 
              v-if="!showMini">
              <v-img
                v-if="maintenance"
                src="@/assets/Maintenance/manutencao.png"
                :aspect-ratio="16/9"
                style="
                      display: block;
                      margin-left: auto;
                      margin-right: auto;"
                contain
                max-height="100px"
                width="100%"
              />
              <v-img 
                v-else-if="$root.$vuetify.theme.isDark"
                src="@/assets/logo/geonorte_logo_dark.png"
                :aspect-ratio="16/9"
                style="
                      display: block;
                      margin-left: auto;
                      margin-right: auto;"
                contain
                max-height="100px"
                width="100%"
              />
              <v-img 
                src="@/assets/logo/geonorte_logo.jpg"
                :aspect-ratio="16/9"
                v-else
                style="
                      display: block;
                      margin-left: auto;
                      margin-right: auto;"
                contain
                max-height="100px"
                width="100%"
              />
        </v-container>
        <v-divider v-if="!showMini" class="mb-4"></v-divider>
        <v-container v-if="!showMini" class="py-0 my-0 mb-4">
          <v-row no-gutters>
            <v-col cols="12" align="center">
              <v-card class="elevation-0">
                <div class="d-flex flex-no-wrap">
                  <!-- Avatar com fundo laranja e borda branca -->
                  <v-avatar
                    class="ma-1"
                    size="65"
                    :style="{ 
                      backgroundColor: '#FF7F00', /* Cor laranja */
                      border: '5px solid white', /* Borda branca */
                      borderRadius: '50%' /* Borda arredondada */
                    }"
                    @mouseover="startRotation"
                    @mouseleave="stopRotation"
                    :class="{'avatar-hover': hover, 'avatar-rotate': rotateAvatar}"
                  >
                    <v-icon dark x-large>
                      mdi-account-circle
                    </v-icon>
                  </v-avatar>
                  <div style="width: 100%;">
                    <v-card-text class="text-caption text-center">
                      <v-row no-gutters v-if="$root.session != null">
                        <v-col cols="12" align="center">
                          <span class="text-caption">{{$root.session.username}}</span>
                        </v-col>
                        <v-col cols="12" align="center" v-if="$root.session.hasRole(['encarregado', 'dto'])">
                          <span class="text-caption font-weight-bold">Score:</span>{{$root.session.score}}
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="!showMini" class="mb-4"></v-divider>
        <template v-if="loading">
          <v-skeleton-loader
            v-for="i in items.length"
            v-bind:key="i"
            type="list-item"
          ></v-skeleton-loader>
        </template>
        <template v-for="item in items" v-else>
          <v-tooltip right color="primary" :key="item.title" v-if="item.items == 0">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item link v-bind="attrs" :to="item.link" :exact="item.exact" v-on="on">
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item> 
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>  
          <v-tooltip right color="primary" :key="item.title" v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-list-group :group="item.group" v-bind="attrs" v-on="on">
                <template v-slot:activator>
                  <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </template>
                
                <template v-for="child in item.items">
                  <v-tooltip right color="primary" :key="child.title" v-if="child.has_permission">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item link :to="child.link" :exact="child.exact" v-bind="attrs" v-on="on">
                        <v-list-item-icon>
                          <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                    </v-list-item> 
                    </template>
                    <span>{{ child.title }}</span>
                  </v-tooltip>  
                </template>
              </v-list-group> 
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar dense fixed app :clipped-left="false" v-if="!loading">
      <v-tooltip right color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on" @click="openMenu"></v-app-bar-nav-icon>
        </template>
        <span>Menu</span>
      </v-tooltip>

      <v-spacer></v-spacer>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon link to="/admin/notifications">
            <v-badge :content="notifications" :value="notifications" color="error" overlap>
              <v-icon 
                :color="notifications > 0 ? 'secondary' : ''" 
                :class="{'bell-pulse-rotate': notifications > 0}"
              >
                mdi-bell
              </v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>Notificações</span>
      </v-tooltip>

        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" link :to="'/admin/settings/my-user/' + $root.session.id" icon>
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <span>Conta</span>
        </v-tooltip>

        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="toggleTheme">
              <v-icon>{{$vuetify.theme.dark ? "mdi-white-balance-sunny" :  "mdi-weather-night"}}</v-icon>
            </v-btn>
          </template>
          <span>{{$vuetify.theme.dark ? "Modo Claro" : "Modo Escuro"}}</span>
        </v-tooltip>

        <v-tooltip bottom color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="logout">
              <v-icon color="error">mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Sair</span>
        </v-tooltip>
    </v-app-bar>
    <v-main>
      <v-progress-linear v-if="loading"
      indeterminate
      class="global-loader"
    ></v-progress-linear>
      <v-container v-on="$listeners" fluid v-else>
          <v-row>
              <v-col cols="12">
                  <router-view @read-notifications="notifications = 0"></router-view>
              </v-col>
          </v-row>
      </v-container>
    </v-main>
    <v-footer padless>
      <v-col
        class="text-center"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong class="primary--text">GPD V{{getVersion()}}</strong>
      </v-col>
    </v-footer>
  </v-app>
</v-theme-provider>
</template>
<script>
import User from "@/api/User.js"
import System from "@/api/System.js"
import Notification from "@/api/Notification.js"
import DiaryOffline from '@/api/DiaryOffline.js'
import { getDiariesOffline, deleteDiaryOffline } from './idb'

export default {
  components: {
  },
  watch: {
    showMenu: {
      immediate: false,
      handler (val) {
         localStorage['menu-show'] = val;
      },
    },
    showMini: {
      immediate: false,
      handler (val) {
         localStorage['menu-mini'] = val;
      },
    },
    $route: {
        immediate: true,
        handler() {
          if(this.$root.session == undefined){
            return
          }

          let theme = this.$root.session.theme;

          theme = theme.length == 0 ? this.$themes[0] : theme
          
          this.setTheme(theme);

          this.setCssVars();
        }
    },
  },
  data: () => ({
      maintenance: false,
      notifications: null,
      newNotification: false,
      showMenu: false,
      showMini: false,
      isRender: true,
      user: {},
      items: [],
      loading: true,
      rotateAvatar: false,  // Variável para controlar a rotação
      hover: false, // Variável para controlar o hover
      canRotate: true,
      groupSelectedColor: {},
      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
  }),
  beforeMount(){
    this.$vuetify.theme.dark = +localStorage['dark-mode'];

    this.listNotifications()

    User.getSession(false).then(({data}) => {
      this.user = User.getInstance(data);

      let theme = data.theme;

      theme = theme.length == 0 ? this.$themes[0] : theme

      this.setTheme(theme);


      this.$root.session = this.user;

      localStorage['auth'] = 1;

      this.setMenu();

      this.loading = false;

      this.setCssVars();

    }).catch((error) => {

      console.error(error);

      localStorage['auth'] = 0;

      this.$router.push('/');
    });

    if(navigator.onLine){
      this.sendOfflineDiaries();
    }

    this.showMini = localStorage['menu-mini'] == "true";

    let showMenu = localStorage['menu-show'];

    this.showMenu = showMenu != undefined ? showMenu == "true" : true;

    this.showMenu = this.showMenu || !this.$vuetify.breakpoint.mdAndDown;
  },
  mounted(){
    System.getStatus().then((resp)  =>  {
      this.maintenance = resp.data.value == "0" ? false : true
    })
    this.rotateAvatar = false;
    this.canRotate = true;  // Permite rotação logo ao carregar a página

  },
  methods:{
    // Método para iniciar a rotação
    startRotation() {
      if (this.canRotate) {
        this.hover = true; // Ativa o hover
        this.rotateAvatar = true;
        this.canRotate = false;  // Bloqueia a rotação por 1 minuto

        // Após 1 minuto, permite nova rotação
        setTimeout(() => {
          this.canRotate = true;
        }, 60000); // 60000 ms = 1 minuto
      }
    },
    // Método para parar a rotação (quando o mouse sai)
    stopRotation() {
      this.hover = false; // Desativa o hover
      this.rotateAvatar = false;
    },
    getVersion(){
            return process.env.VUE_APP_SECRET_VERSION
    },
    listNotifications() {
      Notification.list(1).then(({data}) => {
          this.notifications = data.length
      });
    },
    setCssVars(){
      let color = this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.primaryLight
          : this.$vuetify.theme.themes.light.primaryLight;

      this.groupSelectedColor = {
        '--group-selected': color,
      };
    },
    toggleTheme(){
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

      localStorage['dark-mode'] = Number(this.$vuetify.theme.dark);
    },
    logout() {
      User.logout()
        .then(() => {
          localStorage['auth'] = 0;

          this.$router.push('/');
        });
    },
    openMenu(){

      if(!this.$vuetify.breakpoint.mdAndDown){

        this.showMini = !this.showMini;

        return;
      }

      this.showMini = false;

      this.showMenu = !this.showMenu;
    },
    async sendOfflineDiaries() {
      const offlineDiaries = await getDiariesOffline();

      for (const diary of offlineDiaries) {
        try {
          const response = await DiaryOffline.create(diary);
          console.log('Diary sent successfully:', response);
          await deleteDiaryOffline(diary.id);
        } catch (error) {
          console.error('Failed to send diary:', diary, error);
        }
      }
    },
    setMenu() {
      
      let menu = [
        {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        has_permission: this.$root.session.hasPermission(["super", "works.read", "diaries.read"]) || this.$root.session.hasRole(['colaborador']),
        exact: true,
        link: "/admin",
        items:[],
      },
      {
        title: "QR Code",
        icon: "mdi-qrcode",
        has_permission: this.$root.session.hasRole(["super", "encarregado"]) || this.$root.session.hasRole(['colaborador']),
        exact: true,
        link: "/admin/qr-code",
        items:[],
      },
      {
        title: "Obras",
        icon: "mdi-hard-hat",
        has_permission: this.$root.session.hasPermission(["super", "works.read", "machines.read"]),
        exact: false,
        group:"admin/works-settings",
        link: "",
        items:[
          {
            title: "Gestão de Obras",
            icon: "mdi-hard-hat",
            has_permission: this.$root.session.hasPermission(["super", "works.read"]),
            exact: false,
            link: "/admin/works-settings/works",
            items:[],
          },
          {
            title: "Gestão de Unidades de Obra",
            icon: "mdi-briefcase",
            has_permission: this.$root.session.hasPermission(["super", "units.read"]),
            exact: false,
            link: "/admin/works-settings/units",
            items:[],
          },
          {
            title: "Gestão de Unidades de medida",
            icon: "mdi-tape-measure",
            has_permission: this.$root.session.hasPermission(["super", "units_management.read"]),
            exact: false,
            link: "/admin/works-settings/units-measure",
            items:[],
          },
        ],
      },
      {
        title: "Gestão de Partes Diárias",
        icon: "mdi-notebook",
        has_permission: this.$root.session.hasPermission(["super", "diaries.read"]),
        exact: true,
        link: "/admin/diaries",
        items:[],
      },
      {
        title: "Gestão de Fornecedores",
        icon: "mdi-factory",
        has_permission: this.$root.session.hasPermission(["super", "companies.read"]),
        exact: true,
        link: "/admin/companies",
        items:[],
      },
      {
        title: "Gestão de Clientes",
        icon: "mdi-account-card",
        has_permission: this.$root.session.hasPermission(["super", "clients.read"]),
        exact: true,
        link: "/admin/clients",
        items:[],
      },
      {
        title: "Pontuações",
        icon: "mdi-account-card",
        has_permission: false,//this.$root.session.hasPermission(["super", "scores.read"]),
        exact: true,
        link: "/admin/scores",
        items:[],
      },
      {
        title: "Gestão de Máquinas",
        icon: "mdi-bulldozer",
        has_permission: this.$root.session.hasPermission(["super", "machines.read"]),
        exact: false,
        link: "/admin/machines",
        items:[],
      },
      {
        title: "Gestão Interna",
        icon: "mdi-cursor-default-gesture",
        has_permission: this.$root.session.hasPermission(["super", "holidays_faults.read"]),
        exact: true,
        link: "/admin/internal-admin",
        items:[],
      },
      {
        title: "Exportações",
        icon: "mdi-export",
        has_permission: this.$root.session.hasPermission(["super", "equipments_vehicles_per_work.export", "equipments_vehicles_single.export", "export.equipments", "export.read", "export.units", "export.workers", "machines_monitoring.export", "units_measurement.export", "workers_entry_point.export", "workers_extra_hours.export", "workers_holidays.export", "workers_meals.export", "workers_night_shift.export", "workers_performance.export", "export.create"]),
        link: "/admin/exports",
        exact: false,
        items:[],
      },
      {
        title: "Colaboradores",
        icon: "mdi-account-hard-hat",
        has_permission: this.$root.session.hasPermission(["super", "workers.read"]),
        exact: false,
        group:"admin/workers-settings",
        items:[
          {
            title: "Gestão de Colaboradores",
            icon: "mdi-account-hard-hat",
            has_permission: this.$root.session.hasPermission(["super", "workers.read", "categories.read"]),
            exact: false,
            link: "/admin/workers-settings/workers",
            items:[],
          },
          {
            title: "Gestão de Categorias",
            icon: "mdi-shape",
            has_permission: this.$root.session.hasPermission(["super", "categories.read"]),
            exact: true,
            link: "/admin/workers-settings/categories",
            items:[],
          }
        ],
      },{
        title: "Definições",
        icon: "mdi-cog",
        has_permission: this.$root.session.hasPermission(["super", "roles.read", "users.read", "logs.read", "system_config.read"]),
        link: "",
        group:"admin/settings",
        exact: false,
        items:[
          {
            title: "Importações",
            icon: "mdi-import",
            has_permission: this.$root.session.hasPermission(["super", "import.read"]),
            link: "/admin/settings/imports",
            exact: false,
            items:[],
          },
          {
            title: "Gestão de Roles",
            icon: "mdi-shield-account",
            has_permission: this.$root.session.hasPermission(["super", "roles.read"]),
            link: "/admin/settings/roles",
            exact: false,
            items:[],
          },
          {
            title: "Gestão de Utilizadores",
            icon: "mdi-account-multiple",
            has_permission: this.$root.session.hasPermission(["super", "users.read"]),
            link: "/admin/settings/users",
            exact: false,
            items:[],
          },
          {
            title: "Logs",
            icon: "mdi-paperclip",
            link: "/admin/settings/logs",
            has_permission: this.$root.session.hasPermission(["super", "logs.read"]),
            exact: false,
            items:[],
          },
          {
            title: "Configurações gerais",
            icon: "mdi-apple-keyboard-option",
            link: "/admin/settings/system-configurations",
            has_permission: this.$root.session.hasPermission(['super', "system_config.read"]),
            exact: false,
            items:[],
          },
          {
            title: "Gestão de Sistema",
            icon: "mdi-cog",
            link: "/admin/settings/system",
            has_permission: this.$root.session.hasRole(["super"]),
            exact: false,
            items:[],
          },
        ],
      }];

      this.items = menu.filter((item) => {
        
        return item.has_permission;
      })
    }
  },
};
</script>
<style scoped>
.v-list-group--active{
  background-color: var(--group-selected);
  border-radius: 4px;
}
/* Reduzir a largura da barra de rolagem para 50% do tamanho padrão */
.v-navigation-drawer ::-webkit-scrollbar {
    width: 4px; /* Reduzido para 50% do tamanho padrão */
}

/* Personalização da parte da trilha */
.v-navigation-drawer ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Personalização do polegar (parte que se move) */
.v-navigation-drawer ::-webkit-scrollbar-thumb {
    background: #FF9800;
    border-radius: 10px;
}

/* Efeito de hover para o polegar */
.v-navigation-drawer ::-webkit-scrollbar-thumb:hover {
    background: #e68900;
}
.avatar-hover {
  transform: scale(1.2); /* Aumenta o avatar 20% */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra mais forte */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.avatar-rotate {
  animation: rotateAvatar 7s ease-in-out 1;  /* A duração foi aumentada para 3s para 5 rotações */
}

@keyframes rotateAvatar {
  0% {
    transform: rotateY(0deg); /* Início da rotação */
  }
  25% {
    transform: rotateY(900deg); /* 2ª rotação */
  }
  50% {
    transform: rotateY(1800deg); /* 3ª rotação */
  }
  75% {
    transform: rotateY(2700deg); /* 4ª rotação */
  }
  100% {
    transform: rotateY(3600deg); /* 5ª rotação */
  }
}

/* Efeito de piscar e rotação combinados na mesma animação */
@keyframes pulseShakeBell {
  0% {
    opacity: 1;
    transform: rotate(0deg);
  }
  16% {
    opacity: 0.5;
    transform: rotate(30deg); /* Gira 30 graus para a direita */
  }
  33% {
    opacity: 1;
    transform: rotate(-30deg); /* Gira 30 graus para a esquerda */
  }
  50% {
    opacity: 0.5;
    transform: rotate(30deg); /* Gira 30 graus para a direita novamente */
  }
  66% {
    opacity: 1;
    transform: rotate(-30deg); /* Gira 30 graus para a esquerda novamente */
  }
  83% {
    opacity: 0.5;
    transform: rotate(30deg); /* Gira 30 graus para a direita novamente */
  }
  100% {
    opacity: 1;
    transform: rotate(0deg); /* Retorna à posição inicial */
  }
}

.bell-pulse-rotate {
  animation: pulseShakeBell 2s ease-in-out 3; /* Repetir 3 vezes */
}

</style>