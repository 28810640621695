<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-account-multiple</v-icon> <span>Diárias com atraso</span>
        </h1>

        <div style="position:relative">
          <v-container>
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Opções</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="info">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="info"
                    large
                    @click="searching = true"
                  >
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <span>Pesquisar</span>
              </v-tooltip>              
            </v-speed-dial>
          </v-container>
  
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            class="mt-16"
            locale="pt-pt"
          >
  
            
          </v-data-table>
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resetScores">
                Limpar Campos
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list class="pt-10">
            <v-form @submit.prevent="searchScores">
              
              
  
              <v-row
                class="mt-10"
                align="center"
                justify="space-around"
              >
              <v-btn
                color="primary"
                type="submit"
              >
                Pesquisar
              </v-btn>
              </v-row>
            </v-form>
          </v-list>
        </v-navigation-drawer>
    </section>
  </template>
  <script>
  
  import Score from "@/api/Score.js";
  import Role from "@/api/Role.js";
  
  export default {
    components: {
        
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchScores()}, 10);
        },
        deep: true,
      },
    },
    beforeCreate(){
      document.title = "GPD - Pontuações";
  
      Role.list().then(({data}) => {
        this.roles = data;
      });
    },
    data: () => ({
      headers: [
        {
          text: 'Nome',
          sortable: true,
          value: 'user.name',
        },
        {
          text: 'Email',
          sortable: true,
          value: 'user.email',
        },
        {
          text: 'Pontuação',
          sortable: true,
          value: 'score',
        },
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
        
      },
      roles:[]
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "scores.read"])) {
        this.$router.push('/admin');
      }
  
      this.fillBaseData();
  
    },
    methods:{
      fillBaseData() {
  
        if(localStorage["scores-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["scores-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchScores(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
  
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };
      
        Object.assign(request, filter);
  
        localStorage["scores-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Score.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;
          });
  
  
          this.loading = false;
  
        });
      },
      resetScores()
      {
        this.filter = {
          is_active: 1
        };
  
        this.searchScores();
      }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Pontuação',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  