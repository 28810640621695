<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row>
            <v-col cols="12" md="6">
                <ObrasCurso :filter="getFilterActual" :worker_view="true"/>
            </v-col>
            <v-col cols="12" md="6">
                <ObrasCurso :filter="getFilterLast" :worker_view="true"/>
            </v-col>
            <v-col cols="12">
                <WorkerCalendar />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ObrasCurso from '@/components/dashboard/internals/ObrasCurso.vue'
import WorkerCalendar from '@/components/dashboard/internals/WorkerCalendar.vue'

export default{
    name:"WorkerView",
    components:{
        ObrasCurso,
        WorkerCalendar
    },
    data(){
        return{
            filter:{
                
            }
        }
    },
    computed:{
        getFilterActual(){
            return {month: new Date().getMonth()}
        },
        getFilterLast(){
            return {month: new Date().getMonth()-1}
        }
    }
}
</script>