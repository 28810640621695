<template>
    <v-row>
        <v-col cols="12">
        <div class="d-flex flex-row align-center">
            <h2 class="secondary--text subtitle-1">
            Unidade de Obra
            </h2>
            <v-spacer />
        </div>
        <v-simple-table dense class="d-none d-lg-block">
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-center" style="width:40%;">
                    Nome
                </th>
                <th class="text-center">
                    Código
                </th>
                <th class="text-center">
                    Medição
                </th>
                <th class="text-center">
                    Unidade
                </th>
                <th class="text-center">
                    Mão de Obra
                </th>
                <th class="text-center">
                    Máquinas
                </th>
                <th class="text-center">
                    Opções
                </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="work_unit in template_fields.units" v-bind:key="work_unit.id">
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="action_name" name="Unidade Obra" rules="required">
                    <v-combobox
                        v-model="work_unit.object"
                        label="Unidade Obra *"
                        :items="units"
                        @input="work_unit.code = $event.code; work_unit.unit = $event.unit; work_unit.unit_id = $event.id"
                        item-value="id"
                        item-text="name"
                        hide-selected
                        :error-messages="errors"
                    >
                    </v-combobox>
                    </validation-provider>
                </td>
                <td class="text-center">
                    {{work_unit.object == null ? '-' : work_unit.object.code}}
                </td>
                <td class="text-center">
                    <validation-provider v-slot="{ errors }" vid="measure" name="Medição *" rules="required">
                    <v-text-field
                        v-model="work_unit.measure"
                        @change="replaceComma(work_unit)"
                        label="Medição *"
                        :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                </td>
                <td class="text-center">
                    {{work_unit.object == null ? '-' : work_unit.object.unit}}
                </td>
                <td class="text-center">
                    <v-btn
                    dark
                    small
                    color="secondary"
                    @click="error.show = true"
                    >
                    <v-icon small>mdi-account-hard-hat</v-icon>
                    </v-btn>
                </td>
                <td class="text-center">
                    <v-btn
                    dark
                    small
                    color="secondary"
                    @click="error.show = true"
                    >
                    <v-icon small>mdi-bulldozer</v-icon>
                    </v-btn>
                </td>
                <td class="text-center">
                    <v-btn
                    dark
                    small
                    color="error"
                    @click="removeWorkUnit(work_unit)"
                    >
                    <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        <v-data-iterator
            :items="template_fields.units"
            item-key="id"
            :items-per-page="-1"
            hide-default-footer
            class="d-block d-lg-none"
        >
            <template v-slot:default="{ items }">
            <v-row>
                <v-col
                v-for="work_unit in items"
                :key="work_unit.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                >
                <v-card>
                    <v-list
                    dense
                    >
                    <v-list-item>
                        <v-list-item-content>Nome:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="action_name" name="Unidade Obra" rules="required">
                            <v-combobox
                            v-model="work_unit.name"
                            label="Unidade Obra *"
                            :items="units"
                            @input="work_unit.code = $event.code; work_unit.unit = $event.unit; work_unit.unit_id = $event.id"
                            item-value="id"
                            item-text="name"
                            hide-selected
                            :error-messages="errors"
                            >
                            </v-combobox>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Código:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        {{work_unit.code}}
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Medição:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <validation-provider v-slot="{ errors }" vid="measure" name="Medição *" rules="required">
                            <v-text-field
                            v-model="work_unit.measure"
                            label="Medição *"
                            :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Unidade:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        {{work_unit.unit}}
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Mão de Obra:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-btn
                            dark
                            small
                            block
                            color="secondary"
                            @click="error.show = true"
                        >
                            <v-icon small>mdi-account-hard-hat</v-icon>
                        </v-btn>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Máquinas:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-btn
                            dark
                            small
                            block
                            color="secondary"
                            @click="error.show = true"
                        >
                            <v-icon small>mdi-bulldozer</v-icon>
                        </v-btn>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>Opções:</v-list-item-content>
                        <v-list-item-content class="align-end">
                        <v-btn
                            dark
                            small
                            color="error"
                            @click="removeWorkUnit(work_unit)"
                        >
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list>
                </v-card>
                </v-col>
            </v-row>
            </template>
        </v-data-iterator>
        <v-row justify="center">
            <v-btn
            fab
            dark
            small
            color="primary"
            class="mt-7"
            @click="addWorkUnit"
            >
            <v-icon small>mdi-plus</v-icon>
            </v-btn>
        </v-row>
        </v-col>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
        <DialogConfirmation 
            v-if="openConfirmationDialog" 
            title="Remover Item" 
            type="warning" 
            :opened="openConfirmationDialog" 
            @on-submit="submitRemove" 
            @on-cancel="openConfirmationDialog = false">
                Tem a certeza que pretende remover o item?
        </DialogConfirmation>
    </v-row>
</template>
<script>
import dayjs from 'dayjs'
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';

export default {
    components:{
        DialogConfirmation,
        ErrorSnackbar
    },
    props: {
        template_fields: Object,
        units: Array
    },
    data: () => ({
        openConfirmationDialog: false,
        error:{
            show: false,
            message: 'Ação indisponível em template'
        },
        toSend:{
            id: null
        }
    }),
    mounted(){
        //console.log(this.template_fields)
    },
    methods:{
        addWorkUnit() {
            this.template_fields.units.push({
                id: dayjs().unix(),
                name: "",
                code: "-",
                object: null,
                measure: 0,
                unit: "-"
            });
        },
        getWorkUnitIndex(id) {
            return this.template_fields.units.findIndex(item => item.id == id);
        },
        removeWorkUnit(workUnitObject) {
            this.toSend.id = workUnitObject.id
            this.openConfirmationDialog = true
        },
        async submitRemove(){
            await this.$emit('remove', this.toSend.id)
            this.openConfirmationDialog = false
        }
    }
}
</script>
