<template>
    <v-card v-if="!loading" style="overflow: hidden;">
      <v-card-title class="primary white--text">
        Intervenção
      </v-card-title>
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-form @submit.prevent="submit">
            <v-card-text class="mt-4">
              <v-row>
                <v-col cols="12" md="8" v-if="fields.diary_id != null">
                  <label class="mb-n8">Ver diária</label>
                  <IconView
                    class="mr-2 ml-2 mt-4"
                    @click="viewDiary(fields.diary_id)"
                  />
                </v-col>                
                <v-col cols="12" md="12">          
                  <validation-provider v-slot="{ errors }" vid="status" name="Estado" rules="required">        
                    <v-select
                      v-model="fields.status"
                      :items="states"
                      menu-props="auto"
                      label="Níveis de avaria *"
                      persistent-hint
                      prepend-icon="mdi-sync-alert"
                      single-line
                      clearable
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12" v-if="fields.status == 'Avaria total' || fields.status == 'Avaria parcial'">
                  <v-row>
                    <v-col cols="12" md="12">
                      <validation-provider v-slot="{ errors }" vid="Obra" name="Obra" rules="required">   
                        <v-autocomplete   
                          :disabled="method == 'updateBreakdown'"                       
                          :items="works"
                          v-model="fields.work_id"
                          :error-messages="errors"
                          label="Obra da avaria *"
                          :item-text="item => item.code + ' - ' + item.name"
                          item-value="id"                        
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-if="fields.status == 'Correção de avaria'">
                  <v-row>
                    <v-col cols="12" md="6">
                      <validation-provider v-if="method != 'updateBreakdown'" v-slot="{ errors }" vid="Avarias por corrigir" name="Avarias por corrigir" rules="required">   
                        <v-autocomplete                          
                          :items="getBreakdowns"
                          v-model="fields.what_was_fixed"
                          :error-messages="errors"
                          label="Avarias por corrigir"
                          item-text="code"
                          item-value="id"                        
                        />
                      </validation-provider>
                      <v-text-field v-else 
                        :value="getFixed(fields.what_was_fixed)"
                        label="Avaria corrigida"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <validation-provider v-slot="{ errors }" vid="Próximo estado" name="Próximo estado" rules="required">   
                        <v-select
                          :items="states"
                          v-model="fields.next_status"
                          :disabled="method=='updateBreakdown'"
                          menu-props="auto"
                          label="Próximo estado"
                          :error-messages="errors"
                          persistent-hint
                          single-line
                          clearable
                        ></v-select>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12">
                  <validation-provider v-slot="{ errors }" vid="responsavel" name="Responsável" rules="required">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value="dates"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                          v-model="dates"
                          multiple
                          chips
                          :error-messages="errors"
                          small-chips
                          label="Dias da avaria *"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-combobox>
                      </template>
                      <v-date-picker
                        v-model="dates"
                        :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                        multiple
                        no-title
                        scrollable
                        @input="pickerDataChanged"
                      >
                      </v-date-picker>
                    </v-menu>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <validation-provider v-slot="{ errors }" vid="responsavel" name="Responsável" rules="required">
                    <v-autocomplete
                      label="Responsável/Manobrador *"
                      :items="workers"
                      item-value="id"
                      item-text="name"
                      v-model="fields.responsible"
                      hide-selected
                      :error-messages="errors"
                      clearable
                    >
                    </v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4">
                  <validation-provider v-slot="{ errors }" vid="criado" name="Criado por" rules="|">
                    <v-autocomplete
                      :disabled="true"
                      v-model="fields.created_by"
                      label="Criado por"
                      :items="users"
                      item-value="id"
                      item-text="name"
                      hide-selected
                      :error-messages="errors"
                    >
                    </v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4">
                  <validation-provider v-slot="{ errors }" vid="horas_km" name="Horas/Km" rules="|">
                    <v-text-field
                      v-model="fields.hours_km"
                      prepend-inner-icon="mdi-signal-distance-variant"
                      label="Horas/Km"  
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider v-slot="{ errors }" vid="obs" name="Observações" rules="required">
                    <v-textarea
                      v-model="fields.breakdown"
                      prepend-inner-icon="mdi-signal-distance-variant"
                      label="Observações *"  
                      :error-messages="errors"
                    ></v-textarea>
                  </validation-provider>
                </v-col>           
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="primary white--text text-h4">
                  Anexos
                </v-col>         
                <v-col cols="12">
                  <validation-provider v-slot="{ errors }" vid="Ficheiro" name="Ficheiro" rules="|">
                      <v-file-input
                        small-chips
                        multiple
                        label="Associar documentos"
                        v-model="fields.file_path"
                        :error-messages="errors"
                      ></v-file-input>
                  </validation-provider> 
                </v-col>
                <v-col cols="12" md="2" v-for="f in fields.files" :key="f.id">
                  <v-card outlined class="elevation-10" height="200px">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="text-center">
                          <v-icon>{{getIconDocument(f.path)}}</v-icon>{{getFileExtension(f.path)}}
                        </v-col>
                        <v-col cols="12" class="text-center font-weight-bold">
                          {{getFilename(f.path)}}
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-row>
                        <v-col cols="12" md="12" align="center">
                          <v-btn outlined @click="downloadFile(f)">Download</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="primary white--text text-h4">
                  Imagens
                </v-col>
                <v-col cols="12" md="12">
                  <div class="d-flex">
                    <v-file-input
                        small-chips
                        multiple
                        label="Associar imagens"
                        v-model="fields.image_path"
                      ></v-file-input>
                  </div>
                </v-col>
                <v-col cols="12" md="3" v-for="item in fields.images" :key="item.id">
                  <v-card height="250px">
                    <v-card-text>
                      <v-img height="150px" contain :src="getSrc(item)"
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-row>
                        <v-col cols="12" md="12" align="center">
                          <v-btn outlined @click="openBig(item)">Download</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </V-card>
                </V-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row justify="center">
                <v-col cols="12" md="auto">
                  <v-btn :disabled="invalid" depressed color="success" type="submit">
                    Gravar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </Validation-observer>
        <DialogSuccess :opened="success" @on-ok="$emit('close-success')" >
          Intervenção gravada com sucesso!
      </DialogSuccess>
    </v-card>
</template>

<script>
import User from '@/api/User.js'
import Work from '@/api/Work.js'
import Worker from '@/api/Worker.js'
import Machine from '@/api/Machine.js'
import IconView from '@/components/ui/IconView.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Files from '@/api/Files.js'

export default{
    name: 'Intervention',
    components:{
      IconView,
      DialogSuccess
    },
    props:{
      id: String,
      states: Array,
      breakdowns: Array
    },
    watch:{
      id:{
        immediate: true,
        async handler(val){
          let self = this
          if(val == null){
            this.fields.created_by = this.$root.session.id
            this.fields.machine_id = this.$route.params.id
            self.loading = false
            return
          }

          await Machine.findBreakdown(val)
            .then(function({data}) {

              self.fields = data

              
              
              self.dates.push(data.breakdown_date)

              if(self.fields.id != null)
                self.method = 'updateBreakdown'
              
            });
            this.fields.file_path = null
            self.loading = false
        }
      }
    },
    data(){
        return{
          success: false,
          method: 'breakdown',
          menu: false,
          dates: [],
          loading: true,
          workers: [],
          users: [],
          fields: {
            id: null,
            machine_id: null,
            diary_id: null,
            status: null,
            breakdown: null,
            hours_km: null,
            responsible: null,
            created_by: null,
            work_id: null,
            file_path: null,
            next_status: null,
            what_was_fixed: null,
            images: [],
            files: [],
            image_path: null
          },
          images: [],
          works: []
        }
    },
    mounted(){
      this.fillBaseData()
    },
    methods:{
      pickerDataChanged(p){
        console.log(p)
      },
      getSrc(i){

        return process.env.VUE_APP_API_URL + 'storage/machine-breakdown-files/' + i.id
      },
      openBig(i){
        let url = process.env.VUE_APP_API_URL + 'storage/machine-breakdown-files/' + i.id

        window.open(url, '_blank')
      },
      downloadFile(file){

        
        Files.get(file.id)
        .then((response) => {
            let documentName =  this.getFilename(file.path)

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
      },
      getIconDocument(filename){
        let extension = this.getFileExtension(filename)

        if(extension == 'pdf')
          return 'mdi-file-pdf-box'

        if(extension == 'jpg')
          return 'mdi-file-jpg-box'

        if(extension == 'jpeg')
          return 'mdi-file-jpg-box'

        if(extension == 'png')
          return 'mdi-file-png-box'

        if(extension == 'docx')
          return 'mdi-file-word'

        if(extension == 'xlsx')
          return 'mdi-file-excel'

        if(extension == 'csv')
          return 'mdi-file-excel'

        return 'mdi-image-album'
      },
      getFileExtension(filename){
        return filename.split('.').pop();
      },
      getFilename(path){
        return path.substring(path.lastIndexOf("/") + 1)
      },
      fillBaseData(){
        Work.list({status: "STARTED"}).then(({data}) => {
          this.works = data;
        });
        Worker.list().then(({data}) => {
          this.workers = data;
        });
        User.list().then(({data}) => {
          this.users = data;
        });
      },
      viewDiary(id){
        //this.$router.push('/admin/diaries/' + item.id);

        window.open('/admin/diaries/' + id, '_blank')
      },
      submit(){

        if(this.fields.id == null)
          this.createBreakdown()
        else
          this.updateBreakdown()
        
      },
      async createBreakdown(){
        const formData = new FormData()
        formData.append('id', this.fields.id)
        formData.append('machine_id', this.fields.machine_id)
        formData.append('diary_id', this.fields.diary_id)
        formData.append('status', this.fields.status)
        formData.append('breakdown', this.fields.breakdown)
        formData.append('hours_km', this.fields.hours_km)
        formData.append('responsible', this.fields.responsible)
        formData.append('created_by', this.fields.created_by)
        formData.append('file_path', this.fields.file_path == null ? '' : this.fields.file_path)
        formData.append('breakdown_date', this.dates)
        formData.append('next_status', this.fields.next_status)
        formData.append('what_was_fixed', this.fields.what_was_fixed)
        formData.append('work_id', this.fields.work_id)

        let tamanho = 0
        if(this.fields != null){
          if(this.fields.file_path != null){
            tamanho = this.fields.file_path.length
            for (var i = 0; i < this.fields.file_path.length; i++ ){
              let file = this.fields.file_path[i];
              formData.append('files[]', file);
            } 
          }
        }

        formData.append('total_files', tamanho)

        if(this.fields != null){
          if(this.fields.image_path != null){
            tamanho = this.fields.image_path.length
            for (i = 0; i < this.fields.image_path.length; i++ ){
              let file = this.fields.image_path[i];
              formData.append('images[]', file);
            } 
          }
        }


        await Machine['breakdown'](formData).then(() => {

        this.success = true;

        this.loading = false;

        }).catch(err => {
            this.loading = false;
            if(err.response.status == 422) {

            this.$refs.form.setErrors(err.response.data.errors);

            return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

            this.error.show = true
        });
      },
      async updateBreakdown(){
        const formData = new FormData()
        formData.append('id', this.fields.id)
        formData.append('machine_id', this.fields.machine_id)
        formData.append('diary_id', this.fields.diary_id)
        formData.append('status', this.fields.status)
        formData.append('breakdown', this.fields.breakdown)
        formData.append('hours_km', this.fields.hours_km)
        formData.append('responsible', this.fields.responsible)
        formData.append('created_by', this.fields.created_by)
        formData.append('file_path', this.fields.file_path == null ? '' : this.fields.file_path)
        formData.append('breakdown_date', this.dates)
        formData.append('work_id', this.fields.work_id)

        
        let tamanho = 0
        if(this.fields != null){
          if(this.fields.file_path != null){
            tamanho = this.fields.file_path.length
            for (var i = 0; i < this.fields.file_path.length; i++ ){
              let file = this.fields.file_path[i];
              formData.append('files[]', file);
            } 
          }
        }

        formData.append('total_files', tamanho)

        if(this.fields != null){
          if(this.fields.image_path != null){
            tamanho = this.fields.image_path.length
            for (i = 0; i < this.fields.image_path.length; i++ ){
              let file = this.fields.image_path[i];
              formData.append('images[]', file);
            } 
          }
        }


        await Machine['updateBreakdown'](formData, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;

          }).catch(err => {
              this.loading = false;
              if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);

              return;
              }

              this.error.title = "Erro " + err.response.status;

              this.error.message = err.response.data.message;

              this.error.show = true
          });
      },
      getFixed(id){
        let aux = null
        this.breakdowns.forEach(element => {
          if(element.id == id)
            aux = element
        });
        return aux.code
      }
    },
    computed:{
      getBreakdowns(){
        let returner = []
        this.breakdowns.forEach(element => {
          if(element.fixed == false)
            returner.push(element)
        });
        return returner
      }
    }
}
</script>