<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-briefcase</v-icon> <span>Unidades de Obra</span>
      </h1>
      <v-icon color="primary">
        mdi-eye
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Visualizar

      <v-icon color="red" class="ml-4">
        mdi-delete
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Remover

      <v-icon color="success" class="ml-4">
        mdi-file-export
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Exportar ficheiro

      <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>
            <v-tooltip right color="warning">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="warning"
                  large
                  @click="exportAllUO"
                >
                  <v-icon>mdi-paperclip</v-icon>
                </v-btn>
              </template>
              <span>Rendimentos UO</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  to="/admin/works-settings/units/create"
                  v-if="$root.session.hasPermission(['super', 'units.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16"
          locale="pt-pt"
        >
          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewUnit(item)"
            />
            <IconExport
              class="mr-2"
              text="Relatório por Unidade Obra"
              v-show="false"
              v-if="$root.session.hasPermission(['super', 'export.units'])"
              @click="exportUO(item)"
            />
            <div v-if="false">
            <IconExport
              class="mr-2"
              text="Rendimentos por Unidade Obra"
              v-if="$root.session.hasPermission(['super', 'export.units'])"
              @click="exportRendimentosPorUO(item)"
            />
            </div>
            <IconRemove
              v-if="!item.has_actions && $root.session.hasPermission(['super', 'units.delete'])"
              class="mr-2"
              @on-submit="deleteUnit(item)"
            />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetUnits">
              Limpar Campos
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchUnits">
            
            <v-list-item>
              <v-text-field
                v-model="filter.code"
                prepend-inner-icon="mdi-barcode"
                label="Código"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-form-textbox"
                label="Nome"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
      <v-dialog
            v-model="modalChooseData"
            persistent
            
            width="350px"
        >
        <ChooseData 
          @close="modalChooseData = false"
          @confirm="confirmExport"/>
      </v-dialog>
      <Loader v-if="loaderAction"></Loader>  
  </section>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Unit from "@/api/Unit.js";
import dayjs from 'dayjs';
import Export from "@/api/Export.js";
import IconExport from '@/components/ui/IconExport.vue';
import ChooseData from '@/components/general/dialogs/ChooseDate.vue';
import Loader from '@/components/ui/Loader.vue'

export default {
  components: {
    IconRemove,
    IconView,
    IconExport,
    ChooseData,
    Loader
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchUnits()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "GPD - Gestão de Unidades";
  },
  data: () => ({
    loaderAction: false,
    modalChooseData: false,
    exportRendimentosPorUoFlag: false,
    to_export: {
      ob: null,
      start: null,
      end: null,
      all: false,
    },
    headers: [
      {
        text: 'Código',
        sortable: true,
        value: 'code',
      },
      {
        text: 'Nome',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Unidade',
        sortable: true,
        value: 'unit',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      code:null,
      name: null,
    },
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "units.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    exportRendimentosPorUO(item){
      this.to_export.ob = item
      this.exportRendimentosPorUoFlag = true
      this.modalChooseData = true
    },
    exportAllUO(){
      this.to_export.all = true
      this.modalChooseData = true
    },
    exportUO(item){
      this.to_export.ob = item
      this.modalChooseData = true
      this.to_export.all = false
    },
    async confirmExport(d1, d2, type){
      this.loaderAction = true
      let data ={
        dataInicial: d1,
        dataFinal: d2,
        type: type
      }

      if(this.exportRendimentosPorUoFlag){
        data = {
          dataInicial: d1,
          dataFinal: d2,
          type: type,
          unit_id: this.to_export.ob.id
        }
        await Export.generalExport('unit_work_measure_list', data)
          .then((resp) => {            
              window.open(resp.data.url, '_blank')
              Export.delete( resp.data.path)
              .then(() => {
              });
          });
          this.exportRendimentosPorUoFlag = false
          this.loaderAction = false
          return
      }

      // Se não for para exportar todos é só uma
      if(!this.to_export.all){
        await Export.exportRendimentoPorUO(this.to_export.ob.id, data)
            .then(() => {
              this.modalChooseData = false
              this.$router.push('/admin/exports/background')
            });
        }else{
          await Export.exportRendimentoUOs(data)
            .then(() => {
              this.modalChooseData = false
              this.$router.push('/admin/exports/background')
            });
        }
        
        this.modalChooseData = false
    },
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {

      if(localStorage["units-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["units-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchUnits(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["units-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Unit.search(request).then(response => {

        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetUnits()
    {
      this.filter = {
      };

      this.searchUnits();
    },
    viewUnit(item){
      this.$router.push('/admin/works-settings/units/' + item.id);
    },
    deleteUnit(item){
      Unit.delete(item.id)
        .then(() => {
          this.searchUnits();
        });
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Unidades de Obra',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
