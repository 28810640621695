<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <v-form @submit.prevent="submit"  class="mb-16">
       <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>
      <fieldset :disabled="loading">
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="name" name="Código" rules="required">
              <v-text-field
                dense outlined
                v-model="fields.name"
                prepend-inner-icon="mdi-account-details"
                label="Código *"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="display_name" name="Nome" rules="required">
              <v-text-field
                dense outlined
                v-model="fields.display_name"
                prepend-inner-icon="mdi-account-details"
                label="Nome *"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="permissions" name="Permissões" rules="required">
              <multiple-select
                dense outlined
                v-model="fields.permissions"
                :items="permissions"
                item-text="display_name"
                item-value="id"
                menu-props="auto"
                label="Permissões *"
                prepend-inner-icon="mdi-lock"
                :error-messages="errors"
                return-object
              ></multiple-select>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider v-slot="{ errors }" vid="users" name="Utilizadores">
              <multiple-select
                dense outlined
                v-model="fields.users"
                :items="users"
                :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                item-value="id"
                menu-props="auto"
                label="Utilizadores"
                prepend-inner-icon="mdi-account-multiple"
                :error-messages="errors"
                return-object
              ></multiple-select>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="mt-6">    
          <v-col cols="12" md="6">
            <v-card>
              <v-card-title class="primary white--text">
                Permissões (x{{fields.permissions.length}})
              </v-card-title>
              <v-card-text>
                <v-simple-table fixed-header height="50vh">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Nome
                        </th>
                        <th class="text-left">
                          Permissão
                        </th>
                        <th class="text-left">
                          Op.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in getPermissionsInRole" :key="index"
                      >
                        <td>{{ item.name }}</td>
                        <td>{{ item.display_name }}</td>
                        <td>
                          <v-btn @click="deletePermission(item)" icon color="error" small>
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions>
                <v-row justify="center">
                  <v-col cols="12" md="8">
                    <v-autocomplete
                      dense outlined
                      :items="filterPermissions"
                      label="Permissões"
                      item-value="id"
                      :item-text="item => item.display_name"                      
                      v-model="addObject.permission"
                    />
                  </v-col>
                  <v-col cols="12" md="auto">
                    <v-btn @click="adicionarPermissao" :disabled="addObject.permission == null" class="success" fab small>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-title class="primary white--text">
                Utilizadores (x{{fields.users.length}})
              </v-card-title>
              <v-card-text>
                <v-simple-table fixed-header height="50vh">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Nome
                        </th>
                        <th class="text-left">
                          Email
                        </th>
                        <th class="text-left">
                          Op.
                        </th>
                      </tr>
                    </thead>
                    <tbody :key="reloadUsers">
                      <tr
                        v-for="item in getUsersInRole" :key="item.id"
                      >
                        <td>{{ item.name }}</td>
                        <td>{{ item.email }}</td>
                        <td>
                          <v-btn @click="deleteUser(item)" icon color="error" small>
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions>
                <v-row justify="center">
                  <v-col cols="12" md="8">
                    <v-autocomplete
                      dense outlined
                      :items="filterUsers"
                      label="Utilizadores"
                      item-value="id"
                      :item-text="item => 'Nome: ' + item.name + ' - Username:' + item.username"                      
                      v-model="addObject.user"
                    />
                  </v-col>
                  <v-col cols="12" md="auto">
                    <v-btn @click="adicionarUser" :disabled="addObject.user == null" class="success" fab small>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-6" align="center" justify="space-around">
          <v-btn :disabled="invalid" depressed color="success" type="submit">
            Gravar
          </v-btn>
        </v-row>
      </fieldset>
    </v-form>
    <DialogSuccess :opened="success" @on-ok="onSuccess" >
      Role gravado com sucesso!
    </DialogSuccess>
  </validation-observer>
</template>
<script>
import User from "@/api/User.js";
import Permission from "@/api/Permission.js";
import Role from "@/api/Role.js";
import MultipleSelect from '@/components/ui/MultipleSelect.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default {
  components: {
    MultipleSelect,
    DialogSuccess
  },
  props: {
   role: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
    role: {
      immediate: true, 
      handler (val) {
        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;

        this.fields.id = data.id;
      }
    },
  },
  beforeCreate(){
  },
  data: () => ({
    addObject:{
      user: null,
      permission: null
    },
    fields: {
      id: null,
      name: null,
      display_name:null,
      users:[],
      permissions: [],
    },
    loading:false,
    error: {
      title: '',
      message: '',
    },
    users: [],
    permissions: [],
    success: false,
    reloadUsers: 999
  }),
  mounted(){
    this.fillBaseData();
  },
  methods:{
    adicionarPermissao(){
      this.permissions.forEach(element => {
        if(element.id == this.addObject.permission)
          this.fields.permissions.push(element)
      });      
    },
    adicionarUser(){
      this.users.forEach(element => {
        if(element.id == this.addObject.user)
          this.fields.users.push(element)
      });      
    },
    deleteUser(item){
      let i = 0
      let pos = -1
      this.fields.users.forEach(element => {
        if(element.id == item.id)
          pos = i

        i++
      });

      if(pos != -1)
        this.fields.users.splice(pos, 1)

      this.reloadUsers++
    },
    deletePermission(item){
      let i = 0
      let pos = -1
      this.fields.permissions.forEach(element => {
        if(element.name == item.name)
          pos = i

        i++
      });

      if(pos != -1)
        this.fields.permissions.splice(pos, 1)
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/admin/settings/roles');
    },
    fillBaseData(){
      User.list({is_active: 1}).then(({data}) => {
        this.users = data;
      });

      Permission.list({role_id: this.id}).then(({data}) => {
        this.permissions = data;
      });
    },
    submit() {

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        let data = {...this.fields};

        data.permissions = data.permissions.map(i => i.id);

        let fixed = []
        data.permissions.forEach(data_permission => {
          let found = false
          this.permissions.forEach(permission => {
            if(data_permission == permission.name){
              found = true
              fixed.push(permission.id)
            }
          });
          if(found == false)
            fixed.push(data_permission)
        });

        data.permissions = fixed;

        data.users = data.users.map(i => i.id);

        Role[this.method](data, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;

          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    filterPermissions(){
      let ar = []
      this.permissions.forEach(permi => {
        let existe = false
        this.fields.permissions.forEach(field_permi => {
          if(permi.id == field_permi.id)
            existe = true                  
        });
        if(existe == false)
          ar.push(permi)
      });
      return ar
    },
    filterUsers(){
      let ar = []
      this.users.forEach(u => {
        let existe = false
        this.fields.users.forEach(field_user => {
          if(u.id == field_user.id)
            existe = true                  
        });
        if(existe == false)
          ar.push(u)
      });
      return ar
    },
    getUsersInRole(){
      let usersInRole = []

      this.fields.users.forEach(element => {

            usersInRole.push(element)
        
      });

      return usersInRole
    },
    getPermissionsInRole(){
      let permissionsInRole = []

      this.permissions.forEach(element => {
        this.fields.permissions.forEach(permi => {
          if(element.name == permi.name)
            permissionsInRole.push(permi)
        });
      });
      
      return permissionsInRole
    }
  }
};
</script>
