<template>
  <v-dialog v-model="dialog" width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="red" @click="showRejectedHistory(item)" 
        v-bind="attrs"
        v-on="on">
        mdi-hospital-box
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="primary white--text">
        <span class="text-h5">{{title}}</span>
      </v-card-title>
      <v-card-text class="mt-8">
        <div>Motivo rejeição:</div>
        <v-textarea
            outlined
            v-model="model_text"
            name="input-7-4"
            :readonly="true"
        ></v-textarea>
      </v-card-text>
      
      <v-card-text>
        <div class="font-weight-bold ml-8 mb-2">
          Linha temporal
        </div>
        <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              v-for="message in item.rejectedHistory"
              :key="message.id"
              small
            >
              <div>
                <div class="font-weight-normal">
                  Utilizador <strong>{{ message.user.name }}</strong> a {{ message.created_at }} escreveu:
                </div>
                <div class="mt-2"><span class="ml-8">{{ message.reason }}</span></div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-actions>
          <v-btn
            style="display: block; margin: auto;"
            color="error"
            depressed
            @click="$emit('on-cancel')"
            >
                Cancelar
            </v-btn>
          </v-card-actions>
       </v-card>
  </v-dialog>
</template>
<script>
      
export default {
    name: 'RejectionHistory',
    components: {
      
    },
    props:{
        vid: String,
        model:String,
        title:String,
        label:String,
        item: null,
        locked:{
        type: Boolean,
        default:false
        }
    },
    mounted(){
      this.dialog = true
      this.model_text = this.item.rejectedHistory[0].reason
    },
    data(){
      return{
          model_text: '',
          dialog: false
      }
    }
}
</script>