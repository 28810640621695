<template>
    <span class="pointer-cursor">
      <Tooltip
        class="my-4 mr-4"
        color="#038186"
        text="Link Sharepoint"
        top
      >
        <v-icon
          
          color="#038186"
          v-bind="$attrs"
          v-on="$listeners"
        >
          mdi-microsoft-sharepoint
        </v-icon>
      </Tooltip>
    </span>
  </template>
  
  <script>
    import Tooltip from '@/components/ui/Tooltip.vue';
    export default {
      name: 'IconSharePoint',
      components: {
        Tooltip
      },
    }
  </script>
  
  <style lang="scss" scoped>
  .pointer-cursor{
    cursor: pointer;
  }
  </style>