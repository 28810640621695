import BaseApi from "./BaseApi";

export default class Downloads extends BaseApi{
    construct() {}
    

    static async get(id) {
        return await Downloads.getAxiosInstance().get(Downloads.getApiUrl() + id);
    }

    static async delete(id) {
        return await Downloads.getAxiosInstance().delete(Downloads.getApiUrl() + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/download/images/";
    }
    
}