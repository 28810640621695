<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <v-form @submit.prevent="submit"  class="mb-16">
       <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>

      <v-tabs :class="!machine ? 'd-none' : ''" v-model="tab">
        <v-tab>Detalhes</v-tab>
        <v-tab>Manutenção</v-tab>
        <v-tab>Modelos</v-tab>
        <v-tab v-if="$route.params.id != undefined && $root.session.hasPermission(['super', 'breakdown.read'])">Avarias</v-tab>
        <v-tab>Indicadores e Monitorização</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item class="mt-6" :key="1">
          <v-row>
            <v-col cols="12" md="auto" v-if="method!='create'">
              <v-card style="width: 175px; height:200px;" :class="getStatusColor(fields)">
                <v-card-title class="text-center">
                  <v-icon v-if="fields.status != 'Á ordem de obra ou com parte diária'" color="white" x-large style="margin: auto; display: block;">mdi-sync-alert</v-icon>
                  <v-icon v-else color="white" x-large style="margin: auto; display: block;">mdi-hand-okay</v-icon>
                </v-card-title>
                <v-card-text class="text-center text-body-1 white--text">
                  <v-row>
                    <v-col cols="12">
                      {{fields.status}}
                    </v-col>
                    <v-col cols="12" class="mt-n4">
                      <v-btn @click="breakdown_id = null; breakdowns.intervention = true;" :class="$root.$vuetify.theme.isDark ? '' : 'white'">Alterar<v-icon>mdi-refresh</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text class="text-center text-h6 white--text"></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="auto" v-if="method!='create'">
              <v-card style="width: 175px; height:200px;" class="primary">
                <v-card-title class="text-center">
                  <v-icon color="white" x-large style="margin: auto; display: block;">mdi-map-marker-distance</v-icon>
                </v-card-title>
                <v-card-text class="text-center text-h6 white--text">{{fields.counter_type == 'H' ? 'Horas' : 'KM'}} atuais:</v-card-text>
                <v-card-text class="mt-n7 text-center white--text text-body-1">{{fields.next_start == null ? '-' : fields.next_start}}</v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="auto" v-if="method!='create'">
              <v-card style="width: 175px; height:200px;" class="primary">
                <v-card-title class="text-center">
                  <v-icon color="white" x-large style="margin: auto; display: block;">mdi-book-alert</v-icon>
                </v-card-title>
                <v-card-text class="text-center text-h6 white--text">Última diária:</v-card-text>
                <v-card-text class="mt-n7 text-center white--text text-body-1">{{fields.last_diary == null ? '-' : fields.last_diary}}</v-card-text>
                <v-card-text class="mt-n7 text-center white--text text-caption">{{fields.last_diary_work == null ? '-' : fields.last_diary_work.code}}</v-card-text>
                <v-card-text v-if="fields.last_diary_work != null" class="mt-n7 text-center white--text text-caption">{{fields.last_diary_work == null ? '-' : fields.last_diary_work.name}}</v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="auto" v-if="method!='create'">
              <v-card style="width: 175px; height:200px;" class="accent">
                <v-card-title class="text-center">
                  <v-icon color="white" x-large style="margin: auto; display: block;">mdi-countertop</v-icon>
                </v-card-title>
                <v-card-text class="text-center text-h6 white--text">
                  <v-row>
                    <v-col cols="12" align="center" class="text-body-1 font-weight-bold">
                      Mês:
                    </v-col>
                    <v-col cols="12" align="center" class="text-caption mt-n6">
                      {{fields.machine_averages == null ? '-' : fields.machine_averages.media_mes}} l/h
                    </v-col>
                    <v-col cols="12" align="center" class="text-body-1 mt-n4 font-weight-bold">
                      Ano:
                    </v-col>
                    <v-col cols="12" align="center" class="text-caption mt-n6">
                      {{fields.machine_averages == null ? '-' : fields.machine_averages.media_ano}} l/h
                    </v-col>
                  </v-row>  
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="auto" v-if="method!='create'">
              <v-card style="width: 175px; height:200px;" color="#CC6600">
                <v-card-title class="text-center">
                  <v-icon color="white" x-large style="margin: auto; display: block;">mdi-book-alert</v-icon>
                </v-card-title>
                <v-card-text class="text-center text-caption white--text" v-if="fields.alerts.length > 0">
                  <v-row v-for="item in fields.alerts" :key="item.id">
                    <v-col cols="12" class="ma-0 pa-0">
                      {{item.time_alert}} - {{item.alert}}
                      <v-divider color="white"></v-divider>
                    </v-col>
                  </v-row>     
                </v-card-text>                
                <v-card-text v-else class="text-center text-h6 white--text">
                  <v-row>
                    <v-col cols="12" align="center" class="text-body-1 font-weight-bold">
                      Sem avisos
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <fieldset :disabled="loading" :readonly="readOnly">
            <v-row class="mt-8">
              <v-col cols="12" md="3">
                <validation-provider v-slot="{ errors }" vid="code" name="Código" rules="required">
                  <v-text-field
                    v-model="fields.code"
                    prepend-inner-icon="mdi-barcode"
                    label="Código *"
                    :clearable="!readOnly"
                    :readonly="readOnly"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">
                  <v-text-field
                    v-model="fields.serial_number"
                    prepend-inner-icon="mdi-serial-port"
                    label="Número de série"
                    :clearable="!readOnly"
                    :readonly="readOnly"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <validation-provider v-slot="{ errors }" vid="Abreviatura" name="Abreviatura" rules="|">
                  <v-text-field
                    v-model="fields.abbreviation"
                    prepend-inner-icon="mdi-form-textbox"
                    label="Abreviatura"
                    :clearable="!readOnly"
                    :readonly="readOnly"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="2">  
                <validation-provider v-slot="{ errors }" vid="Contador" name="Contador" rules="required">
                  <v-select
                      v-model="fields.counter_type"
                      prepend-icon="mdi-map-marker-distance"
                      label="Contador de kms/horas *"
                      :items="distance_marks"
                      item-text="text"
                      item-value="id"
                      :readonly="readOnly"
                      :error-messages="errors"
                    />
                  </validation-provider>                 
              </v-col>
              <v-col cols="12" md="2">
                <validation-provider v-slot="{ errors }" vid="Media_Manual" name="Media_Manual" rules="|">
                  <v-text-field
                    v-model="fields.manual_average_km"
                    prepend-inner-icon="mdi-form-textbox"
                    label="Média (avisos)"
                    peristent-hint
                    hint="Este valor será utilizado para avisos de máquina"
                    :clearable="!readOnly"
                    :readonly="readOnly"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="12">
                <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
                  <v-text-field
                    v-model="fields.name"
                    prepend-inner-icon="mdi-form-textbox"
                    label="Nome *"
                    :clearable="!readOnly"
                    :readonly="readOnly"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="3">              
                <validation-provider v-slot="{ errors }" vid="value" name="Custo" rules="required|min_value:0">
                  <v-currency-field
                      v-model="fields.value"
                      prepend-icon="mdi-currency-eur"
                      label="Custo *"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                      :error-messages="errors"
                    />
                  </validation-provider>         
              </v-col>
              <v-col cols="12" md="2">              
                <validation-provider v-slot="{ errors }" vid="value" name="Custo" rules="required">
                  <v-autocomplete
                      v-model="fields.machine_unit"
                      prepend-icon="mdi-tape-measure"
                      label="Unidade"
                      :items="['dia', 'mes']"
                      :readonly="readOnly"
                      :error-messages="errors"
                    />
                  </validation-provider>         
              </v-col>
              <v-col cols="12" md="4">              
                <validation-provider v-slot="{ errors }" vid="category" name="Categoria" rules="|">
                  <v-select
                      v-model="fields.category"
                      :items="eq_categories"
                      menu-props="auto"
                      label="Categoria"
                      prepend-inner-icon="mdi-factory"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>         
              </v-col>
              <v-col cols="12" md="3">              
                <validation-provider v-slot="{ errors }" vid="external" name="Externa" rules="|">
                  <v-checkbox
                      v-model="fields.external"
                      :readonly="readOnly"
                      label="Externa"
                      :error-messages="errors"
                    ></v-checkbox>
                  </validation-provider>         
              </v-col>
              <v-col cols="12" md="3">              
                <validation-provider v-slot="{ errors }" vid="central_cost" name="Centro de custo" rules="|">
                  <v-text-field
                      v-model="fields.central_cost"
                      prepend-icon="mdi-ticket-confirmation-outline"
                      label="Centro de custo"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                      :error-messages="errors"
                    />
                  </validation-provider>         
              </v-col>
              <v-col cols="12" md="2">
                <v-checkbox
                  v-model="fields.uses_diesel"
                  :readonly="readOnly"
                  label="Diesel"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="2">
                <v-checkbox
                  v-model="fields.uses_ad_blue"
                  :readonly="readOnly"
                  label="Ad Blue"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                  <v-row>
                    
                  <v-col cols="12" md="8">
                    <v-autocomplete
                      v-model="fields.id_company"
                      :items="companies"
                      :item-text="item => item.name"
                      item-value="id"
                      menu-props="auto"
                      label="Fornecedor"
                      prepend-inner-icon="mdi-factory"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                    ></v-autocomplete>
                  </v-col>              
                  <v-col cols="12" md="2">              
                    <validation-provider v-slot="{ errors }" vid="manufacture_year" name="Ano fabrico" rules="numeric">
                      <v-text-field
                          v-model="fields.manufacture_year"
                          prepend-icon="mdi-timer"
                          label="Ano fabrico"
                          :clearable="!readOnly"
                          :readonly="readOnly"
                          :error-messages="errors"
                        />
                      </validation-provider>         
                  </v-col>
                  <v-col cols="12" md="2">              
                    <validation-provider v-slot="{ errors }" vid="purchase_date" name="Ano compra" rules="numeric">
                      <v-text-field
                          v-model="fields.purchase_date"
                          prepend-icon="mdi-chart-timeline"
                          label="Ano compra"
                          :clearable="!readOnly"
                          :readonly="readOnly"
                          :error-messages="errors"
                        />
                      </validation-provider>         
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="12">              
                <v-text-field
                    v-model="fields.sharepoint_link"
                    prepend-icon="mdi-microsoft-sharepoint"
                    label="Link Sharepoint"
                    :clearable="!readOnly"
                    :readonly="readOnly"
                  />       
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="fields.obs"
                  name="input-7-1"
                  outlined
                  filled
                  label="Observações"
                  auto-grow
                />
              </v-col>
            </v-row>
            
            <v-row class="mt-6" align="center" justify="space-around">
              <v-col align="center" justify="space-around">
                <v-btn v-if="!readOnly" :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                  Gravar
                </v-btn>
              </v-col>
            </v-row>
            
          </fieldset>
        </v-tab-item>
        <v-tab-item class="mt-6" :key="2">
          <fieldset :disabled="loading" :readonly="readOnly">
            <v-card class="elevation-0">
              <v-card-title class="primary white--text">
                <v-icon color="white" class="mr-4">mdi-wrench</v-icon>Manutenção periódica
              </v-card-title>
              <v-card-text>
                <v-row class="mt-2">
                  <v-col cols="12" md="auto">
                    <v-switch                
                      :readonly="readOnly"
                      v-model="show_km_hour"
                      color="success"
                      :label="show_km_hour ? 'Mostrar alerta por horas' : 'Mostrar alerta por KM'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="4" v-if="!show_km_hour">
                    <v-text-field
                      v-model="fields.km_alert"
                      prepend-inner-icon="mdi-signal-distance-variant"
                      type="number"
                      label="KM"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                      @blur="changedPercentageAlert"
                    />
                  </v-col>
                  <v-col cols="12" md="4" v-else>
                    <v-text-field
                      v-model="fields.hour_alert"
                      prepend-inner-icon="mdi-clock"
                      type="number"
                      label="Hora"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                      @blur="changedPercentageAlert"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="fields.percentage_alert"
                      prepend-inner-icon="mdi-car-speed-limiter"
                      type="number"
                      label="Limite"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                      @blur="changedPercentageAlert"
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="fields.maintenance_plan"
                      prepend-inner-icon="mdi-link-box"
                      label="Link Sharepoint Plano Manutenção"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-menu
                      v-model="show_maintenance_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider v-slot="{ errors }" vid="finished_at" name="Data Fim">
                          <v-text-field
                            label="Próxima manutenção"
                            v-model="fields.maintenance_date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="fields.maintenance_date = null"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="fields.maintenance_date"
                        locale="pt-pt"
                        @input="show_maintenance_date = false;"
                        :readonly="readOnly"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-menu
                      v-model="show_last_maintenance_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider v-slot="{ errors }" vid="finished_at" name="Data Fim">
                          <v-text-field
                            label="Última manutenção"
                            v-model="fields.last_maintenance_date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="fields.last_maintenance_date = null"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="fields.last_maintenance_date"
                        locale="pt-pt"
                        @input="show_last_maintenance_date = false;"
                        :readonly="readOnly"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="fields.last_maintenance_plan"
                      prepend-inner-icon="mdi-link-box"
                      label="Link Sharepoint Último Plano Manutenção"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-title class="primary white--text">
                <v-icon color="white" class="mr-4">mdi-safety-goggles</v-icon>Alertas Inspeção
                <v-spacer></v-spacer>
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="eq_model = 'Mod007EQ'">
                      <v-list-item-title>Mod 007/EQ</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="eq_model = 'Mod008EQ'">
                      <v-list-item-title>Mod 008/EQ</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="eq_model = 'Mod009EQ'">
                      <v-list-item-title>Mod 009/EQ</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="eq_model = 'Mod010EQ'">
                      <v-list-item-title>Mod 010/EQ</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="eq_model = 'Mod011EQ'">
                      <v-list-item-title>Mod 011/EQ</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-text>
                <v-row class="mt-2">
                  <v-col cols="12" md="4">
                    <v-menu
                      v-model="show_inspection"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider v-slot="{ errors }" vid="finished_at" name="Data Fim">
                          <v-text-field
                            label="Próxima inspeção"
                            v-model="fields.inspection_date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="fields.inspection_date = null"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="fields.inspection_date"
                        locale="pt-pt"
                        @input="show_inspection = false;"
                        :readonly="readOnly"  
                      ></v-date-picker>
                    </v-menu>
                  </v-col>              
                  <v-col cols="12" md="auto">
                    <v-text-field
                      v-model="fields.maintenance_previous_days"
                      prepend-inner-icon="mdi-ticket-confirmation"
                      type="number"
                      label="Aviso prévio (dias)"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-col align="center" justify="space-around">
                <v-btn v-if="!readOnly" :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                  Gravar
                </v-btn>
              </v-col>
            </v-row>
          </fieldset>
        </v-tab-item>
        <v-tab-item class="mt-6" :key="3">
          <Models :key="modelsLoader" @new-model="createModel" @open-model="openModel"/>
        </v-tab-item>
        <v-tab-item class="mt-6" :key="4"
              v-if="$root.session.hasPermission(['super', 'breakdown.read'])">
          <v-row justify="end">
            <v-col cols="12" md="auto" v-if="$root.session.hasPermission(['super', 'breakdown.create'])">        
              <v-btn class="success white--text" @click="breakdown_id = null; breakdowns.intervention = true;">Adicionar intervenção</v-btn>
            </v-col>
            <v-col cols="12" md="auto">     
              <v-btn class="error white--text" @click="calendarDate = null;">Limpar filtros</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
                <v-date-picker
                    id="picker"
                    style="width: 100% !important;"
                    v-model="selectedDate"
                    :event-color="date => date[9] % 2 ? 'red' : 'yellow'"
                    :events="functionEvents"
                    @change="changeMachineDate"
                ></v-date-picker>
            </v-col>
            <v-col cols="12" md="8">
                  <ListInterventions @clean="changeMachineDate(null)" :selectedDate="calendarDate" :key="reloadInterventions" @reload="reloadInterventions++;$emit('reload');" @view="openIntervention"/>
            </v-col>
            <v-col cols="12">
              <span class="text-h6">Legendas:</span>
            </v-col>
            <v-col cols="12" md="auto">
              <v-icon x-large color="red">mdi-circle-small</v-icon>Avaria total
            </v-col>
            <v-col cols="12" md="auto">
              <v-icon x-large color="warning">mdi-circle-small</v-icon>Avaria parcial
            </v-col>
            <v-col cols="12" md="auto">
              <v-icon x-large color="blue">mdi-circle-small</v-icon>Correção de avaria
            </v-col>
            <v-col cols="12" md="auto">
              <v-icon x-large color="success">mdi-circle-small</v-icon>Á ordem de obra ou com parte diária
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class="mt-6" :key="5">
          <v-card class="elevation-0">
            <v-card-title class="primary white--text" v-if="false">
              Exportação
            </v-card-title>
            <v-card-text v-if="false">
              <v-row>
                <v-col cols="12" md="2">
                  <v-menu
                      v-model="initialDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                  >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              label="Data inicial"
                              v-model="indicators.start_date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="fields.date = null"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                      v-model="indicators.start_date"
                      :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                      locale="pt-pt"
                      @input="initialDate = false;"
                      ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2">
                  <v-menu
                      v-model="finalDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :close-on-content-click="false"
                  >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              label="Data final"
                              v-model="indicators.end_date"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="fields.date = null"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                      v-model="indicators.end_date"
                      :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                      locale="pt-pt"
                      @input="finalDate = false;"
                      ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="auto">
                  <v-btn @click="exportMonitoring">Exportar</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="elevation-0">
            <v-card-title class="primary white--text">
              Configurações
            </v-card-title>
            <v-card-text>
              <fieldset :disabled="loading" :readonly="readOnly">
                <v-row class="mt-8">
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="fields.indice_utilizacao"
                      prepend-inner-icon="mdi-state-machine"
                      label="Indice de utilização (% p/ mês)"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="fields.indice_consumo_combustivel"
                      prepend-inner-icon="mdi-gas-station"
                      label="Indice de consumo de combustível"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="fields.indice_consumo_adblue"
                      prepend-inner-icon="mdi-car-state"
                      label="Indice de consumo de Adblue"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="fields.indice_avarias"
                      prepend-inner-icon="mdi-image-broken-variant"
                      label="Indice de avarias"
                      :clearable="!readOnly"
                      :readonly="readOnly"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-6" align="center" justify="space-around">
                  <v-col align="center" justify="space-around">
                    <v-btn v-if="!readOnly" :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                      Gravar
                    </v-btn>
                  </v-col>
                </v-row>
              </fieldset>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-form>

    <v-dialog
        v-model="breakdowns.intervention"
        max-width="60%"
      >
        <Intervention v-if="breakdowns.intervention"
          :id="breakdown_id"
          :breakdowns="fields.breakdowns"
          :states="breakdowns.states"
          @close-success="breakdowns.intervention = false; reloadInterventions++; $emit('reload')"
        />
      </v-dialog>


     <DialogSuccess :opened="success" @on-ok="onSuccess" >
        Máquina gravada com sucesso!
    </DialogSuccess>
    <ErrorSnackbar v-model="error.show" :message="error.message"/>

    <Mod007EQ :machine="machine" :model_id="model_id" @close="eq_model = null; modelsLoader++" v-if="eq_model == 'Mod007EQ'"/>
    <Mod008EQ :machine="machine" :model_id="model_id" @close="eq_model = null; modelsLoader++" v-if="eq_model == 'Mod008EQ'"/>
    <Mod009EQ :machine="machine" :model_id="model_id" @close="eq_model = null; modelsLoader++" v-if="eq_model == 'Mod009EQ'"/>
    <Mod010EQ :machine="machine" :model_id="model_id" @close="eq_model = null; modelsLoader++" v-if="eq_model == 'Mod010EQ'"/>
    <Mod011EQ :machine="machine" :model_id="model_id" @close="eq_model = null; modelsLoader++" v-if="eq_model == 'Mod011EQ'"/>
  </validation-observer>
</template>
<script>
import Machine from "@/api/Machine.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Company from "@/api/Company.js";
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import Intervention from '@/components/machines/Breakdowns/Intervention'
import ListInterventions from '@/components/machines/Breakdowns/List'
import Models from '@/components/machines/Models.vue'
import Mod007EQ from '@/components/machines/Forms/Mod007EQ.vue'
import Mod008EQ from '@/components/machines/Forms/Mod008EQ.vue'
import Mod009EQ from '@/components/machines/Forms/Mod009EQ.vue'
import Mod010EQ from '@/components/machines/Forms/Mod010EQ.vue'
import Mod011EQ from '@/components/machines/Forms/Mod011EQ.vue'

export default {
  components: {
    DialogSuccess,
    ErrorSnackbar,
    Intervention,
    ListInterventions,
    Mod007EQ,
    Mod008EQ,
    Mod009EQ,
    Mod010EQ,
    Mod011EQ,
    Models
  },
  props: {
   machine: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
    machine: {
      immediate: true, 
      handler (val) {
        this.fields.theme = this.$themes[0];

        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;

      

        if(this.fields.hour_alert != null)
          this.show_km_hour = true

        if(data.company != null){
          this.fields.id_company = data.company.id
          this.checkbox = true
        }
      }
    },
  },
  beforeCreate(){
  },
  data: () => ({
    distance_marks:[
      {
        id: 'H',
        text:'Horas'
      },
      {
        id: 'KM',
        text:'KM'
      },
      {
        id: 'SEM_CONTADOR',
        text: 'Sem contador'
      }
    ],
    tab: 0,
    calendarDate: null,
    reloadInterventions: 190,
    model_id: null,
    modelsLoader: 300,
    eq_model: '',
    menu: false,
    dates: [],
    breakdown_id: null,
    breakdowns:{
      intervention: false,
      dates: [],
      states: ['Avaria total', 'Avaria parcial', 'Correção de avaria', 'Á ordem de obra ou com parte diária'],
      intervention_model: null,
      intervention_object: null
    },
    selectedDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    readOnly: false,
    show_km_hour: false,
    show_maintenance_date: false,
    show_last_maintenance_date: false,
    show_inspection: false,
    checkbox: false,
    companies: [],
    eq_categories: ['Equipamento Fixo', 'Equipamento Móvel', 'Equipamento Móvel Ligeiro', 'Equipamento de Carga'],
    indicators:{
      start_date: null,
      end_date: null,
      machine_id: null
    },
    initialDate: false,
    finalDate: false,
    fields: {
      id: null,
      code: null,
      abbreviation: null,
      name:null,
      value:null,
      id_company: null,
      machine_unit: null,
      inspection_date: null,
      counter_type: 'KM',
      sharepoint_link: null,
      external: false,
      hour_alert: null,
      maintenance_date: null,
      manual_average_km: null,
      km_alert: null,
      percentage_alert: null,
      maintenance_previous_days: null,
      uses_ad_blue: false,
      uses_diesel: false,
      serial_number: null,
      obs: null,
      manufacture_year: null,
      purchase_date: null,
      eq_group: null,
      central_cost: null,
      maintenance_plan: null,
      last_maintenance_date: null,
      last_maintenance_plan: null,
      breakdowns: [],
      status: 'Á ordem de obra ou com parte diária',
      average_km: null
    },
    loading:false,
    error: {
      title: '',
      message: '',
      show: false
    },
    success: false,
  }),
  mounted(){
    Company.list().then(({data}) => {
        this.companies = data;
      });

      if(!this.$root.session.hasPermission(['super', 'machines.update', 'machines.write']))
        this.readOnly = true
  },
  methods:{
    createModel(item){
      this.model_id = null
      this.eq_model = item
    },
    openModel(item){
      this.model_id = item.id
      this.eq_model = item.model_name      
    },
    async exportMonitoring(){
      this.indicators.machine_id = this.$route.params.id
      await Machine.exportMonitoring(this.indicators)
        .then((resp) => {
          if(resp.data.success){
              if(resp.data.object == null)
              {
                  this.error.message = "Não há dados para mostrar"
                  this.error.show = true
                  this.exportingDocument = false
                  return
              }else 
                  window.open(resp.data.object)
          }
        });
    },
    getStatusColor(item){
      if(item.status == 'Avaria total')
        return 'red';
      if(item.status == 'Avaria parcial')
        return 'yellow darken-2'
      if(item.status == 'Correção de avaria')
        return 'blue'
      
      return 'success'
    },
    changeMachineDate(d){
      this.calendarDate = d
    },
    openIntervention(item){
      this.breakdown_id = item.id
      this.breakdowns.intervention = true
    },
    functionEvents(date){
      let color = []
      let acoes_corretivas = []
      this.fields.breakdowns.forEach(element => {
        if(element.corrective != null){
          if(element.corrective.breakdown_date == date)
            color.push('blue')
        }

        if(element.breakdown_date == date){
          if(element.status == 'Avaria total')
            color.push('red')
          if(element.status == 'Avaria parcial')
            color.push('yellow')
          if(element.status == 'Correção de avaria')
            color.push('blue')
          if(element.status == 'Á ordem de obra ou com parte diária')
            color.push('green')

            if(element.corrective != null){
              acoes_corretivas.push(element.corrective.breakdown_date)
            }
        }
      });

     
      if(color.length == 0)
        color = false

      return color
    },
    changedPercentageAlert(){
      if(!this.show_km_hour){
        if(this.fields.km_alert == null)
          return true
      }else{
        if(this.fields.hour_alert == null)
          return true
      }

      if(!this.show_km_hour){
        if(parseInt(this.fields.percentage_alert) > parseInt(this.fields.km_alert)){
          this.error.message = 'Limite de alerta deve ser inferior aos KM inseridos'
          this.error.show = true
          return false
        }
      }else{
        if(parseInt(this.fields.percentage_alert) > parseInt(this.fields.hour_alert)){
          this.error.message = 'Limite de alerta deve ser inferior às horas inseridas'
          this.error.show = true
          return false
        }
      }

      return true
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/admin/machines');
    },
    submit() {

      if(!this.changedPercentageAlert())
        return

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        Machine[this.method](this.fields, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    
  }
};
</script>
