<template>
    <span>
      <Tooltip
        class="my-4 mr-4"
        color="success"
        text="Aprovar tópico"
        top
      >
      
      <v-btn rounded small @click.stop="openConfirmationDialog = true" v-bind="$attrs" class="success"
          v-on="$listeners">
          <v-icon class="mr-2">mdi-thumb-up</v-icon>Aprovar tópico
      </v-btn>
      </Tooltip>
  
      
      
      <DialogConfirmation 
        title="Aprovar tópico" 
        type="warning" 
        :opened="openConfirmationDialog" 
        @on-submit="submit" 
        @on-cancel="openConfirmationDialog = false">
          Tem a certeza que pretende aprovar este tópico?
      </DialogConfirmation>

    </span>
  </template>
  
  <script>
    import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
    import Tooltip from '@/components/ui/Tooltip.vue';
    export default {
      name: 'uiIconRemove',
      components: {
        DialogConfirmation,
        Tooltip
      },
      data() {
        return {
          openConfirmationDialog: false,
        }
      },
      methods: {
        submit() {
          this.openConfirmationDialog = false
          this.$emit('on-submit')
        }
      }
    }
  </script>
  
  <style lang="scss" scoped>
  </style>