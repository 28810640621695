<template>
    <div>
        <v-row class="mr-4 ml-4">
            <v-col cols="12" md="2">
                <v-dialog
                    ref="dialogPicker1"
                    v-model="modalPicker1"
                    :return-value.sync="datePicker1"
                    persistent
                    width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="datePicker1"
                    label="Data inicio"
                    dense outlined
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="datePicker1"
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modalPicker1 = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogPicker1.save(datePicker1)"
                >
                    OK
                </v-btn>
                </v-date-picker>
            </v-dialog>
        </v-col>
        <v-col cols="12" md="2">
            <v-dialog
                ref="dialogPicker2"
                v-model="modalPicker2"
                :return-value.sync="datePicker2"
                persistent
                width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="datePicker2"
                    label="Data fim"
                    dense outlined
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="datePicker2"
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modalPicker2 = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogPicker2.save(datePicker2)"
                >
                    OK
                </v-btn>
                </v-date-picker>
            </v-dialog>
        </v-col>
        <v-col  v-if="fields.work_fronts.length > 0" cols="12" md="4">
            <v-autocomplete
                :items="fields.work_fronts"
                label="Frentes Obra"
                v-model="work_front"
                clearable
                dense outlined
                item-value="id"
                :item-text="item    =>  item.reference + ' - ' + item.description"
            />
        </v-col>
        <v-col cols="12" md="auto">
            <v-btn
                dark
                color="secondary"
                @click="gerarInformacao()"
            >
            Gerar informação 
                <v-icon small>mdi-plus-circle-multiple-outline</v-icon>                
            </v-btn>


            <v-menu offset-y v-if="fields.status != 'FINISHED'">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    >
                    <v-icon
                    right
                    dark
                    class="mr-2"
                    >
                    mdi-download
                    </v-icon>
                    Exportar
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                    v-for="(t, index) in exportItems"
                    :key="index"
                    @click="exportPerformanceObra(t.title)"
                    >
                    <v-list-item-title>{{ t.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
        </v-row>
        <v-card-title>
            Performance Geral Obra
        </v-card-title>
        <v-card-subtitle class="mt-n6">
            <small>(Escolha um intervalo de datas para aceder ás performances das U.O.)</small>
        </v-card-subtitle>
        <v-progress-linear
            v-if="generate_info"
            class="mt-2"
            indeterminate
        ></v-progress-linear>
        <v-simple-table v-if="(work_performance!=null)">
            <tbody>
                <tr>
                    <td>
                        <h3>Performance da Obra</h3>
                    </td>
                    <td>
                        {{work_performance.performance}}%
                    </td>
                </tr>
                <tr>
                    <td><h3>Acumulado horas trabalhadas</h3></td>
                    <td>{{work_performance.acumulado_horas}}</td>
                </tr>
                <tr>
                    <td><h3>Equipa média orçamentada</h3></td>
                    <td>{{work_performance.equipa_media_orc}}</td>
                </tr>
                <tr>
                    <td><h3>Prazo Obra Orç.</h3></td>
                    <td>{{work_performance.prazo_obra_orc}}</td>
                </tr>
                <tr>
                    <td><h3>Equipa média acumulada</h3></td>
                    <td>{{work_performance.equipa_media_acumulada}}</td>
                </tr>
                <tr>
                    <td><h3>Estimativa prazo</h3></td>
                    <td>{{work_performance.estimativa_prazo}}</td>
                </tr>
                <tr>
                    <td><h3>Dias de trabalho</h3></td>
                    <td>{{work_performance.dias_trabalho}}</td>
                </tr>
                <tr>
                    <td><h3>Horas.Homem de trabalho acumulado</h3></td>
                    <td>{{work_performance.horas_homem_trab_acumulado}}</td>
                </tr>
                <tr>
                    <td><h3>Horas.Homem média diária</h3></td>
                    <td>{{work_performance.horas_homem_media_diaria}}</td>
                </tr>
                <tr>
                    <td><h3>% Obra executada</h3></td>
                    <td>{{work_performance.obra_executada}}%</td>
                </tr>
                <tr v-if="(work_performance.partes_diarias.length > 0)">
                    <td colspan="3">
                        <v-card>
                            <v-card-title>
                                <v-switch
                                    v-model="showPDPerformance"
                                    label="Ver performance de partes diárias"
                                    color="success"
                                    hide-details
                                ></v-switch>
                            </v-card-title>
                            <v-card-text v-show="showPDPerformance">
                                <v-simple-table class="mt-6">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>Dia</th>
                                                <th>Performance</th>
                                                <th>Horas trabalhadas</th>
                                                <th>Nº Colaboradores Geonorte</th>
                                                <th>Nº Colaborades Externos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="d in work_performance.partes_diarias"  :key="d.id">
                                                <td>{{d.dia}}</td>
                                                <td>{{d.rendimento_uo}}%</td>
                                                <td>{{d.horas.toFixed(2)}}</td>
                                                <td>{{d.internos}}</td>
                                                <td>{{d.externos}}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        
        <v-row>
            <v-col cols="12" class="font-weight-bold">
                Legenda:
            </v-col>
            <v-col cols="12" md="auto">
                <span class="font-weight-bold text-caption">
                    <v-icon>mdi-circle-medium</v-icon>Interna
                </span>
            </v-col>
            <v-col cols="12" md="auto">
                <span class="text-decoration-underline font-weight-light text-caption">
                    <v-icon>mdi-circle-medium</v-icon>Subempreiteiro
                </span>
            </v-col>
        </v-row>
        <v-data-table
            v-if="showTable"
            :headers="workUnitsHeader"
            :items="work_performance.unidades"
            :single-expand="true"
            :expanded.sync="expanded"
            item-key="code"            
            show-expand
            class="elevation-1 mt-4 outlined"
        >
            <template v-slot:item.name="{ item }">
                <v-row>
                    <v-col cols="12">
                        <span :class="item.subcontractor_id == null ? 'font-weight-bold' : 'text-decoration-underline font-weight-light'">
                            {{item.name}} 
                        </span>                        
                        <span v-if="item.subcontractor_id != null" class="font-weight-bold text-decoration-none">{{item.subcontractor_id == null ? '' : '(' + item.subcontractor.name + ')'}}</span>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:expanded-item="{  item }">
                <td colspan="12">
                    <v-simple-table class="mt-8 mr-4 ml-4 mb-8">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Descrição
                                    </th>
                                    <th class="text-left">
                                        Valores
                                    </th>
                                    <th class="text-left">
                                        Unidades
                                    </th>
                                </tr>
                            </thead>
                            <tbody>                
                                <tr>
                                    <td class="text-left">
                                        <small>Unidade de obra</small>
                                    </td>                    
                                    <td class="text-left">
                                        {{item.code}}
                                    </td>
                                    <td class="text-left">
                                        {{item.name}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">
                                        <small>Equipa orçamentada</small>
                                    </td>
                                    <td class="text-left">
                                        {{item.team}}
                                    </td>
                                    <td class="text-left">
                                        Homens
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">
                                        <small>Quantidade orçamento</small>
                                    </td>
                                    <td class="text-left">
                                        {{item.qtd_orc}}
                                    </td>
                                    <td class="text-left">
                                        {{item.object_unit.unit}}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-left">
                                        <small>Prazo Orçamento</small>
                                    </td>
                                    <td class="text-left">
                                        {{ parseFloat(item.prazo_orc).toFixed(2) }}
                                    </td>
                                    <td class="text-left">
                                        dias
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-left">
                                        <small>Rendimento orçamento</small>
                                    </td>
                                    <td class="text-left">
                                        {{parseFloat(item.rend_orc).toFixed(2)}}
                                    </td>
                                    <td class="text-left">
                                        Homens/{{item.object_unit.unit}}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-left">
                                        <small>Rendimento Médio Homem.dia Orçamentado</small>
                                    </td>
                                    <td class="text-left">
                                        {{parseFloat(item.rend_homem_dia_orc).toFixed(2)}}
                                    </td>
                                    <td class="text-left">
                                        {{item.object_unit.unit}}/homem.dias
                                    </td>
                                </tr>

                                <tr v-if="false" class="primary"><td colspan="8"><v-divider></v-divider></td></tr>
                                

                                <tr>
                                    <td class="text-left">
                                        <small>Medição acumulada</small>
                                    </td>
                                    <td class="text-left">
                                        {{parseFloat(item.medicao_acumulada).toFixed(2)}}
                                    </td>
                                    <td class="text-left">
                                        {{item.object_unit.unit}}
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-left">
                                        <small>% U.O. executada</small>
                                    </td>
                                    <td class="text-left">
                                        {{item.percentagem_uo_executada}}
                                    </td>
                                    <td class="text-left">
                                        %
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-left">
                                        <small>Dias de trabalho com a tarefa</small>
                                    </td>
                                    <td class="text-left">
                                        {{parseFloat(item.dias_trabalho_tarefa).toFixed(0)}}
                                    </td>
                                    <td class="text-left">
                                        dias
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-left">
                                        <small>Afetação média de tarefa por dia</small>
                                    </td>
                                    <td class="text-left">
                                        {{parseFloat(item.afetacao_media_tarefa_dia).toFixed(2)}}
                                    </td>
                                    <td class="text-left">
                                        %
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-left">
                                        <small>Rendimento médio</small>
                                    </td>
                                    <td class="text-left">
                                        {{parseFloat(item.rendimento_medio).toFixed(2)}}
                                    </td>
                                    <td class="text-left">
                                        {{item.object_unit.unit}}/dias
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-left">
                                        <small>Rendimento Médio Homem.dia</small>
                                    </td>
                                    <td class="text-left">
                                        {{parseFloat(item.rend_medio_homem_dia).toFixed(2)}}
                                    </td>
                                    <td class="text-left">
                                        {{item.object_unit.unit}}/homens.Dias
                                    </td>
                                </tr>

                                <tr>
                                    <td class="text-left">
                                        <small>Rendimento Médio Homem.hora</small>
                                    </td>
                                    <td class="text-left">
                                        {{parseFloat(item.rend_medio_homem_hora).toFixed(2)}}
                                    </td>
                                    <td class="text-left">
                                        {{item.object_unit.unit}}/homens.Hora
                                    </td>
                                </tr>

                                <tr v-if="false" class="primary"><td colspan="8"><v-divider></v-divider></td></tr>
                                <tr v-if="item.data.length > 0">
                                    <td colspan="8">
                                        <v-simple-table class="mt-6">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left">Data</th>
                                                        <th class="text-left">Medição</th>
                                                        <th class="text-left">Afetação</th>
                                                        <th class="text-left">Rend. (%)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="d in item.data" :key="d.id*d.measure">
                                                        <td class="text-left">{{d.pd_date}}</td>
                                                        <td class="text-left">{{parseFloat(d.measure).toFixed(2)}}</td>
                                                        <td class="text-left">{{parseFloat(d.afetacao).toFixed(2)}}%</td>
                                                        <td class="text-left">{{parseFloat(d.rend).toFixed(2)}}%</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </td>
                                </tr>

                            </tbody>
                        </template>
                    </v-simple-table>
                </td>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import Performance from "@/api/Performance"

export default {
    mounted(){
        console.log(this.general_work_performance)
    },
    props:{
        workUnitList: Array,
        fields: Object,
        diaryParts: Array,
        general_work_performance: {
            default: null
        }
    },
    data(){
        return{
            generate_info: false,
            showPDPerformance: true,
            work_units_performance: null,
            work_performance: null,
            exportItems:[
            {
                'title': 'PDF'
            },
            {        
                'title': 'Excel'
            }
            ],
            performance_values:{
                media_obra: 0,
                medicao_acumulada:0,
                dias_trabalho:0,
                rendimento_medio:0,
                trabalhadores_internos: 0,
                trabalhadores_externos: 0
            },
            showTable: false,
            diaries: [],
            expanded: [],
            singleExpand: false,
            datePicker1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            datePicker2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            work_front: null,
            modalPicker1: false,
            modalPicker2: false,
            workUnitsHeader:[
                {
                    text: "Unidade de Obra",
                    align: "start", 
                    sortable: false,
                    value: "name",
                    color: "red"
                }
            ]
        }
    },
    methods:{
        async exportPerformanceObra(title){
            let data ={
                type: title,
                dataInicio: this.datePicker1,
                dataFim: this.datePicker2,
            }
            await Performance.exportWorkPerformance(this.$route.params.id, data)
                .then((resp) => {                
                 window.open(resp.data.url, '_blank')
                 Performance.delete(resp.data.path)
                .then(() => {
                    
                });
                 
            });

            
            /*
          window.open(resp.data.url, '_blank')*/
        },
        mediaObra(){
            let parent = this
            let somatorio = 0
            this.diaryParts.forEach(element => {
                somatorio = somatorio + parent.diaryPerformance(element)
            });

            return parseFloat(somatorio / this.diaryParts.length).toFixed(2) + "%"
        },
        diaryPerformance(d){
            let somatorio = 0
            let totalTeam = 0
            let parent = this
            d.worker_work_units.forEach(element => {
                if(element.line_number == '1')
                    totalTeam++
            });

            d.work_units.forEach(element => {
                parent.workUnitList.forEach(el2 => {      
                        
                    if(element.unit_id == el2.object.id){         
                        let workUnitRendimento = element.measure / d.workers.length / (totalTeam / el2.budgeted_income)
                        somatorio += workUnitRendimento
                    }
                });
            }); 
            
            if(isFinite(somatorio))
                return somatorio

            return 0
        },
        rendimentoDiaria(d, i){
            let mIndividual = this.medicodesIndividual(d)
            let formula = (mIndividual/d.workers.length) / (i.team/i.budgeted_income)
            
            return parseFloat(formula).toFixed(2)
        },
        countWorkQtyBudget(){
            let somatorio = 0
            this.workUnitList.forEach(element => {
                somatorio += element.qty_budgeted   
            });
            return somatorio
        },
        countWorkTeam(){
            let somatorio = 0
            this.workUnitList.forEach(element => {
                somatorio += element.team
            });
            return somatorio
        },
        performanceObra(){
            return 100
        },
        somaprazos(){
            let somatorio = 0
            this.workUnitList.forEach(element => {
                somatorio += element.qty_budgeted
            });
            return parseFloat(somatorio).toFixed(2)
        },
        equipaMediaOrc(i){
            let somatorio = 0
            let totalBudgeted = 0
            this.workUnitList.forEach(element => {
                totalBudgeted += element.qty_budgeted
            });
            somatorio = (i.team*i.qty_budgeted) / totalBudgeted
            return parseFloat(somatorio).toFixed(2)
        },
        medicodesIndividual(diary){
            let somatorio = 0
            
                
            diary.work_units.forEach(dir => {
                somatorio = somatorio + dir.measure
            });

            return somatorio
        },
        horasTrabalhadasIndividual(diary){
            let somatorio = 0
            
                
            diary.workers.forEach(w => {
                let started = w.started_hour + ':' + w.started_minute
                let finished = w.finished_hour + ':' + w.finished_minute
                let dt1 = new Date(new Date().toDateString() + ' ' + started)
                let dt2 = new Date(new Date().toDateString() + ' ' + finished)
                var diff =(dt2.getTime() - dt1.getTime()) / 1000;
                diff /= (60 * 60);
                let hours = Math. abs(Math. round(diff));
                somatorio += hours
                    
                
            });

            return somatorio
        },
        trabalhadoresExternos(){
            let somatorio = 0
            this.diaryParts.forEach(element => {
                element.workers.forEach(w => {
                    if(w.id_company != null)
                        somatorio++
                });
            });
            this.performance_values.trabalhadores_externos = somatorio
            return somatorio
        },
        trabalhadoresInternos(){
            let somatorio = 0
            this.diaryParts.forEach(element => {
                element.workers.forEach(w => {
                    if(w.id_company == null)
                        somatorio++
                });
            });
            this.performance_values.trabalhadores_internos = somatorio
            return somatorio
        },
        formatDateToDay(dt){
            let data = new Date(dt)
            
            return data.getDate() + "/" + (data.getMonth() + 1)
        },
        horasTrabalhadasTotal(){
            let somatorio = 0
            
            this.diaryParts.forEach(element => {
                
                element.workers.forEach(w => {
                    let started = w.started_hour + ':' + w.started_minute
                    let finished = w.finished_hour + ':' + w.finished_minute
                    let dt1 = new Date(new Date().toDateString() + ' ' + started)
                    let dt2 = new Date(new Date().toDateString() + ' ' + finished)
                    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
                    diff /= (60 * 60);
                    let hours = Math. abs(Math. round(diff));
                    somatorio += hours
                    
                });
                
            });

            return somatorio
        },
        somaColunasColaboradores(i){
            let somatorio = 0
            this.diaries.forEach(element => {
                element.worker_work_units.forEach(dir => {
                    
                    if(dir.unit_id == i.object.id)
                        somatorio = somatorio + dir.worked_hours
                });
            });

            return parseFloat(somatorio).toFixed(2);
        },
        somaUOdeParteDiaria(i){
            let somatorio = 0
            
            this.diaries.forEach(element => {
                element.work_units.forEach(dir => {
                    if(dir.unit_id == i.object.id)
                        somatorio = somatorio + dir.measure
                });
            });
            this.performance_values.dias_trabalho = somatorio
            return somatorio
        },
        somaMedicao(){
            let somatorio = 0
            
            this.diaries.forEach(element => {
                element.work_units.forEach(dir => {
                    somatorio = somatorio + dir.measure
                });
            });
            this.performance_values.medicao_acumulada = somatorio
            return somatorio
        },
        getTodayDate(){
            return new Date().toLocaleDateString("pt-PT")
        },
        async gerarInformacao(){
            this.generate_info = true
            this.showTable = false
            let data = {
                dataInicio: this.datePicker1,
                dataFim: this.datePicker2,
                workFront: this.work_front
            }
            let parent = this
            await Performance.exportWorkUnitPerformance(this.$route.params.id, data)
                .then((resp) => {
                    parent.work_performance = resp.data
            });

            this.showTable = true
            this.generate_info = false
        }
    }
}
</script>