import BaseApi from "./BaseApi";

export default class DiaryOffline extends BaseApi{
    construct() {}

    static async create(params) {
        return await DiaryOffline.getAxiosInstance().post(DiaryOffline.getApiUrl(),{metadata: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/offline-diaries/";
    }
}