import BaseApi from "./BaseApi";

export default class Logger extends BaseApi{
    construct() {}
    
    static async search(params) {
        return await Logger.getAxiosInstance().get(Logger.getApiUrl(),{params: params});
    }

    static async getLoggerVars(){
        return await Logger.getAxiosInstance().get(Logger.getApiUrl() + 'getVarActions');
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/logs/";
    }
}