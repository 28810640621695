import BaseApi from "./BaseApi";

export default class System extends BaseApi{
    construct() {}
 
    static async getStatus(params) {
        return await System.getAxiosInstance().get(System.getApiUrl() + 'get-status', params);
    }

    static async setStatus(params) {
        return await System.getAxiosInstance().post(System.getApiUrl() + 'set-status', params);
    }
 
    static async getSystemConfiguration(params) {
        return await System.getAxiosInstance().get(System.getApiUrl() + 'get-system-configurations', params);
    }

    static async setSystemConfiguration(params) {
        return await System.getAxiosInstance().post(System.getApiUrl() + 'set-system-configurations', params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/system/";
    }
}