<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-shield-account</v-icon> <span>Edição de Role</span>
      </h1>

      <div class="mt-16">
        <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>

        <template v-if="loading">
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-skeleton-loader
                type="button"
              ></v-skeleton-loader>
          </v-row>
        </template>
        <role v-else method="update" :role="role"></role>
      </div>
  </section>
</template>
<script>
import Role from '@/components/roles/Role'
import RoleApi from "@/api/Role.js";

export default {
  components: {
    Role
  },
  beforeCreate(){
    document.title = "GPD - Edição de Roles";
  },
  data: () => ({
      role: {},
      loading:true
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "roles.update"])) {
      this.$router.push('/admin');
    }

    RoleApi.find(this.$route.params.id).then(({data}) => { this.role = data; this.loading = false;});
  },
  methods:{
    
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Roles',
          disabled: false,
          to: '/admin/settings/roles',
          exact: true,
        },
        {
          text: 'Registo de Role',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
