import BaseApi from "./BaseApi";

export default class QueuedDocs extends BaseApi{
    construct() {}

   
    static async list(params) {
        let url = QueuedDocs.getApiUrl() + "list";

        return QueuedDocs.getAxiosInstance().get(url,{params: params});
    }



    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/queued-docs/";
    }
}
