<template>
    <v-card>
        <v-card-title>Escolha o intervalo:</v-card-title>
        <v-card-text>
        <v-col cols="12" md="12">
                <v-dialog
                ref="dialogPicker1"
                v-model="modalPicker1"
                :return-value.sync="datePicker1"
                persistent
                width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-select
                    :items="itemTypes"
                    v-model="selectedType"
                    label="Formato"
                ></v-select>
                <v-text-field
                    v-model="datePicker1"
                    label="Data inicio"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                </template>
                <v-date-picker
                v-model="datePicker1"
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modalPicker1 = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogPicker1.save(datePicker1)"
                >
                    OK
                </v-btn>
                </v-date-picker>
            </v-dialog>
        </v-col>
        <v-col cols="12" md="12">
            <v-dialog
                ref="dialogPicker2"
                v-model="modalPicker2"
                :return-value.sync="datePicker2"
                persistent
                width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="datePicker2"
                    label="Data fim"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
                
                <v-autocomplete
                    v-if="secondOption != null"
                    v-model="secondOptionModel"
                    :label="secondOptionLabel"
                    :items="secondOption"
                    :item-text="item => item.name"
                    item-value="id"
                    hide-selected
                >
                </v-autocomplete>
                </template>
                
                <v-date-picker
                v-model="datePicker2"
                scrollable
                >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modalPicker2 = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogPicker2.save(datePicker2)"
                >
                    OK
                </v-btn>
                </v-date-picker>
            </v-dialog>
        </v-col>
    </v-card-text>
    <v-card-actions>
        <v-col class="text-right">
            <v-btn
            class="mr-4"
            dark
            small
            color="red"
            @click="$emit('close')"
            >
            <v-icon small>mdi-close</v-icon>
            
            </v-btn>
            <v-btn
            dark
            small
            color="success"
            @click="enviarInformacao()"
            >
            <v-icon small>mdi-check</v-icon>
            Confirmar
            </v-btn>
        </v-col>        
    </v-card-actions>
    <ErrorSnackbar v-model="error.show" :message="error.message"/>
    </v-card>
</template>

<script>
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
export default {
    components:{
        ErrorSnackbar
    },
    props:{
        secondOption:{
            type: Array,
            default: null
        },
        secondOptionLabel:{
            type: String,
            default: ''
        }
    },
    methods:{
        enviarInformacao(){            
            this.$emit('confirm', this.datePicker1, this.datePicker2, this.selectedType, this.secondOptionModel)
        }
    },
    data(){
        return{
            error: {
                title: '',
                message: '',
                show:false,
            },
            secondOptionModel: null,
            itemTypes: ['Excel', 'PDF'],
            selectedType: 'Excel',
            datePicker1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            datePicker2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modalPicker1: false,
            modalPicker2: false,
        }
    }
}
</script>