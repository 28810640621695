<template>
    <section class="mt-16">
        <v-tabs slider-color="warning" grow class="pt-4" v-model="tab" @change="onChangeTab">
            <v-tab class="primary white--text" v-if="$root.session.hasPermission(['super', 'works.read'])">Obras</v-tab>
            <v-tab class="primary white--text" v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['dto']) || $root.session.hasRole(['ceo'])">Geral</v-tab>
            <v-tab class="primary white--text" v-if="$root.session.hasPermission(['super', 'diaries.read'])">Estatísticas</v-tab>
            <v-tab class="primary white--text" v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['dto']) || $root.session.hasRole(['ceo'])">Outros</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-progress-linear 
            v-if="loading"
            class="mt-2"
            indeterminate
            ></v-progress-linear>
            <v-tab-item class="mt-6" :key="1" v-if="!loading">
                <WorksTab :engineers="engineers" :commissioners="commissioners"/>
            </v-tab-item>
            <v-tab-item class="mt-6" :key="2" v-if="!loading">
                <GeneralTab />
            </v-tab-item>
            <v-tab-item class="mt-6" :key="3" v-if="!loading">
                <StatisticsTab />
            </v-tab-item>
            <v-tab-item class="mt-6" :key="4" v-if="!loading">
                <OthersTab />
            </v-tab-item>
        </v-tabs-items>
    </section>
</template>
<script>
import WorksTab from '@/components/dashboard/WorksTab.vue'
import GeneralTab from '@/components/dashboard/GeneralTab.vue'
import StatisticsTab from '@/components/dashboard/StatisticsTab.vue'
import OthersTab from '@/components/dashboard/OthersTab.vue'
import User from '@/api/User.js'

export default{
    name: "DashboardV2",
    components:{
        WorksTab,
        GeneralTab,
        StatisticsTab,
        OthersTab
    },
    data(){
        return{
            tab: 0,
            loading: true,
            engineers:[],
            commissioners:[],
        }
    },
    mounted(){
        if(localStorage["dashboard-v2-" + this.$root.session.id] != undefined){
    
            let data = JSON.parse(localStorage["dashboard-v2-" + this.$root.session.id]);

            this.tab = data.tab

        }

        this.fillBaseData()
    },
    methods:{
        async fillBaseData(){
            await User.list().then(({data}) => {

                this.commissioners = data.filter(item => item.roles?.some(role => role.name == "encarregado"));

                this.engineers = data.filter(item => item.roles?.some(role => role.name == "dto"));

            });

            this.loading = false
        },
        onChangeTab(){
            localStorage["dashboard-v2-" + this.$root.session.id] = JSON.stringify({
                tab: this.tab
            });
        }
    }
}
</script>