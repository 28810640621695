<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-hard-hat</v-icon> <span>Gestão de Clientes</span>
        </h1>
        <v-icon color="primary">
          mdi-eye
        </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
        Visualizar

        <v-icon color="red" class="ml-4">
          mdi-delete
        </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
        Remover
  
        <div style="position:relative">
        
          <v-switch
          style="position:absolute"
            v-model="filter.deleted_client"
            color="success"
            @change="searchClients"
            label="Mostrar eliminados"
          ></v-switch>
          
          <v-container>
            <v-speed-dial
              absolute
              v-model="speedDial"
              top
              right
              direction="bottom"
              :open-on-hover="true"
            >
              <template v-slot:activator>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs" v-on="on"
                      v-model="speedDial"
                      color="primary"
                      dark
                      x-large
                      fab
                    >
                      <v-icon v-if="speedDial">
                        mdi-close
                      </v-icon>
                      <v-icon v-else>
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Opções</span>
                </v-tooltip>
              </template>
              <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>   
              <v-tooltip right color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    fab
                    dark
                    small
                    color="secondary"
                    large
                    @click="openModalNewClient()"
                    v-if="$root.session.hasPermission(['super', 'clients.create'])"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Registar</span>
              </v-tooltip>
            </v-speed-dial>
          </v-container>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            class="mt-16"
            locale="pt-pt"
          >
          <template v-slot:item.created_at="{ item }">
              {{ getTableDate(item.created_at)}}
          </template>
  
          <template v-slot:item.updated_at="{ item }">
              {{ getTableDate(item.updated_at)}}
          </template>
  
          <template v-slot:item.deleted_client="{ item }">
            <IconView
                class="mr-2"
                @click="viewClient(item)"
              />
            <IconRemove
                v-if="$root.session.hasPermission(['super', 'clients.delete'])"
                class="mr-2"
                :reverse="filter.deleted_client"
                @on-submit="deleteClient(item)"
              />
          </template>
  
            
          </v-data-table>
          
        </div>
        <v-navigation-drawer
            fixed
            right
            v-model="searching"
            hide-overlay
            width="520"
            class="pt-6"
          >
          <v-list>
            <v-list-item>
              <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
  
              <v-btn absolute right small color="error" @click="resetClients">
                Limpar Campos
              </v-btn>
            </v-list-item>
          </v-list>
          <v-list>
            <v-form @submit.prevent="searchClients">
              <v-list-item>
                <v-text-field
                  v-model="filter.name"
                  dense outlined
                  prepend-inner-icon="mdi-barcode"
                  label="Nome"
                  clearable
                ></v-text-field>
              </v-list-item>

              <v-list-item>
                <v-text-field
                  v-model="filter.nif"
                  dense outlined
                  prepend-inner-icon="mdi-ticket-confirmation-outline"
                  label="Nif"
                  clearable
                ></v-text-field>
              </v-list-item>

              <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
            </v-form>
          </v-list>
  
        </v-navigation-drawer>
        <DialogSuccess :opened="approved" @on-ok="approved = false" >
          Empresa atualizada com sucesso
        </DialogSuccess>
        <v-dialog
          v-model="addClient"
          max-width="800px"
        >
          <AddClient 
            v-if="addClient"
            :editClient="client_selected"
            @close="addClient = false"
            @close-update="fillBaseData()"
          />
        </v-dialog>
    </section>
  </template>
  <script>
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  import dayjs from 'dayjs';
  import AddClient from '@/components/clients/Add.vue';
  import Client from "@/api/Client.js";
  import IconRemove from '@/components/ui/IconRemove.vue';
  import IconView from '@/components/ui/IconView.vue';
  
  export default {
    components: {
        AddClient,
      DialogSuccess,
      IconRemove,
      IconView
    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchClients()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      client_selected: null,
      headers: [
        {
          text: 'Nome',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Nif',
          sortable: true,
          value: 'nif',
        },
        {
          text: 'Criada a',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Última modificação a',
          sortable: true,
          value: 'updated_at',
        },
        {
          text: 'Ativa',
          sortable: false,
          value: 'deleted_client',
        }
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
        name:null,
        nif: null,
        deleted_client: false
      },
      status: [],
      approved:false,
      addClient: false
    }),
    mounted(){
  
      /*if(!this.$root.session.hasPermission(["super", "diaries.read"])) {
        this.$router.push('/admin');
      }*/
  
      //this.searchClients();

      this.fillBaseData()
    },
    methods:{
      openModalNewClient(){
        this.client_selected = null
        this.addClient = true
      },
      getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
      },
      fillBaseData() {
        this.addClient = false
        if(localStorage["clients-v2-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["clients-v2-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchClients(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
        
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          deleted_client: this.filter.deleted_client
        };
      
        Object.assign(request, filter);
  
        localStorage["clients-v2-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Client.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;  
          });
  
          this.loading = false;
  
        });
      },
      resetClients()
      {
        this.filter = {
          deleted_client: false,
          name: null
        }
        this.searchClients();
      },
      deleteClient(item){
        if(this.filter.deleted_client)
          item.deleted_client = false;
        else
          item.deleted_client = true
        Client.update(item, item.id)
          .then(() => {
            this.searchClients();
          });
      },
      viewClient(item){
        this.client_selected = item
        this.addClient = true
      }
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão de Clientes',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  