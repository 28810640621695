<template>
    <v-container fluid>
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          locale="pt-pt"
        >

            <template v-slot:item.unidade_obra="{item}">
                {{item.object.code}} - {{item.object.name}}
            </template>
            <template v-slot:item.unidade="{item}">
                {{item.object.unit}}
            </template>
            <template v-slot:item.subempreiteiro="{item}">
                {{item.subcontractor_id == null ? '-' : item.subcontractor.name}}
            </template>
            <template v-slot:item.performance="{item}">
                <v-switch 
                    inset
                    color="success"
                    v-model="item.enable_performance"
                    @change="changedSwitch(item)"
                /> 
            </template>

            <template v-slot:item.actions="{item}">
                <v-row>
                    <v-col cols="12" md="auto">
                        <IconView
                            @click="viewListWorkUnit(item)"                                      
                        />
                    </v-col>
                    <v-col cols="12" md="auto">
                        <IconRemove
                            v-if="$root.session.hasPermission(['super']) || $root.session.hasRole(['ceo', 'dto'])"
                            class="mr-2"
                            @on-submit="deleteWorkUnitList(item.id)"                                      
                        />
                    </v-col>
                </v-row>
            </template>
        </v-data-table>
        <v-row>
            <v-col cols="12" align="center" justify="center">
                <v-btn
                    fab
                    dark
                    color="primary"
                    class="mt-7"
                    @click="addNewUnit()"
                    >
                    <v-icon small>mdi-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <SuccessSnackBar class="mt-12" v-model="showSuccess" :message="'Alteração gravada com sucesso'" />

    <v-dialog
        v-model="showWorkList"
        persistent
        max-width="800px">
      <v-card>
            <fieldset>
              <v-card-title class="primary white--text">
                      Adicionar Unidade Obra
              </v-card-title>
              <v-card-text>
                  <v-row class="mt-4">
                      <v-col cols="12">                    
                        <v-autocomplete
                            v-model="newWorkUnit.work_id"
                            label="Unidade Obra *"
                            dense outlined
                            :items="units"
                            :item-text="item => item.name"
                            item-value="id"
                            hide-selected
                            clearable
                            >
                        </v-autocomplete>     
                      </v-col>   
                      <v-col cols="12" md="4">  
                              <v-autocomplete                                              
                                    v-model="newWorkUnit.work_id"
                                    dense outlined
                                    label="Código *"
                                    :items="units"
                                    :item-text="item => item.code"
                                    item-value="id"
                                    hide-selected
                                    clearable
                                >
                              </v-autocomplete>     
                      </v-col>
                      <v-col cols="12" md="4">
                          <v-autocomplete
                                v-model="newWorkUnit.work_id"
                                dense outlined
                                label="Unidade"
                                :items="units"
                                :disabled="true"
                                :item-text="item => item.unit"
                                item-value="id"
                                hide-selected
                                clearable
                            >
                            </v-autocomplete>   
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-switch
                            class="mt-0"                        
                            v-model="newWorkUnit.enable_performance"
                            label="Calcular performance"
                            inset color="success"
                        /> 
                      </v-col>
                      <v-divider class="mt-4 mb-4"></v-divider>
                  </v-row>
                  <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                            v-model="newWorkUnit.qty_budgeted"
                            dense outlined
                            prepend-inner-icon="mdi-form-textbox"
                            label="Qtd. Orç."
                            clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                            v-model="newWorkUnit.budgeted_income"
                            dense outlined
                            small
                            prepend-inner-icon="mdi-google-circles-communities"
                            label="Rend. Orç."
                            clearable
                        >
                            
                            <template v-slot:append>        
                                <v-icon>{{ newWorkUnit.unit_measure }}</v-icon> 
                            </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                              <v-text-field
                                    v-model="newWorkUnit.team"
                                    dense outlined
                                    prepend-inner-icon="mdi-counter"
                                    label="Equipa"
                                    clearable
                              ></v-text-field>
                      </v-col>              
                      <v-col cols="12" md="4">
                        <v-autocomplete
                            :items="subcontractors"
                            dense outlined
                            v-model="newWorkUnit.subcontractor_id"
                            item-text="name"
                            item-value="id"
                            clearable
                            label="Subempreiteiros"
                        />
                      </v-col>
                  </v-row>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="error"
                  depressed
                  @click="closeWorkList"
              >
              Cancelar
              </v-btn>
              <v-btn :disabled="newWorkUnit.qty_budgeted == null || newWorkUnit.work_id == null ||newWorkUnit.budgeted_income == null" depressed color="primary" @click="addWorkUnitToWork">
                  Gravar
              </v-btn>            
            </v-card-actions>
            </fieldset>
      </v-card>
    </v-dialog>

    </v-container>
</template>
<script>
import WorkUnitList from '@/api/WorkUnitList.js'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import Unit from "@/api/Unit.js"
import Company from "@/api/Company.js"

export default{
    name: "WorkUnits",
    components:{
        SuccessSnackBar,
        IconView,
        IconRemove
    },
    watch: { 
        'options': {
            immediate: false,
            handler () {
                setTimeout(() => {this.searchWorkUnitList()}, 10);
            },
            deep: true,
        },
    },
    data(){
        return{       
            method: 'update',     
            subcontractors:[],
            units: [],
            newWorkUnit:{
                id: null,
                work_id: null,
                start_date: null,
                code: null,
                team: null,
                unit: null,
                qty_budgeted: null,
                budgeted_income: null,
                edit: false,
                enable_performance: true,
                work_unit_id: null,
                has_subcontractor: false,
                subcontractor_id: null
            },
            showWorkList: false,
            filter:{
                work_id: null
            },
            total: 0,
            showSuccess: false,
            loading: false,
            items: [],
            tableFooter: {
                'items-per-page-options': [5, 10, 15, 20],
                'items-per-page-text': "Linhas por página"
            },
            options: {},
            headers: [
                {
                    text: 'Unidade obra',
                    sortable: true,
                    align: "start",
                    value: 'unidade_obra',
                },
                {
                    text: 'Qtd. Orç.',
                    sortable: true,
                    align: "center",
                    value: 'qty_budgeted',
                },
                {
                    text: 'Uni.',
                    sortable: true,
                    align: "center",
                    value: 'unidade',
                },
                {
                    text: 'Rend. Orç.',
                    sortable: true,
                    align: "center",
                    value: 'budgeted_income',
                },
                {
                    text: 'Equipa',
                    sortable: true,
                    align: "center",
                    value: 'team',
                },
                {
                    text: 'Subempreiteiro',
                    sortable: true,
                    align: "center",
                    value: 'subempreiteiro',
                },
                {
                    text: 'Performance',
                    sortable: true,
                    align: "center",
                    value: 'performance',
                },
                { 
                    text: 'Opções', 
                    value: 'actions', 
                    sortable: false 
                },
            ],
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        addNewUnit(){
            this.method = 'create'
            this.newWorkUnit={
                id: null,
                work_id: null,
                id_work: this.$route.params.id,
                start_date: null,
                code: null,
                team: null,
                unit: null,
                qty_budgeted: null,
                budgeted_income: null,
                edit: false,
                enable_performance: true,
                work_unit_id: null,
                has_subcontractor: false,
                subcontractor_id: null
            }
            this.showWorkList = true
        },
        closeWorkList(){
            this.showWorkList = false
        },
        addWorkUnitToWork(){
            this.newWorkUnit.id_work_unit = this.newWorkUnit.work_id
            
            WorkUnitList[this.method](this.newWorkUnit, this.newWorkUnit.id).then((resp) =>  {
                console.log(resp)

                this.showWorkList = false
                this.showSuccess = true
                this.searchWorkUnitList()
                this.$emit('reload-work')
            })
        },  
        viewListWorkUnit(ob){
            this.method = 'update'
            WorkUnitList.find(ob.id).then((resp)    =>  {
                this.newWorkUnit = resp.data
                this.newWorkUnit.work_id = resp.data.id_work_unit

                this.showWorkList = true
            })
        },
        deleteWorkUnitList(id){
            WorkUnitList.delete(id).then((resp) =>  {
                console.log("Deleted line")
                console.log(resp)
                this.showSuccess = true
                this.searchWorkUnitList()
            })
        },
        changedSwitch(o){
            WorkUnitList.update(o, o.id).then((resp)    =>  {
                console.log("Work unit item from list updated")
                console.log(resp)
                this.$emit('reload-work')
                this.showSuccess = true
            })
        },
        fillBaseData(){
            if(localStorage["work-unit-list-in-work-" + this.$root.session.id] != undefined){

                let data = JSON.parse(localStorage["work-unit-list-in-work-" + this.$root.session.id]);

                this.filter = data.filter;

                this.filter.work_id = this.$route.params.id

                this.options = data.options;
            }

            Unit.list().then(({data}) => {
                this.units = data;
            });

            Company.list().then(({data})  =>  {
                this.subcontractors = data
            });
        },
        async searchWorkUnitList(){
            this.loading = true


            let filter = {...this.filter};
            
            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
            };

            
            Object.assign(request, filter);

            this.filter.work_id = this.$route.params.id

            localStorage["work-unit-list-in-work-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });

            await WorkUnitList.search(request).then(response => {

                this.total = response.data.total
                this.items = response.data.data     
                
                
                this.loading = false;

            });
        }
    }
}
</script>