var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"vid":"code","name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-barcode","label":"Código *","dense":"","outlined":"","clearable":"","error-messages":errors},model:{value:(_vm.fields.code),callback:function ($$v) {_vm.$set(_vm.fields, "code", $$v)},expression:"fields.code"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"name","name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Nome *","dense":"","outlined":"","clearable":"","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"phone_number","name":"Telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-phone","label":"Telefone *","dense":"","outlined":"","clearable":"","error-messages":errors},model:{value:(_vm.fields.phone_number),callback:function ($$v) {_vm.$set(_vm.fields, "phone_number", $$v)},expression:"fields.phone_number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Aniversário","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fields.birthday),callback:function ($$v) {_vm.$set(_vm.fields, "birthday", $$v)},expression:"fields.birthday"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.birthday_date),callback:function ($$v) {_vm.birthday_date=$$v},expression:"birthday_date"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker,"max":(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),"min":"1950-01-01"},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save},model:{value:(_vm.fields.birthday),callback:function ($$v) {_vm.$set(_vm.fields, "birthday", $$v)},expression:"fields.birthday"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{attrs:{"label":"Associar empresa"},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),_c('v-col',[_c('v-select',{attrs:{"disabled":!_vm.checkbox,"items":_vm.companies,"item-text":function (item) { return item.name; },"item-value":"id","menu-props":"auto","label":"Empresa *","prepend-inner-icon":"mdi-factory","clearable":""},model:{value:(_vm.fields.id_company),callback:function ($$v) {_vm.$set(_vm.fields, "id_company", $$v)},expression:"fields.id_company"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"vid":"value","name":"Valor","rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"prepend-inner-icon":"mdi-currency-eur","label":"Custo/h","clearable":"","error-messages":errors},on:{"blur":function($event){_vm.fields.value = _vm.fields.value == 0 ? null : _vm.fields.value}},model:{value:(_vm.fields.value),callback:function ($$v) {_vm.$set(_vm.fields, "value", $$v)},expression:"fields.value"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"category_id","name":"Categoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.categories,"item-text":"name","item-value":"id","menu-props":"auto","label":"Categoria","prepend-inner-icon":"mdi-shape","clearable":"","error-messages":errors},model:{value:(_vm.fields.category_id),callback:function ($$v) {_vm.$set(_vm.fields, "category_id", $$v)},expression:"fields.category_id"}}),_c('v-row',{staticClass:"px-3",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"color":"secondary","text":"","outlined":"","right":"","small":"","depressed":""},on:{"click":function($event){_vm.creatingCategory = true}}},[_vm._v(" Registar nova categoria ")])],1)]}}],null,true)})],1),(_vm.method=='update')?_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"warning"},on:{"click":_vm.criarUserWorker}},[_vm._v(" Criar conta de utilizador ")])],1):_vm._e()],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{staticClass:"mr-5 ml-5 mb-5",attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Gravar ")])],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Colaborador gravado com sucesso! ")])]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.creatingCategory),callback:function ($$v) {_vm.creatingCategory=$$v},expression:"creatingCategory"}},[_c('v-card',[_c('validation-observer',{ref:"formCategory",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.loadingCategory)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loadingCategory}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitCategory.apply(null, arguments)}}},[_c('v-card-title',[_c('h2',{staticClass:"secondary--text subtitle-1"},[_vm._v(" Categoria ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"name","name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-form-textbox","label":"Nome *","clearable":"","error-messages":errors},model:{value:(_vm.category.name),callback:function ($$v) {_vm.$set(_vm.category, "name", $$v)},expression:"category.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"value","name":"Custo","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"prepend-inner-icon":"mdi-currency-eur","label":"Custo *","clearable":"","error-messages":errors},model:{value:(_vm.category.value),callback:function ($$v) {_vm.$set(_vm.category, "value", $$v)},expression:"category.value"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":function($event){_vm.creatingCategory = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Gravar ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }