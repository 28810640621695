import BaseApi from "./BaseApi";

export default class Emails extends BaseApi{
    construct() {}
    
    static async diariesToApprove() {
        return await Emails.getAxiosInstance().get(Emails.getApiUrl() + 'diariesToApprove');
    }

    static async diariesToDo() {
        return await Emails.getAxiosInstance().get(Emails.getApiUrl() + 'diariesToDo');
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/emails/";
    }
}