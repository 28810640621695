<template>
    <section>
        <v-tabs v-model="tab" class="mt-6" background-color="primary white--text" dark>
            <v-tabs-slider color="orange darken-4"></v-tabs-slider>
            <v-tab>Exportações</v-tab>
            <v-tab>Histórico Background</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item class="mt-6" :key="1">
                <validation-observer ref="form" v-slot="{ invalid }">
                    <v-form @submit.prevent="submit" class="mb-16">
                        <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                            <v-progress-linear v-if="loadPage"
                            indeterminate
                            class="global-loader"
                        ></v-progress-linear>
                            <v-card-text>
                                <v-row v-if="!loadPage">
                                    <v-col cols="12" md="6">
                                        <validation-provider v-slot="{ errors }" vid="Ficheiro" name="Ficheiro" rules="required">
                                            <v-select
                                                v-model="fields.fileType"
                                                :items="getFiles"
                                                menu-props="auto"
                                                label="Ficheiro"
                                                item-text="name"
                                                item-value="id"
                                                :error-messages="errors"
                                                persistent-hint
                                                hint="Selecione o tipo de ficheiro"
                                                prepend-icon="mdi-file-delimited"
                                                single-line
                                                @change="changedDocument"
                                            ></v-select>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <validation-provider v-if="workSelect" v-slot="{ errors }" vid="Obra" name="Obra" rules="required">
                                            <v-autocomplete
                                                v-model="fields.workId"
                                                :items="works"
                                                menu-props="auto"
                                                :error-messages="errors"
                                                label="Obra"
                                                :item-text="item => item.code + ' - ' + item.name"
                                                item-value="id"
                                                persistent-hint
                                                hint="Selecione a obra"
                                                prepend-icon="mdi-hard-hat"
                                                single-line
                                            ></v-autocomplete>
                                        </validation-provider>
                                        <validation-provider v-if="machineSelect" v-slot="{ errors }" vid="Máquinas" name="Máquinas" rules="required">
                                            <v-autocomplete
                                                v-model="fields.machine_id"
                                                :items="machines"
                                                menu-props="auto"
                                                :error-messages="errors"
                                                label="Máquina"
                                                item-text="name"
                                                item-value="id"
                                                persistent-hint
                                                hint="Selecione a máquina"
                                                prepend-icon="mdi-hard-hat"
                                                single-line
                                            ></v-autocomplete>
                                        </validation-provider>
                                        <validation-provider v-if="uoSelect" v-slot="{ errors }" vid="Unidade Obra" name="Unidade Obra" rules="required">
                                            <v-autocomplete
                                                v-model="fields.unit_id"
                                                :items="units"
                                                menu-props="auto"
                                                :error-messages="errors"
                                                label="Unidade Obra"
                                                item-text="name"
                                                item-value="id"
                                                persistent-hint
                                                hint="Selecione a Unidade Obra"
                                                prepend-icon="mdi-hard-hat"
                                                single-line
                                            ></v-autocomplete>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <validation-provider v-if="computedChoosed" v-slot="{ errors }" vid="Formato" name="Formato" rules="required">
                                            <v-select
                                                :items="itemTypes"
                                                v-model="selectedType"
                                                prepend-icon="mdi-paperclip"
                                                :error-messages="errors"
                                                label="Formato"
                                            ></v-select>
                                        </validation-provider>
                                            <v-text-field
                                                v-else
                                                v-model="selectedType"
                                                label="Formato"
                                                prepend-icon="mdi-paperclip"
                                                readonly
                                                :disabled="true"
                                            ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3" v-if="justDate">
                                        <v-menu
                                            v-model="pickerInicio"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <validation-provider v-slot="{ errors }" vid="Inicio" name="Inicio" rules="required">
                                                <v-text-field
                                                    v-model="fields.start"
                                                    :disabled="dateDisabled"
                                                    label="Data de inicio"
                                                    :error-messages="errors"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </validation-provider>
                                            </template>
                                            <v-date-picker
                                            v-model="fields.start"
                                            :allowed-dates="!all_days_available ? (date) => date <= new Date(new Date()+1).toISOString().substr(0, 10) : null"
                                            @input="pickerInicio = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="3" v-if="justDate">
                                        <v-menu
                                            v-model="pickerFim"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <validation-provider v-slot="{ errors }" vid="Fim" name="Fim" rules="required">
                                                <v-text-field
                                                    v-model="fields.end"
                                                    :disabled="dateDisabled"
                                                    label="Data de fim"
                                                    :error-messages="errors"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </validation-provider>
                                            </template>
                                            <v-date-picker
                                            v-model="fields.end"
                                            :allowed-dates="!all_days_available ? (date) => date <= new Date(new Date()+1).toISOString().substr(0, 10) : null"
                                            @input="pickerFim= false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="3" v-if="!justDate">
                                        <v-menu
                                            v-model="month_picker"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <validation-provider v-slot="{ errors }" vid="Fim" name="Fim" rules="required">
                                                <v-text-field
                                                    v-model="fields.month_year"
                                                    :disabled="dateDisabled"
                                                    label="Medir ano, até"
                                                    :error-messages="errors"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </validation-provider>
                                            </template>
                                            <v-date-picker
                                            v-model="fields.month_year"
                                            type="month"
                                            :allowed-dates="!all_days_available ? (date) => date <= new Date(new Date()+1).toISOString().substr(0, 10) : null"
                                            @input="month_picker= false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-6" align="center" justify="space-around">
                                    <v-col align="center" justify="space-around">
                                    <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                                        Exportar
                                    </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-form>
                </validation-observer>
            </v-tab-item>
            <v-tab-item class="mt-6" :key="2">
                <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                    <v-card-title>Exportações</v-card-title>
                    <v-card-text>
                        <v-btn @click="fillQueue">Atualizar lista</v-btn>
                    </v-card-text>
                    <v-card-text :key="refreshList">
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    Documento
                                </th>
                                <th class="text-left">
                                    Gerado a
                                </th>
                                <th class="text-left">
                                    Ação
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in queued_docs"
                                :key="item.id"
                                >
                                <td>{{ item.document }}</td>
                                <td>{{ item.created_at }}</td>
                                <td>
                                    <v-btn outlined icon @click="openDownload(item.export_link)">
                                        <v-icon color="primary">mdi-download-box</v-icon>
                                    </v-btn>
                                </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <Loader v-if="loading"></Loader>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
        <DialogSuccess :opened="background_export" @on-ok="background_export = false" >
            O ficheiro contém muita informação. Iremos gerar o documento no nosso servidor e ele irá estar pronto dentro de breves minutos, na lista abaixo. Iremos também notificá-lo
        </DialogSuccess>

        <DialogSuccess :opened="redirected_export" @on-ok="redirected_export = false" >
            O seu ficheiro está a ser gerado. Irá aparecer nesta lista quando estiver pronto
        </DialogSuccess>
    </section>
</template>
<script>
import Work from "@/api/Work.js";
import Worker from "@/api/Worker.js";
import Unit from "@/api/Unit.js";
import Machine from "@/api/Machine.js";
import Export from "@/api/Export.js";
import QueuedDocs from "@/api/QueuedDocs.js"
import Loader from '@/components/ui/Loader.vue'
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    components:{
        Loader,
        ErrorSnackbar,
        DialogSuccess
    },
    props:{
        openBackground: Boolean
    },
    data(){
        return{
            redirected_export: false,
            refreshList: 0,
            tab: 0,
            background_export: false,
            queued_docs: [],
            dateDisabled: false,
            units: [],
            machines: [],
            error: {
                title: '',
                message: '',
                show:false,
            },
            chooseDocument: false,
            justDate: true,
            selectedType: 'Excel',
            month_picker: false,
            loadPage: false,
            loading: false,
            itemTypes: ['Excel', 'PDF'],
            fields:{
                fileType: null,
                month_year: null,
                workId: null,
                start: null,
                end: null,
                designation: '',
                machine_id: null,
                unit_id: null
            },
            all_days_available: false,
            workSelect: false,
            uoSelect: false,
            machineSelect: false,
            pickerInicio: false,
            pickerFim: false,
            works: [],
            files: [
                {
                    id: -1,
                    name: 'Relatório de todos equipamentos e viaturas',
                    type: '',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "all_equipments_vehicles.export"]),
                },
                {
                    id: 0,
                    name: 'Relatorio de equipamento e viatura por equipamento',
                    type: 'machine',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "equipments_vehicles_single.export"]),
                },
                {
                    id: 1,
                    name: 'Relatório de equipamentos e viaturas por obra',
                    type: 'works',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "equipments_vehicles_per_work.export"]),
                },
                {
                    id: 2,
                    name: 'Relatório de rendimentos de mão de obra',
                    type: 'works',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "work_hand_report_performance.export"]),
                },
                {
                    id: 3,
                    name: 'Colaboradores - Horas extra',
                    type: 'workers',
                    chooseDocument: true,
                    designation: 'extra_hours',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "workers_extra_hours.export"]),
                },
                {
                    id: 4,
                    name: 'Colaboradores - Ponto de mão de obra',
                    type: 'workers',
                    chooseDocument: true,
                    designation: 'work_entries',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "workers_entry_point.export"]),
                },
                {
                    id: 5,
                    name: 'Colaboradores - Refeições',
                    type: 'workers',
                    chooseDocument: true,
                    designation: 'meals',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "workers_meals.export"]),
                },
                {
                    id: 6,
                    name: 'Colaboradores - Trabalho noturno',
                    type: 'workers',
                    chooseDocument: true,
                    designation: 'night_work',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "workers_night_shift.export"]),
                },
                {
                    id: 7,
                    name: 'Colaboradores - Performance',
                    type: 'workers',
                    chooseDocument: true,
                    designation: 'performance',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "workers_performance.export"]),
                },
                {
                    id: 8,
                    name: 'Colaboradores - Férias',
                    type: 'workers',
                    chooseDocument: false,
                    designation: 'holidays',
                    all_days_available: true,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "workers_holidays.export"]),
                },
                {
                    id: 9,
                    name: 'Partes diárias por obra',
                    type: 'works',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "diaries.export"]),
                },
                {
                    id: 10,
                    name: 'Relatório de rendimentos médios por UO',
                    type: 'uo',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "average_performance_report_per_uo.export"]),
                },
                {
                    id: 11,
                    name: 'Relatório de rendimentos médios das UO',
                    type: '',
                    chooseDocument: true,
                    designation: '',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "average_performance_report_all_uo.export"]),
                },
                {
                    id: 14,
                    name: 'Listagem de unidades de medida',
                    type: 'units_management',
                    chooseDocument: false,
                    designation: '',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "units_measurement.export"]),
                },
                {
                    id: 15,
                    name: 'Listagem de unidades de obra',
                    type: 'unit_work',
                    chooseDocument: false,
                    designation: '',
                    all_days_available: false,
                    year_month: false,
                    has_permission: this.$root.session.hasPermission(["super", "work_units.export"]),
                },
                /*{
                    id: 16,
                    name: 'Rendimento de cada UO',
                    type: 'unit_work_measure_list',
                    chooseDocument: false,
                    designation: '',
                    all_days_available: false,
                    year_month: false
                },*/
                {
                    id: 17,
                    name: 'Indicadores e Monitorização de Máquinas',
                    type: 'machines_monitoring',
                    chooseDocument: false,
                    designation: '',
                    all_days_available: false,
                    year_month: true,
                    has_permission: this.$root.session.hasPermission(["super", "machines_monitoring.export"]),
                },
            ]
        }
    },
    async mounted(){
        this.loadPage = true
        await this.fillBaseData()

        if(this.openBackground){
            this.tab = 1
            this.redirected_export = true
        }
        this.loadPage = false
    },
    methods:{
        openDownload(l){
            window.open(l)

            QueuedDocs.list().then((resp)   =>  {
                this.queued_docs = resp.data
            })
        },
        changedDocument(e){
            this.workSelect = false
            this.machineSelect = false
            this.uoSelect = false

            this.files.forEach(element => {
                if(e == element.id){
                    if(element.type == 'works'){
                        this.workSelect = true
                    }
                    if(element.type == 'machine'){
                        this.machineSelect = true
                    }
                    if(element.type == 'uo'){
                        this.uoSelect = true
                    }
                    this.justDate = !element.year_month
                    this.chooseDocument = element.chooseDocument
                    if(this.chooseDocument == false)
                        this.selectedType = this.itemTypes[0]

                    this.fields.report = element.designation

                    this.all_days_available = element.all_days_available

                    if(element.id >= 12 && element.id <=16){
                        this.dateDisabled = true
                    }else this.dateDisabled = false
                }
            });
        },
        async submit(){
            this.loading = true
            let data = {
                type: this.selectedType,
                started_at: this.fields.start,
                finished_at: this.fields.end,
                report: this.fields.report,
                machine_id: this.fields.machine_id,
                unit_id: this.fields.unit_id,
                work_id: this.fields.workId,
            }

            if(this.fields.fileType <= 0){

                await Machine.exportEquipments(data)
                    .then((resp) => {

                    if(resp.data.success){
                        this.fields.machine_id = null
                        if(resp.data.object == 'job-runner'){
                            this.background_export = true
                        }else{
                            window.open(resp.data.object)
                        }
                    }

                    });
            }


            if(this.fields.fileType == 1){
                await Work.exportEquipments(this.fields.workId, data)
                    .then((resp) => {
                    this.exportingWorkEquipments = false;

                    this.exportingDocument = false

                    if(resp.data.success){
                        if(resp.data.object == 'job-runner'){
                            this.background_export = true
                        }else{
                            window.open(resp.data.object)
                        }

                        /*if(resp.data.object == null)
                        {
                            this.error.message = "Não há dados para mostrar"
                            this.error.show = true
                            this.exportingDocument = false
                            return
                        }else
                            window.open(resp.data.object)*/

                    }
                });
            }

            if(this.fields.fileType == 2){
                if(data.type == 'PDF'){
                    data = {
                        type: this.selectedType,
                        started_at: this.fields.start,
                        finished_at: this.fields.end,
                        report: 'performance',
                        machine_id: this.fields.machine_id,
                        unit_id: this.fields.unit_id,
                        work_id: this.fields.workId,
                    }
                    await Worker.exportReport(data)
                    .then((resp) => {
                        if(resp.data.object == 'job-runner'){
                            this.background_export = true
                        }else{
                            window.open(resp.data.object)
                        }

                });
                }else{
                    data = {
                        type: data.type,
                        data1: data.started_at,
                        data2: data.finished_at,
                        work_id: this.fields.workId,
                    }

                    await Export.exportMORendimentos(this.fields.workId, data)
                        .then((resp) => {
                            if(resp.data.success){
                                if(resp.data.object == 'job-runner'){
                                    this.background_export = true
                                }else{
                                    window.open(resp.data.object)
                                }
                            }

                    });
                }
            }

            if(this.fields.fileType >= 3 && this.fields.fileType <= 8){
                await Worker.exportReport(data)
                    .then((resp) => {
                        if(resp.data.success){
                            if(resp.data.object == null){
                                this.error.message = "Não há dados para mostrar"
                                this.error.show = true
                                this.exportingDocument = false
                                return
                            }else{
                                if(resp.data.object == 'job-runner'){
                                    this.background_export = true
                                }else{
                                    window.open(resp.data.object)
                                }

                            }
                        }

                });
            }


            if(this.fields.fileType == 9){
                data ={
                    dataInicial: data.started_at,
                    dataFinal: data.finished_at,
                    type: this.selectedType,
                    work: this.fields.workId,
                }

                await Export.exportDiaries(data)
                    .then((resp) => {
                        if(resp.data.url == null){
                            this.error.message = "Sem Partes Diárias para apresentar"
                            this.error.show = true
                            this.exportingDocument = false
                            return;
                        }
                        window.open(resp.data.url, '_blank')
                        Export.delete( resp.data.path)
                        .then(() => {
                            this.exportingDocument = false
                        });
                    });
            }

            if(this.fields.fileType == 10){
                data ={
                    dataInicial: data.started_at,
                    dataFinal: data.finished_at,
                    type: this.selectedType,
                    unit_id: this.fields.unit_id
                }

                await Export.exportRendimentoPorUO(this.fields.unit_id, data)
                    .then((resp) => {
                        if(resp.data == 'job-runner'){
                            this.background_export = true
                        }else{
                            window.open(resp.data.url)
                        }
                    });
            }

            if(this.fields.fileType == 11){
                data ={
                    dataInicial: data.started_at,
                    dataFinal: data.finished_at,
                    type: this.selectedType,
                }

                await Export.exportRendimentoUOs(data)
                    .then((resp) => {
                        if(resp.data == 'job-runner'){
                            this.background_export = true
                        }else{
                            window.open(resp.data.url, '_blank')
                            Export.delete( resp.data.path)
                            .then(() => {
                                this.exportingDocument = false
                            });
                        }

                    });
            }

            if(this.fields.fileType >= 12 && this.fields.fileType <= 16){
                let data = ''
                this.files.forEach(element => {
                    if(element.id == this.fields.fileType)
                        data = element.type
                });
                Export.generalExport(data)
                .then((resp) => {
                    window.open(resp.data.url, '_blank')
                    Export.delete( resp.data.path)
                    .then(() => {
                    });
                });
            }

            if(this.fields.fileType == 17){
                await Machine.exportAllMonitoring({month_year: this.fields.month_year})
                    .then((resp) => {
                    if(resp.data.success){
                        if(resp.data.object == null)
                        {
                            this.error.message = "Não há dados para mostrar"
                            this.error.show = true
                            this.exportingDocument = false
                            return
                        }else
                            window.open(resp.data.object)
                    }
                    });
            }


            this.loading = false
        },
        async fillBaseData(){
            await Work.list().then(({data}) => {
                this.works = data;
            });

            await Machine.list().then(({data}) => {
                let final = []
                data.forEach(element => {
                    if(element.has_actions == true)
                        final.push(element)
                });
                this.machines = final
            });

            await Unit.list().then(({data}) => {
                let final = []
                data.forEach(element => {
                    if(element.has_actions == true)
                        final.push(element)
                });
                this.units = final
            });

            this.fillQueue()

            // Exportações. Fim é o dia de hoje o Inicio são 30 dias antes, por defeito
            let dt = new Date(new Date().toISOString().substr(0, 10))
            dt.setDate(dt.getDate() - 30);
            this.fields.end = new Date().toISOString().substr(0, 10);
            this.fields.start = dt.toISOString().substr(0, 10);
        },
        fillQueue(){
            QueuedDocs.list().then((resp)   =>  {
                this.queued_docs = resp.data

                this.refreshList++
            })
        }
    },
    computed:{
        getFiles(){
            let aux = []

            this.files.forEach(element => {
                if(element.has_permission)
                    aux.push(element)
            });

            return aux
        },
        computedChoosed(){
            return this.chooseDocument
        }
    }
}
</script>