import BaseApi from "./BaseApi";

export default class Worker extends BaseApi{
    construct() {}
    
    static async list(params) {
        let url = Worker.getApiUrl() + "list";

        return Worker.getAxiosInstance().get(url,{params: params});
    }

    static async getWork(params) {
        let url = Worker.getApiUrl() + "work";

        return Worker.getAxiosInstance().get(url,{params: params});
    }

    static async search(params) {
        return await Worker.getAxiosInstance().get(Worker.getApiUrl(),{params: params});
    }

    static async calendar(params){
        return await Worker.getAxiosInstance().get(Worker.getApiUrl() + 'worker-calendar', {params: params})
    }

    static async create(params) {
        return await Worker.getAxiosInstance().post(Worker.getApiUrl(),params);
    }

    static async update(params, id) {
        return await Worker.getAxiosInstance().put(Worker.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await Worker.getAxiosInstance().delete(Worker.getApiUrl() + id);
    }

    static async find(id) {
        return await Worker.getAxiosInstance().get(Worker.getApiUrl() + id);
    }

    static async workerInDiary(params){
        let url = Worker.getApiUrl() + "worker-in-diary";

        return await Worker.getAxiosInstance().get(url, {params: params});
    }

    static async exportReport(params) {
        let url = Worker.getApiUrl() + "export";

        return Worker.getAxiosInstance().post(url, params);
    }

    static async validateHours(params){
        let url = Worker.getApiUrl() + "validate-hours";

        return Worker.getAxiosInstance().post(url, params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/workers/";
    }
}