import BaseApi from "./BaseApi";

export default class DiaryFree extends BaseApi{
    construct() {}
  
    static async list(params) {
        let url = DiaryFree.getApiUrl() + "list";

        return DiaryFree.getAxiosInstance().get(url,{params: params});
    }

    static async search(params) {
        return await DiaryFree.getAxiosInstance().get(DiaryFree.getApiUrl(),{params: params});
    }

    static async delete(id) {
        return await DiaryFree.getAxiosInstance().delete(DiaryFree.getApiUrl() + id);
    }

    static async create(params) {
        return await DiaryFree.getAxiosInstance().post(DiaryFree.getApiUrl(),params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/diary-free/";
    }
}