<template>
    <v-container fluid class="ma-0 pa-0" style="min-height: 450px;">
        <v-container class="ma-0 pa-0 fill-height">
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="success"
                  large
                  @click="$emit('new-model', 'Mod007EQ')"
                >
                  <v-icon>mdi-numeric-7-box</v-icon>
                </v-btn>
              </template>
              <span>Criar Mod007EQ</span>
            </v-tooltip>
            <v-tooltip right color="warning">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="warning"
                  large
                  @click="$emit('new-model', 'Mod008EQ')"
                >
                  <v-icon>mdi-numeric-8-box</v-icon>
                </v-btn>
              </template>
              <span>Criar Mod008EQ</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  @click="$emit('new-model', 'Mod009EQ')"
                >
                  <v-icon>mdi-numeric-9-box</v-icon>
                </v-btn>
              </template>
              <span>Criar Mod009EQ</span>
            </v-tooltip>
            <v-tooltip right color="orange">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="orange"
                  large
                  @click="$emit('new-model', 'Mod010EQ')"
                >
                  <v-icon>mdi-numeric-10-box</v-icon>
                </v-btn>
              </template>
              <span>Criar Mod010EQ</span>
            </v-tooltip>
            <v-tooltip right color="orange">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="orange"
                  large
                  @click="$emit('new-model', 'Mod011EQ')"
                >
                  <v-icon>mdi-numeric-1-box</v-icon>
                </v-btn>
              </template>
              <span>Criar Mod011EQ</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="footer"
            :options.sync="options"
            locale="pt-pt"
        >

        <template v-slot:item.actions="{item}">
            <v-row>
              <v-col cols="12" md="auto">
                <IconView
                  @click="$emit('open-model', item)"
                />
              </v-col>
              <v-col cols="12" md="auto">
                <IconRemove
                  class="mr-2"
                  @on-submit="deleteModel(item)"
                />
              </v-col>
            </v-row>
        </template>
        </v-data-table>
    </v-container>
</template>
<script>
import ModelsAPI from '@/api/Models'
import IconView from '@/components/ui/IconView.vue';
import IconRemove from '@/components/ui/IconRemove.vue';

export default{
    name: 'Models',
    components:{ IconView, IconRemove},
    watch: { 
        'options': {
            immediate: false,
            handler () {
                setTimeout(() => {this.searchData()}, 10);
            },
            deep: true,
        },
    },
    data(){
        return{
            speedDial: false,
            loading: true,
            items: [],
            total: 0,
            headers: [
                {
                    text: 'Modelo',
                    sortable: true,
                    value: 'model_name',
                },
                {
                    text: 'Criado a',
                    sortable: true,
                    value: 'created_at',
                },
                {
                    text: 'Criador',
                    sortable: true,
                    value: 'user',
                },
                { 
                    text: 'Opções', 
                    value: 'actions', 
                    sortable: false 
                },
            ],
            footer: {
                'items-per-page-options': [5, 10, 15, 20],
                'items-per-page-text': "Linhas por página"
            },
            options: {}
        }
    },
    methods:{
        deleteModel(item){
          ModelsAPI.delete( item.id)
          .then(() => {
              this.searchData()
          });
        },
        async searchData(){
            this.loading = true
            let filter = {...this.filter};
            

            let request = {
                machine_id: this.$route.params.id,
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
            };

    
            Object.assign(request, filter);

            localStorage["models-machine-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });

            await ModelsAPI.search(request).then(response => {

                this.total = response.data.total
                this.items = response.data.data     
                
                
                this.loading = false;

            });
        },   
    }
}
</script>