<template>
    <section class="mt-16">  
        <div style="position:relative">
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            class="mt-16"
            locale="pt-pt"
          > 
          <template v-slot:item.created_at="{ item }">
              {{ getTableDate(item.created_at)}}
          </template>
          </v-data-table>          
        </div>
    </section>
  </template>
  <script>
  import dayjs from 'dayjs';
  import Diary from "@/api/Diary.js";
  
  export default {
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchStory()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      client_selected: null,
      headers: [
        {
          text: 'Ação',
          sortable: true,
          value: 'action',
        },
        {
          text: 'Detalhe',
          sortable: true,
          value: 'explanation',
        },
        {
          text: 'Autor',
          sortable: true,
          value: 'user',
        },
        {
          text: 'Criada a',
          sortable: true,
          value: 'created_at',
        },
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
      },
      status: [],
    }),
    mounted(){
      this.fillBaseData()
    },
    methods:{
      getTableDate(date) {
        return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
      },
      fillBaseData() {
        this.addClient = false
        if(localStorage["diary-storybook-v2-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["diary-storybook-v2-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchStory(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
        
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          diary_id: this.$route.params.id
        };
      
        Object.assign(request, filter);
  
        localStorage["diary-storybook-v2-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        Diary.searchStorybook(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;  
          });
  
          this.loading = false;
  
        });
      }
    }    
  };
  </script>
  