<template>
    <v-container fluid class="ma-0 pa-0">
        <v-row class="ma-0 pa-0 mb-4" justify="center">
          <v-col cols="12" md="3" v-for="(m, index) in methereology" :key="m.id">      
            <!--DESKTOP-->
              <v-card v-if="work_object.status != 'FINISHED'" class="d-none d-lg-block mr-2 d-lg-block elevation-0 rounded-xl" outlined style="min-height: 225px !important;">
                <v-card-text :class="index == 0 ? 'font-weight-bold text-center warning white--text' : 'font-weight-bold text-center primary white--text'">
                  {{m.date}}
                </v-card-text>
                <v-card-text class="ma-0 pa-0 text-center">
                  <v-row class="mt-2"  justify="center" align="center">
                    <v-col cols="12" md="6" class="mt-2">
                      <v-img style="display: block; margin: auto;" contain width="150" :src="m.icon" />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row class="">
                        <v-col cols="12" class="my-0 py-0 mt-4 text-start">
                          <span class="text-caption font-weight-bold">Previsão: </span>{{m.condition}}
                        </v-col>
                        <v-col cols="12" class="my-0 py-0 text-start">
                          <span class="text-caption font-weight-bold">Max (Cº): </span>{{m.maxtemp_c}}
                        </v-col>
                        <v-col cols="12" class="my-0 py-0 text-start">
                          <span class="text-caption font-weight-bold">Min (Cº): </span>{{m.mintemp_c}}
                        </v-col>
                        <v-col cols="12" class="my-0 py-0 text-start">
                          <span class="text-caption font-weight-bold">Precip. (mm): </span>{{m.totalprecip_mm}}
                        </v-col>
                        <v-col cols="12" class="my-0 py-0 text-start">
                          <span class="text-caption font-weight-bold">Vento (kph): </span>{{m.totalprecip_mm}}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>                
              </v-card>
              <!--DESKTOP-->              
              <!--MOBILE -->
              <v-card class="d-sm-none elevation-0 rounded-xl" outlined style="min-height: 300px !important;">
                <v-card-text :class="index == 0 ? 'font-weight-bold text-center warning white--text' : 'font-weight-bold text-center primary white--text'">
                  {{m.date}}
                </v-card-text>
                <v-card-text class="ma-0 pa-0 text-center">
                  <v-row class="mt-2"  justify="center" align="center">
                    <v-col cols="12" md="6" class="mt-2">
                      <v-img style="display: block; margin: auto;" width="150" :src="m.icon" />
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row class="">
                        <v-col cols="12" class="my-0 py-0 mt-4 text-start">
                          <span class="font-weight-bold">Previsão: </span>{{m.condition}}
                        </v-col>
                        <v-col cols="12" class="my-0 py-0 text-start">
                          <span class="font-weight-bold">Max (Cº): </span>{{m.maxtemp_c}}
                        </v-col>
                        <v-col cols="12" class="my-0 py-0 text-start">
                          <span class="font-weight-bold">Min (Cº): </span>{{m.mintemp_c}}
                        </v-col>
                        <v-col cols="12" class="my-0 py-0 text-start">
                          <span class="font-weight-bold">Precip. (mm): </span>{{m.totalprecip_mm}}
                        </v-col>
                        <v-col cols="12" class="my-0 py-0 text-start">
                          <span class="font-weight-bold">Vento (kph): </span>{{m.totalprecip_mm}}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>                
              </v-card>
              <!--MOBILE -->
          </v-col>
        </v-row>
      <v-card>
          <v-card-title :class="$root.$vuetify.theme.isDark ? 'secondary' : 'primary white--text'" >
            Morada
          </v-card-title>
          <v-card-text class="mt-4">
            <v-row>
              <v-col cols="12" md="2" class="font-weight-bold">
                Pais
              </v-col>
              <v-col cols="12" md="10">
                {{fields.country}}
              </v-col>

              
              <v-col cols="12" md="2" class="font-weight-bold">
                Morada
              </v-col>
              <v-col cols="12" md="10">
                <p>{{fields.route}}</p>
                <p>{{fields.administrative_area_level_2}}</p>
                <p>{{fields.administrative_area_level_1}}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      <v-row class="mt-4">
        <v-col cols="12">
          <v-card  outlined>
            <gmap-autocomplete
              style="width: 100%; height: 100% !important; border-bottom: black solid 1px;
                      font-size: 18px;
                      height: 40px;
                      padding: 0 10px;
                      z-index: 1;"
              placeholder="Escreva aqui uma morada"
              @place_changed="setPlace">
            </gmap-autocomplete>
          </v-card>
        </v-col>
        <v-col cols="12" md="2" v-if="markers.length > 0">
          <v-btn @click="centerMap" depressed color="primary" class="elevation-10">
            <span class="white--text"><v-icon class="mr-2">mdi-image-filter-center-focus</v-icon>Centrar</span>
          </v-btn>
        </v-col>
        <v-col cols="12" md="2" v-if="markers.length == 0">
          <v-btn @click="drawCircle" depressed color="primary" class="elevation-10">
            <span class="white--text"><v-icon class="mr-2">mdi-draw-pen</v-icon>Desenhar</span>
          </v-btn>
        </v-col>
        <v-col cols="12" md="2" v-if="removeMarkerButton && markers.length > 0">          
          <v-btn @click="cleanMarkers" depressed color="error" class="elevation-10">
            <span class="white--text"><v-icon class="mr-2">mdi-trash-can</v-icon>Remover</span>
          </v-btn>
        </v-col>
      </v-row>
        <GmapMap
          v-if="!loading"
          :key="gmapReloaded"
          class="mt-4 elevation-10"
          ref="gmap"
          :center="center"
          :options="getOptions"
          :zoom="zoom"
          :map-type-id="satellite ? 'satellite' : 'roadmap'"
          style="width: 100%; height: 500px"
        >

        <GmapCircle
            v-for="(m, index) in markers"
            :key="index"
            :draggable="true"
            :center="m.position"
            :position="m.position"
            :radius="55"
            @center_changed="updateCoordinates"
            :visible="true"
            :options="{
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity:0.35
                      }"
            @click="showRemoveMarker(m)"
            
          />
          <!--<GmapMarker

            :radius="55"


            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center=m.position"
          />-->
        </GmapMap>

        <v-row justify="center" class="mt-4">
          <v-col cols="12" md="auto">
            <v-btn :disabled="this.markers.lenght == 0" @click="openConfirmation" depressed color="success">
              <span class="white--text"><v-icon class="mr-2">mdi-content-save</v-icon>Guardar</span>
            </v-btn>
          </v-col>
        </v-row>
        <DialogConfirmation 
        v-if="openConfirmationDialog" 
        title="Confirme a sua ação" 
        type="warning" 
        :opened="openConfirmationDialog" 
        @on-submit="saveMapMarkers" 
        @on-cancel="centerMap(); openConfirmationDialog = false">
          <v-row>
            <v-col cols="12">
              Tem a certeza que a localização que deseja está bem?
            </v-col>
          </v-row>
          <v-row v-if="markers.length > 0">
            <v-col cols="12" md="4" class="font-weight-bold">
              Pais
            </v-col>
            <v-col cols="12" md="8">
              {{fields.country}}
            </v-col>

            
            <v-col cols="12" md="4" class="font-weight-bold">
              Morada
            </v-col>
            <v-col cols="12" md="8">
              <p>{{fields.route}}</p>
              <p>{{fields.administrative_area_level_2}}</p>
              <p>{{fields.administrative_area_level_1}}</p>
            </v-col>
          </v-row>
      </DialogConfirmation>
      <SuccessSnackBar v-model="modalSuccess.show" :message="modalSuccess.message" />
    </v-container>
</template>
<script src="http://maps.googleapis.com/maps/api/js?sensor=false"></script> 
<script>
import Work from '@/api/Work.js'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';
import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
// https://www.npmjs.com/package/vue2-google-maps
export default{
    name:"Localization",
    components:{
      SuccessSnackBar,
      DialogConfirmation
    },
    props:{
      id: String,
      satellite:{
        typeof:Boolean,
        default: false
      },
      workLocal: {
        typeof: Object,
        default: null
      },
      methereology: {
        typeof: Array,
        default: null
      },
      work_object:{
        typeof: Object,
        default: null
      }
    },
    data(){
      return{
        openConfirmationDialog: false,
        gmapReloaded: 0,
        toRemove: false,
        modalSuccess:{
            show: false,
            message: 'Alterações gravadas com sucesso'
        },
        zoom: 15,
        center:{
          lat: 0,
          lng: 0
        },
        loading: true,
        markers: [],
        saveMarker: false,
        markerToRemove: null,
        removeMarkerButton: false,
        informationModal:{
          message: null
        },
        fields:{
          street_number: null,
          administrative_area_level_1: null,
          administrative_area_level_2: null,
          country: null,
          locality: null,
          route: null
        }
      }
    },
    mounted(){
      if(this.workLocal != null){
        this.fields = this.workLocal
        this.center = {
            lat: this.fields.lat,
            lng: this.fields.lng,
          };
        this.markers.push({position: {lat: this.fields.lat, lng: this.fields.lng}, radius: 55})
      }else
        this.geolocate();

        this.loading = false

    },
    methods:{    
      openConfirmation(){
        if(this.markers.length > 0)
          this.codeLatLng(this.markers[0].position.lat, this.markers[0].position.lng)
        this.openConfirmationDialog = true;
      },
      centerMap(){
        this.gmapReloaded++
      },
      updateCoordinates(location){
        this.markers[0].position.lat = location.lat()
        this.markers[0].position.lng = location.lng()
      },
      async saveMapMarkers(){
        let data = {...this.fields}

        if(this.markers.length != 0){
          await this.codeLatLng(this.markers[0].position.lat, this.markers[0].position.lng)
          data = {...this.fields}
          data.lat = this.markers[0].position.lat
          data.lng = this.markers[0].position.lng
        }else{
          data = {
            work_id: null
          }
        }

        data.work_id=this.id;
        Work.setLocal(data).then((resp) => {

          this.success = true;          
          this.modalSuccess.show = true
          this.openConfirmationDialog = false

          if(this.markers.length == 0){
            
            this.fields = {
              street_number: null,
              administrative_area_level_1: null,
              administrative_area_level_2: null,
              country: null,
              locality: null,
              route: null
            }
          }

          }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      },
      setPlace(newPlace){
        this.center.lat = newPlace.geometry.location.lat()
        this.center.lng = newPlace.geometry.location.lng()
        
        this.$refs['gmap'].fitBounds(newPlace.geometry.viewport)
      },
      usePlace(b){
        console.log(b)
      },
      cleanMarkers(){
        this.markers = this.markers.splice()
        this.toRemove = true
      },
      drawCircle(){
        this.saveMarker = true
        this.center = {
          lat: this.$refs['gmap'].$mapObject.getCenter().lat(),
          lng: this.$refs['gmap'].$mapObject.getCenter().lng(),
        };
      
        this.drawable(this.center.lat, this.center.lng)
      },
      drawable(lat, lng){
        this.cleanMarkers()
        const marker = {
          lat: lat,
          lng: lng
        };
        this.markers.push({ position: marker, radius: 55 });
      },
      showRemoveMarker(m){
        this.markerToRemove = m
        this.removeMarkerButton = true
      },
      geolocate(){
        navigator.geolocation.getCurrentPosition(position => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });
      },
      async codeLatLng(lat, lng) {
        var latlng = new google.maps.LatLng(lat, lng);
        let geocoder = new google.maps.Geocoder();
        let parent = this
        let zone_discovered = false
        await geocoder.geocode({'latLng': latlng}, function(results, status) {
          
          if(status == google.maps.GeocoderStatus.OK) {
              for(const component of results[0].address_components){
                const componentType = component.types[0];
                  switch (componentType) {
                    case "locality":
                      parent.fields.locality = component.long_name;
                      break;
                    case "administrative_area_level_1":
                      parent.fields.administrative_area_level_1 = component.long_name
                      break;
                    case "administrative_area_level_2":
                      parent.fields.administrative_area_level_2 = component.long_name
                      break;
                    case "route":
                      parent.fields.route = component.long_name
                      break;
                    case "street_number":
                      parent.fields.street_number = component.long_name
                      break;
                    case "country":
                      parent.fields.country = component.long_name
                      break;
                      
                    default:
                  }
                  
              }           
          }

        });
      },
    },
    computed:{
      getOptions(){
        let options = {
              clickableIcons: false,
              mapTypeControl: false, 
              streetViewControl: false,
              fullscreenControl: false,
              styles: [
                {
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#f5f5f5"
                    }
                  ]
                },
                {
                  "elementType": "labels.icon",
                  "stylers": [
                    {
                      "visibility": "off"
                    }
                  ]
                },
                {
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#616161"
                    }
                  ]
                },
                {
                  "elementType": "labels.text.stroke",
                  "stylers": [
                    {
                      "color": "#f5f5f5"
                    }
                  ]
                },
                {
                  "featureType": "administrative.land_parcel",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#bdbdbd"
                    }
                  ]
                },
                {
                  "featureType": "poi",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#eeeeee"
                    }
                  ]
                },
                {
                  "featureType": "poi",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#757575"
                    }
                  ]
                },
                {
                  "featureType": "poi.park",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#e5e5e5"
                    }
                  ]
                },
                {
                  "featureType": "poi.park",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#9e9e9e"
                    }
                  ]
                },
                {
                  "featureType": "road",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#ffffff"
                    }
                  ]
                },
                {
                  "featureType": "road.arterial",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#757575"
                    }
                  ]
                },
                {
                  "featureType": "road.highway",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#dadada"
                    }
                  ]
                },
                {
                  "featureType": "road.highway",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#616161"
                    }
                  ]
                },
                {
                  "featureType": "road.local",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#9e9e9e"
                    }
                  ]
                },
                {
                  "featureType": "transit.line",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#e5e5e5"
                    }
                  ]
                },
                {
                  "featureType": "transit.station",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#eeeeee"
                    }
                  ]
                },
                {
                  "featureType": "water",
                  "elementType": "geometry",
                  "stylers": [
                    {
                      "color": "#c9c9c9"
                    }
                  ]
                },
                {
                  "featureType": "water",
                  "elementType": "labels.text.fill",
                  "stylers": [
                    {
                      "color": "#9e9e9e"
                    }
                  ]
                }
              ]
            }


        return options
      }
    }
}
</script>