<template>
    <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''" v-if="loading == false">
      <v-card-title class="primary white--text">
        Mão de Obra
      </v-card-title>
      <validation-observer ref="form" v-slot="{ invalid }">
          <v-form @submit.prevent="submitWorkers" :disabled="loading || diary_status == 'FINISHED_APPROVAL' && locked_edition">
              <v-card-text>
                  <v-simple-table>
                      <template v-slot:default>
                          <thead>
                              <tr>
                                  <th class="text-left">
                                    Colaborador
                                  </th>
                                  <th class="text-left">
                                    Horas Trabalhadas
                                  </th>
                              </tr>
                          </thead>
                          <tbody :class="$root.$vuetify.theme.isDark ? 'secondary' :''">
                              <tr
                                  v-for="worker in divisionFields"
                                  v-bind:key="worker.worker_id"
                              >
                                  <td>
                                      {{ getWorkerName(worker) }}
                                  </td>
                                  <td>
                                      <!--
                                          No modelo temos de ir buscar as horas trabalhadas, para aquela linha.
                                          Procurar no array worker_work_units qual a linha que corresponde
                                          ao ID do user e ao ID da unidade
                                      -->
                                      <validation-provider v-slot="{ errors }" vid="hours" name="Horas" rules="required|between_hour">
                                      <v-text-field
                                          v-model="worker.worked_hours"
                                          :label="'Horas *'"
                                          :error-messages="errors"
                                          @keypress="onlyNumbers($event)"
                                      ></v-text-field>
                                      </validation-provider>
                                  </td>
                              </tr>
                          </tbody>
                      </template>
                  </v-simple-table>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                  <v-btn
                      dark
                      v-if="fields.approvals == undefined ? true : fields.approvals.topics.unidades_obra == false"
                      small
                      color="secondary"
                      @click="divideAutomaticHours"
                      >
                      <v-icon small>mdi-refresh-auto</v-icon>Dividir horas
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn v-if="fields.approvals == undefined ? true : fields.approvals.topics.unidades_obra == false" :disabled="invalid" depressed :class="$root.$vuetify.theme.isDark ? 'white primary--text' : 'primary'" type="submit">
                      Gravar
                  </v-btn>
              </v-card-actions>
          </v-form>
      </validation-observer>
      <SuccessSnackBar v-model="modalSuccess.show" :message="modalSuccess.message" />
      <ErrorSnackbar v-model="error.show" :message="error.message"/>
      </v-card>
</template>
<script>
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';

export default{
  name: "WorkHandDivision",
  components:{
      ErrorSnackbar,
      SuccessSnackBar,
  },
  props:{
      diary_status: String,
      locked_edition: Boolean,
      work_unit_id: String,
      fields: Object,
      auto_divider: Boolean,
      workers: Array
  },
  data(){
      return{
          divisionFields: [],
          dialog: false,
          loading: true,
          modalSuccess:{
              show: false,
              message: ''
          },
          error:{
              show: false,
              message: ''
          }
      }
  },
  mounted(){
      this.loading = true;

      let a = false

      if(a)return
      this.fillBaseData();


      if(this.auto_divider)
          this.divideAutomaticHours()

      this.loading = false
  },
  methods:{
    onlyNumbers(event) {
      const value = event.target.value;
      if (value.includes('.') || value.includes(',')) {
        const decimalPlaces = value.split('.')[1] || value.split(',')[1];
        if (decimalPlaces && decimalPlaces.length >= 2) {
          event.preventDefault();
        }
      }
      if (event.key === ',' || event.key === '.') {
        if (value.includes('.') || value.includes(',')) {
          event.preventDefault();
        }
      } else if (isNaN(event.key)) {
        event.preventDefault();
      }
    },
    getWorkerName(worker){
        let nome = 'N/D'
        this.workers.forEach(element => {
            if(element.id == worker.worker_id)
                nome = element.name
        });
        return nome
    },
    fillBaseData(){
        this.fields.workers.forEach(element => {
            let worker_object = null;

            this.fields.worker_work_units.forEach(w => {
                if(element.id == w.worker_id && w.work_unit_id == this.work_unit_id)
                    worker_object = w
            })

            if(worker_object == null){
                this.divisionFields.push({
                    id: 'new_' + element.id,
                    worker_id: element.id,
                    worker_name: element.name,
                    worked_hours: null,
                    work_unit_id: this.work_unit_id
                })
            }else{
                this.divisionFields.push({
                    id: worker_object.work_unit_id,
                    worker_id: element.id,
                    worker_name: element.name,
                    worked_hours: worker_object.worked_hours,
                    work_unit_id: this.work_unit_id
                })
            }
        });
    },
    submitWorkers(){
        this.$emit('submit', this.divisionFields)
    },
    divideAutomaticHours(){
        this.divisionFields.forEach(element => {
            this.fields.workers.forEach(worker => {
                if(element.worker_id == worker.id){
                    let startDate = new Date('2020', '05', '05',
                        worker.started_hour,
                        worker.started_minute,
                        '00'
                    );

                    let endDate = new Date('2020', '05', '05',
                        worker.finished_hour,
                        worker.finished_minute,
                        '00'
                    );

                    let difference = endDate.getTime() - startDate.getTime();
                    difference = difference / 1000;
                    let hourDifference = Math.floor(difference / 3600);

                    if(hourDifference < 0){
                        hourDifference = 24 + hourDifference;
                    }

                    this.workers.forEach(lunch_worker => {
                    if(lunch_worker.id == worker.id){
                        hourDifference = hourDifference - lunch_worker.lunch_hour
                    }
                    });

                    /*
                    // Retirar hora de almoço
                    if(element.code == 25)
                    hourDifference = hourDifference - 1.5
                    else hourDifference = hourDifference - 1*/


                    let divided_hours = parseFloat(hourDifference / this.fields.work_units.length).toFixed(2)

                    element.worked_hours = divided_hours

                }
            });
        });
    }
  }
}
</script>