<template>
    <v-dialog
        v-model="dialog" width="50%">    
        <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="error"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Rejeitar
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="primary white--text">
            {{title}}
        </v-card-title>
        <v-card-text class="py-8">
            <ValidationProvider :vid="vid" :name="$attrs.label"  v-slot="{errors}">
                <v-textarea
                    outlined
                    :label="label"
                    name="input-7-4"
                    v-model="model_text"
                    :error-messages="errors"
                    :readonly="locked"
                ></v-textarea>
            </ValidationProvider>
        </v-card-text>
        <v-card-actions>
            <v-btn
                color="primary"
                depressed
                @click="enviarRejeicao"
            >
                Submeter
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="error"
                depressed
                @click="dialog = false"
                >
                Cancelar
            </v-btn>
        </v-card-actions>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
          Rejeição inserida com sucesso
        </DialogSuccess>
      </v-card>
    </v-dialog>
</template>
<script>
import Diary from "@/api/Diary.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default {
   name: 'Reject',
    components: {
        DialogSuccess
    },
   props:{
       vid: String,
       model:String,
       title:String,
       label:String,
       locked:{
        type: Boolean,
        default:false
       }
   },
   data(){
    return{
        model_text: '',
        dialog: false,
        success: false
    }
   },
   methods:{
    onSuccess(){
        this.model_text = ''
        this.success = false
        this.dialog = false
    },
    enviarRejeicao(){
        let data = {
            reason: this.model_text
        }
        Diary.reject(this.$route.params.id, data)
            .then(() => {
            this.success = true
        })
    }
   }
}
</script>

<style lang="scss" scoped>
</style>