<template>
    <v-card>
        <validation-observer ref="form" v-slot="{ invalid }">
            <fieldset>
              <v-form @submit.prevent="editCompany == null ? addCompany() : updateCompany()" :disabled="loading">
                <v-card-title>
                  <h2 class="secondary--text subtitle-1">
                    {{ editCompany == null ? 'Adicionar fornecedor' : 'Atualizar fornecedor' }}
                  </h2>
                </v-card-title>
                <v-card-text>
                  <DialogSuccess :opened="success" @on-ok="success = false" >
                    Empresa adicionada com sucesso
                  </DialogSuccess>
                  <v-row>
                    <v-col cols="12">
                        <validation-provider v-slot="{ errors }" vid="name" name="Name" rules="required">
                            <v-text-field
                                v-model="company.name"
                                prepend-inner-icon="mdi-account-details"
                                label="Nome *"
                                clearable
                                :error-messages="errors"
                            ></v-text-field>
                    </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                        <validation-provider v-slot="{ errors }" vid="nif" name="Nif" rules="required">
                            <v-text-field
                                v-model="company.nif"
                                prepend-inner-icon="mdi-ticket-confirmation"
                                label="NIF *"
                                clearable
                                :error-messages="errors"
                            ></v-text-field>
                    </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="company.email"
                            prepend-inner-icon="mdi-at"
                            label="Email"
                            clearable
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="company.phone"
                            prepend-inner-icon="mdi-phone"
                            label="Contato"
                            clearable
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col><small>Por defeito a empresa vai aparecer como ativa. Se pretender eliminar a empresa, poderá faze-lo na lista quando fechar esta janela</small></v-col>                    
                  </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        depressed
                        @click="$emit('close')"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn :disabled="invalid" depressed color="primary" type="submit">
                    Gravar
                    </v-btn> 
                </v-card-actions>
                </v-form>
            </fieldset>            
        </validation-observer>
    </v-card>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Company from "@/api/Company.js";

export default {
    components:{
        DialogSuccess
    },
    props:{
        editCompany: Object
    },
    mounted(){
        if(this.editCompany != null){
            this.company = this.editCompany
            this.company.name = this.editCompany.name            
        }
    },
    data(){
        return{
            loading: false,
            success:false,
            company:{
                name: '',
                deleted_company: false
            }
        }
    },
    methods:{
        updateCompany(){
            this.loading = true
            Company.update(this.company, this.editCompany.id)
                .then(() => {
                    this.success = true;

                    this.loading = false;

                    this.$emit('close-update')
                });
        },
        addCompany(){
            this.loading = true
            
            Company['create'](this.company).then(() => {

                this.success = true;

                this.loading = false;

                this.$emit('close-update')

                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }
    }
}
</script>