import BaseApi from "./BaseApi";

export default class Performance extends BaseApi{
    construct() {}

    static async getDiaries(id, params) {
        let url = Performance.getApiUrl() + id;

        return Performance.getAxiosInstance().get(url, {params: params});
    }

    static async getPerformance(id) {
        let url = Performance.getApiUrl() + 'work_performance/' + id;

        return Performance.getAxiosInstance().get(url);
    }

    static async exportWorkPerformance(work_id, params) {
        let url = Performance.getApiUrl() + 'export_work_performance/' + work_id;

        return Performance.getAxiosInstance().get(url, {params: params});
    }

    static async exportWorkUnitPerformance(work_id, params) {
        let url = Performance.getApiUrl() + 'unit_performance/' + work_id;

        return Performance.getAxiosInstance().get(url, {params: params});
    }

    static async delete(id) {
        return await Performance.getAxiosInstance().delete(Performance.getApiUrl() + 'export_work_performance/' + id);
    }


    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/performance/";
    }

}