<template>
  <div>
    <v-card 
      v-bind="$attrs"
      v-on="$listeners"
     :loading="loading"
      elevation="2"
    >
      <slot></slot>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'uiCard',
    props: {
      loading: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>
<!--
<style lang="scss" scoped>
::v-deep .v-card__title {
  color: #673ab7 !important
}
</style>
-->