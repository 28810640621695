import BaseApi from "./BaseApi";

export default class Models extends BaseApi{
    construct() {}
    
    static async find(id) {
        return await Models.getAxiosInstance().get(Models.getApiUrl() + id);
    }

    static async create(params) {
        return await Models.getAxiosInstance().post(Models.getApiUrl(),params);
    }

    static async delete(id) {
        return await Models.getAxiosInstance().delete(Models.getApiUrl() + id);
    }

    static async search(params) {
        return await Models.getAxiosInstance().get(Models.getApiUrl(),{params: params});
    }

    static async export(params) {
        let url = Models.getApiUrl() + "export";

        return Models.getAxiosInstance().post(url, params);
    }

    static async update(params, id) {
        return await Models.getAxiosInstance().post(Models.getApiUrl() + id,params);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/models/";
    }
}