<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-progress-linear v-if="loading"
          indeterminate
    ></v-progress-linear>
    <v-toolbar
          dark
          color="primary"
    >
        <v-toolbar-title>Modelo 009 EQ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
        <v-btn
            icon
            dark
            @click="$emit('close')"
        >
        <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-toolbar-items>
    </v-toolbar>
    <v-card >
        <v-card-text v-if="!loading">
            <v-row align="center">
                <v-col cols="12" 
                        v-for="component in components" 
                        :key="component.key" 
                        :md="component.cols"
                        :class="component.class"
                >
                    <v-text-field v-if="component.type == 'textfield'"
                        :label="component.label"
                        :placeholder="component.placeholder"
                        v-model="component.value"
                    ></v-text-field>

                    <label v-if="component.type == 'label'">
                        {{component.label}}
                    </label>

                    <label v-if="component.type == 'label_html'" v-html="component.label">
                       
                    </label>

                    <v-checkbox
                        v-if="component.type == 'checkbox'"
                        v-model="component.value"
                        :label="component.label"
                    ></v-checkbox>

                    <v-btn
                        style="display: block; margin: auto;"
                        v-if="component.type == 'button_check'"
                        v-model="component.value"
                        @click="component.value ? component.value = false : component.value = true"
                        :class="component.value ? 'primary' : ''"
                        :label="component.label"
                    >{{component.label}}</v-btn>

                    <v-textarea v-if="component.type == 'textarea'"
                        v-model="component.value"
                        outlined
                    >
                    </v-textarea>

                    <v-menu
                        v-if="component.type == 'date'"
                        v-model="component.menu_model"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="component.value"
                                :label="component.label"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="component.value"
                            @input="component.menu_model = false"
                        ></v-date-picker>
                    </v-menu>

                    <v-menu
                        v-if="component.type == 'time'"
                        ref="menu"
                        v-model="component.menu_model"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="component.value"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="component.value"
                            :label="component.label"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-time-picker
                        v-if="component.menu_model"
                        v-model="component.value"
                        full-width
                        @click:minute="$refs.menu.save(component.value)"
                        ></v-time-picker>
                    </v-menu>

                    <v-divider v-if="component.type == 'divider'"></v-divider>

                </v-col>
            </v-row>
        </v-card-text>
        <v-speed-dial
            v-model="fab"
            class="mb-8 mr-4"
            style="position: fixed; bottom: 0; right: 0;"
            :open-on-hover="hover"
            :transition="transition"
            >
            <template v-slot:activator>
                <v-btn
                v-model="fab"
                color="warning"
                dark
                fab
                >
                <v-icon v-if="fab">
                    mdi-close
                </v-icon>
                <v-icon v-else>
                    mdi-cog-outline
                </v-icon>
                </v-btn>
            </template>
            <Tooltip
                class="my-4 mr-4"
                color="primary"
                text="Gravar"
                top
            >
                <v-btn
                    fab
                    dark
                    small
                    color="primary"
                    @click="submit()"
                >
                    <v-icon>mdi-content-save-all</v-icon>
                </v-btn>
            </Tooltip>
            <Tooltip
                class="my-4 mr-4"
                color="error"
                text="Exportar PDF"
                top
            >
                <v-btn
                    fab
                    dark
                    small
                    @click="exportModel('PDF')"
                    color="error"
                >
                    <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
            </Tooltip>
            <Tooltip
                v-if="false"
                class="my-4 mr-4"
                color="success"
                text="Exportar Excel"
                top
            >
                <v-btn
                    fab
                    dark
                    small
                    color="success"
                    @click="exportModel('Excel')"
                >
                    <v-icon>mdi-file-excel-box</v-icon>
                </v-btn>
            </Tooltip>
            </v-speed-dial>
        <DialogSuccess :opened="success" @on-ok="$emit('close')" >
            Modelo gravado com sucesso
        </DialogSuccess>
    </v-card>
    </v-dialog>
</template>
<script>
import Models from '@/api/Models.js'
import Export from '@/api/Export.js'
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Tooltip from '@/components/ui/Tooltip.vue';


export default{
    name: "Mod009EQ",
    components:{
        DialogSuccess,
        Tooltip
    },
    props:{
        machine:{
            typeof: Object,
            default: null,
        },
        model_id: {
            typeof: String,
            default: null
        }
    },
    data(){
        return{
            fab: false,
            hover: false,
            transition: 'slide-y-reverse-transition',
            loading: true,
            dialog: true,
            method: 'create',
            components: [],
            success: false,
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        exportModel(type){
            let data = {
                type: type,
                model: 'Mod009EQ',
                model_id: this.model_id
            }

            Models.export(data).then(async (resp) => {
                let caminho = data.type == 'PDF' ? resp.data.object.url : resp.data.object
                let remover = data.type == 'PDF' ? resp.data.object.path : resp.data.object
                window.open(caminho, '_blank')
                Export.delete(remover)
                .then(() => {
                    
                });
            });
        },
        submit(){
            let data = {
                machine_id: this.$route.params.id,
                model_name: 'Mod009EQ',
                metadata: this.components
            }
            Models[this.method](data, this.model_id).then(async () => {
                this.success = true
            });
        },
        async fillBaseData(){
            if(this.model_id != null){
                this.method = 'update'
                await Models.find(this.model_id)
                .then((resp) => {
                    this.components = JSON.parse(resp.data.metadata)
                    
                });
            }
            else this.constructComponent()

            this.loading = false
        },
        constructComponent(){
            this.components = [
            {
                type: "textfield",
                key: "designacao",
                placeholder: "",
                label: "Designação",
                value: this.machine.name,
                cols: 8,
                show_both: true,
                header: 'normal-60',
                table_body: 'header',
                class: ""
            },
            {
                type: "textfield",
                key: "numero",
                placeholder: "",
                label: "Número",
                value: this.machine.code,
                cols: 2,
                show_both: true,
                header: 'normal-60',
                table_body: 'header',
                class: ""
            },
            {
                type: "textfield",
                key: "ano_fabrico",
                placeholder: "",
                label: "Ano fabrico",
                value: this.machine.manufacture_year,
                cols: 2,
                show_both: true,
                header: 'normal-60',
                table_body: 'header',
                class: ""
            },
            {
                type: "textfield",
                key: "marca",
                placeholder: "",
                label: "Marca",
                value: "",
                cols: 8,
                show_both: true,
                header: 'normal-60',
                table_body: 'header',
                class: ""
            },
            {
                type: "textfield",
                key: "modelo",
                placeholder: "",
                label: "Modelo",
                value: "",
                cols: 2,
                show_both: true,
                header: 'normal-60',
                table_body: 'header',
                class: ""
            },
            {
                type: "textfield",
                key: "n_serie",
                placeholder: "",
                label: "Nº série",
                value: this.machine.serial_number,
                cols: 2,
                show_both: true,
                header: 'normal-60',
                table_body: 'header',
                class: ""
            },
            {
                type: "textfield",
                key: "local_insp",
                placeholder: "",
                label: "Local de Inspeção",
                value: "",
                cols: 6,
                local: true,
                show_both: true,
                header: 'normal-40',
                table_body: 'local',
                class: ""
            },
            {
                type: "checkbox",
                key: "checkbox_initial",
                placeholder: "",
                label: "Inicial",
                value: "",
                cols: 2,
                show_both: true,
                header: 'normal-20',
                table_body: 'local',
                class: ""
            },
            {
                type: "checkbox",
                key: "checkbox_periodica",
                placeholder: "",
                label: "Periódica",
                value: "",
                cols: 2,
                show_both: true,
                header: 'normal-20',
                table_body: 'local',
                class: ""
            },
            {
                type: "checkbox",
                key: "checkbox_extra",
                placeholder: "",
                label: "Extraordinária",
                value: "",
                cols: 2,
                show_both: true,
                header: 'normal-20',
                table_body: 'local',
                class: ""
            },

            {
                type: "label",
                key: "id_equipamento",
                placeholder: "",
                label: "Identificação do equipamento",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_equipamento_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block d-none d-lg-block orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_equipamento_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block d-none d-lg-block orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_equipamento_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block d-none d-lg-block orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_equipamento_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },

            {
                type: "label",
                key: "id_equipamento_text_1",
                placeholder: "",
                label: "Presença de placa de identificação / Marcação CE",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_equipamento_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_equipamento_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_equipamento_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_equipamento_text_field",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "label",
                key: "id_sistemas_comando",
                placeholder: "",
                label: "Sistemas de comando",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sistemas_comando_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_sistemas_comando_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_sistemas_comando_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_sistemas_comando_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },

            {
                type: "label",
                key: "id_sistemas_comando_text_1",
                placeholder: "",
                label: "Identificação dos comandos / estado e funcionalidade",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sistemas_comando_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sistemas_comando_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sistemas_comando_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_sistemas_comando_text_field",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_1",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            {
                type: "label",
                key: "id_sistemas_comando_text_2",
                placeholder: "",
                label: "Paragem de emergência, corte geral",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sistemas_comando_checkbox_4",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sistemas_comando_checkbox_5",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sistemas_comando_checkbox_6",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_sistemas_comando_text_field_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "label",
                key: "id_estabilidade_rutura",
                placeholder: "",
                label: "Estabilidade e rutura",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_estabilidade_rutura_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_estabilidade_rutura_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_estabilidade_rutura_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_estabilidade_rutura_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            
            
            {
                type: "label",
                key: "id_estabilidade_rutura_text_1",
                placeholder: "",
                label: "Esturutra em geral",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_estabilidade_rutura_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_estabilidade_rutura_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_estabilidade_rutura_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_estabilidade_rutura_obs_textfield_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_2",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            {
                type: "label",
                key: "id_estabilidade_rutura_text_2",
                placeholder: "",
                label: "Proteções contra projeção de objetos",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_estabilidade_rutura_checkbox_4",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_estabilidade_rutura_checkbox_5",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_estabilidade_rutura_checkbox_6",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_estabilidade_rutura_obs_textfield_2",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "label",
                key: "id_risco_mecanico",
                placeholder: "",
                label: "Risco de contacto mecânico",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_risco_mecanico_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_risco_mecanico_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_risco_mecanico_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_risco_mecanico_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },

            {
                type: "label",
                key: "id_risco_mecanico_text_1",
                placeholder: "",
                label: "Proteção dos órgãos moveis / Tampas, portas de acesso",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico__checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_risco_mecanico_obs_textfield_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "label",
                key: "id_eletric_risk_fire",
                placeholder: "",
                label: "Riscos elétricos, incêndio e explosão",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_eletric_risk_fire_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_eletric_risk_fire_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_eletric_risk_fire_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_eletric_risk_fire_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },

            {
                type: "label",
                key: "id_eletric_risk_fire_textfield_1",
                placeholder: "",
                label: "Isolamento cablagens, invólucros, quadros elétricos, tomadas",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_eletric_risk_fire_obs_textfield_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_3",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            {
                type: "label",
                key: "id_eletric_risk_fire_textfield_2",
                placeholder: "",
                label: "Ligação à terra de todas as massas metálicas / Borne Terra",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_4",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_5",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_6",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_eletric_risk_fire_obs_textfield_2",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_4",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            {
                type: "label",
                key: "id_eletric_risk_fire_textfield_3",
                placeholder: "",
                label: "Ligações elétricas, bornes, tomadas, secções cablagens",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_7",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_8",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_9",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_eletric_risk_fire_obs_textfield_3",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_5",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            {
                type: "label",
                key: "id_eletric_risk_fire_textfield_4",
                placeholder: "",
                label: "Ensaios elétricos – continuidade, terra, isolamento, tensão",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_10",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_11",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_12",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_eletric_risk_fire_obs_textfield_4",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            

            {
                type: "label",
                key: "id_sinalizacao_seguranca",
                placeholder: "",
                label: "Sinalização de segurança",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sinalizacao_seguranca_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sinalizacao_seguranca_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sinalizacao_seguranca_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sinalizacao_seguranca_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },

            {
                type: "label",
                key: "id_sinalizacao_seguranca_textfield_1",
                placeholder: "",
                label: "Sinalização de reservatórios de água, combustível, óleos, etc.",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_sinalizacao_seguranca_obs_textfield_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_7",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            {
                type: "label",
                key: "id_sinalizacao_seguranca_textfield_2",
                placeholder: "",
                label: "Sinalização de outros riscos (quadro elétrico, perigo de corte, esmagamento, temperatura, etc.)",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_4",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_5",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_6",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_sinalizacao_seguranca_obs_textfield_2",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_8",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            {
                type: "label",
                key: "id_sinalizacao_seguranca_textfield_3",
                placeholder: "",
                label: "Sinalização de bateria",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_7",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_8",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_9",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_sinalizacao_seguranca_obs_textfield_3",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_9",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            {
                type: "label",
                key: "id_sinalizacao_seguranca_textfield_4",
                placeholder: "",
                label: "Sinalização dos pontos de elevação / amarração / fixação",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_10",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_11",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_12",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_sinalizacao_seguranca_obs_textfield_4",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "label",
                key: "id_documentacao",
                placeholder: "",
                label: "Documentação",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_documentacao_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_documentacao_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_documentacao_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_documentacao_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },

            {
                type: "label",
                key: "id_documentacao_textfield_1",
                placeholder: "",
                label: "Manual em Português",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_documentacao_obs_textfield_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_12",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_documentacao_textfield_2",
                placeholder: "",
                label: "Planos de manutenção",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_4",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_5",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_6",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_documentacao_obs_textfield_2",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_13",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            
            {
                type: "label",
                key: "id_documentacao_textfield_3",
                placeholder: "",
                label: "Registo da última manutenção",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_7",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_8",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_9",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_documentacao_obs_textfield_3",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_14",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            {
                type: "label",
                key: "id_documentacao_textfield_5",
                placeholder: "",
                label: "Marcação e declaração CE em português (após 1995)",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_13",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_14",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_15",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_documentacao_obs_textfield_5",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_15",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_documentacao_textfield_6",
                placeholder: "",
                label: "Esquemas elétricos",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_16",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_17",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_18",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_documentacao_obs_textfield_6",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_16",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            {
                type: "label",
                key: "id_documentacao_textfield_7",
                placeholder: "",
                label: "Esquemas hidráulicos",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_19",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_20",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_21",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_documentacao_obs_textfield_7",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "label",
                key: "id_observations",
                placeholder: "",
                label: "Observações",
                value: "",
                cols: 12,
                show_both: false,
                header: 'header-100',
                table_body: 'observations',
                class: "font-weight-bold"
            },
            
            {
                type: "textarea",
                key: "id_obs_textarea",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                show_both: false,
                header: 'header-100',
                table_body: 'observations',
                class: ""
            },

            {
                type: "label",
                key: "id_resultado",
                placeholder: "",
                label: "Resultado",
                value: "",
                cols: 12,
                show_both: false,
                header: 'header-100',
                table_body: 'result',
                class: "orange white--text font-weight-bold text-center"
            },

            {
                type: "label",
                key: "id_equipamento_pergunta",
                placeholder: "",
                label: "O equipamento pode ser utilizado pelos trabalhadores?",
                value: "",
                cols: 8,
                show_both: false,
                header: 'normal-100',
                table_body: 'result-2',
                class: "text-center"
            },

            {
                type: "button_check",
                key: "id_equipamento_pergunta_checkbox_1",
                placeholder: "",
                label: "Sim",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-20',
                table_body: 'result-2',
                class: "text-center"
            },

            {
                type: "button_check",
                key: "id_equipamento_pergunta_checkbox_2",
                placeholder: "",
                label: "Não",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-20',
                table_body: 'result-2',
                class: "text-center"
            },
            {
                type: "date",
                key: "id_data_limite_correcao",
                placeholder: "",
                label: "Data limite para correção",
                value: "",
                cols: 4,
                class: "text-center",
                show_both: true,
                header: 'normal-30',
                table_body: 'dates',
                menu_model: false
            },

            {
                type: "date",
                key: "id_data_implementacao",
                placeholder: "",
                label: "Data de implementação da correção",
                value: "",
                cols: 4,
                class: "text-center",
                show_both: true,
                header: 'normal-30',
                table_body: 'dates',
                menu_model: false
            },

            {
                type: "textfield",
                key: "id_rubrica",
                placeholder: "",
                label: "Rubrica (Quem efetuou a correção)",
                value: "",
                cols: 4,
                show_both: true,
                header: 'normal-30',
                table_body: 'dates',
                class: "text-center"
            },

            {
                type: "label_html",
                key: "id_nota",
                placeholder: "",
                label: "<strong>NOTA I:</strong> Esta inspeção foi efetuada no âmbito do DL n.º 50/2005, de 25 de fevereiro – Capítulo II e normas aplicáveis. ",
                value: "",
                cols: 12,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "text-center"
            },

            {
                type: "label",
                key: "id_proxima_inspecao",
                placeholder: "",
                label: "Próxima inspeção",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "orange white--text font-weight-bold text-center"
            },

            {
                type: "date",
                key: "id_data_proxima_inspecao",
                placeholder: "",
                label: "Data próxima inspeção",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "text-center",
                menu_model: false
            },

            {
                type: "label",
                key: "id_operador",
                placeholder: "",
                label: "Operador",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "d-none d-lg-block mt-4 orange white--text font-weight-bold text-center"
            },

            {
                type: "label",
                key: "id_inspetor",
                placeholder: "",
                label: "Inspetor",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "d-none d-lg-block mt-4 orange white--text font-weight-bold text-center"
            },
            {
                type: "textfield",
                key: "id_operador_nome",
                placeholder: "",
                label: "Nome operador",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: ""
            },
            {
                type: "textfield",
                key: "id_inspetor_nome",
                placeholder: "",
                label: "Nome inspetor",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: ""
            },
            {
                type: "textfield",
                key: "id_operador_assinatura",
                placeholder: "",
                label: "Assinatura operador",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: ""
            },
            {
                type: "textfield",
                key: "id_inspetor_assinatura",
                placeholder: "",
                label: "Assinatura inspetor",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: ""
            },

            
            {
                type: "label",
                key: "id_data",
                placeholder: "",
                label: "Data",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "d-none d-lg-block mt-4 orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_hora",
                placeholder: "",
                label: "Hora",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "d-none d-lg-block mt-4 orange white--text font-weight-bold text-center"
            },

            {
                type: "date",
                key: "id_data_assinatura",
                placeholder: "",
                label: "Data",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "text-center",
                menu_model: false
            },

            {
                type: "time",
                key: "id_hora_assinatura",
                placeholder: "",
                label: "Hora",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "text-center",
                menu_model: false
            },
            {
                type: "label_html",
                key: "id_nota_2",
                placeholder: "",
                label: "<strong>NOTA II:</strong> Fica excluída da emissão deste relatório qualquer responsabilidade de mau uso e/ou não cumprimento das normas de utilização do equipamento constantes do respetivo manual de utilização / operador.",
                value: "",
                cols: 12,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "text-center"
            },
        ];
        }
    }
}
</script>