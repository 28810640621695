<template>
    <v-container fluid>
        <v-progress-linear 
            v-if="loading"
            class="mt-2"
            indeterminate
        ></v-progress-linear>
        <v-card v-else class="elevation-0 ma-0 pa-0">
            <v-row v-if="encarregado != null && $root.session.hasRole(['encarregado'])">
                <v-col cols="12" md="3">
                    <Card class="mt-4 mb-4">
                    <v-card-title>Partes diárias mês</v-card-title>
                    <v-card-text>
                        <h3>{{encarregado.n_partes_diarias}}</h3>
                    </v-card-text>
                    </Card>
                </v-col>
                <v-col cols="12" md="3">
                    <Card class="mt-4 mb-4">
                    <v-card-title>Partes diárias com atraso</v-card-title>
                    <v-card-text>
                        <h3>{{encarregado.atrasadas}}</h3>
                    </v-card-text>
                    </Card>
                </v-col>
                <v-col cols="12" md="4">
                    <Card class="mt-4 mb-4">
                    <v-card-title>Partes diárias entregues atempadamente</v-card-title>
                    <v-card-text>
                        <h3>{{encarregado.atempada}}%</h3>
                    </v-card-text>
                    </Card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-speed-dial
                        absolute
                        v-model="speedDial"
                        top
                        right
                        direction="bottom"
                        :open-on-hover="true"
                        >
                        <template v-slot:activator>
                            <v-tooltip right color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                v-bind="attrs" v-on="on"
                                v-model="speedDial"
                                fab
                                dark
                                small
                                color="primary"
                                large
                                @click="openDiariesFilter = true"
                                >
                                <v-icon>mdi-filter</v-icon>
                                </v-btn>
                            </template>
                            <span>Pesquisar</span>
                            </v-tooltip>
                        </template>
                    </v-speed-dial>
                </v-col>
                <v-col cols="12" md="2">
                    <v-autocomplete
                        outlined dense
                        v-model="filterDiaries.year"
                        label="Ano"
                        :items="getYears"
                    />
                </v-col>
                <v-col cols="12" md="auto">
                    <v-btn
                        fab
                        class="success"
                        small
                        @click="diariesPerMonthSearch"
                    >
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <div id="chart">
                        <VueApexCharts type="bar" height="350" :options="diariesPerMonthOptions" :series="diariesPerMonthSeries"></VueApexCharts>
                    </div>
                </v-col>
                <v-col cols="12" v-if="false">                    
                    <RendimentoMO  />
                </v-col>
                <v-col cols="12">       
                    <RendimentoUO  />
                </v-col>
            </v-row>
            <v-navigation-drawer
                fixed
                right
                v-model="openDiariesFilter"
                hide-overlay
                width="520"
                class="pt-6"
            >
                <v-list>
                    <v-list-item>
                        <v-btn fab small color="secondary" @click="openDiariesFilter = false">
                        <v-icon>mdi-close</v-icon>
                        </v-btn>

                        <v-btn absolute right small color="error" @click="resetDiariesFilters">
                        Limpar Campos
                        </v-btn>
                    </v-list-item>
                </v-list>
                <v-list class="pt-10">
                    <v-form @submit.prevent="searchDiaries">
                        <v-list-item>
                            <v-row>
                                <v-col cols="6">
                                    <v-menu
                                    v-model="diariesStartDate"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    :close-on-content-click="false"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <validation-provider v-slot="{ errors }" vid="filterDiaries.start_date" name="Data da Parte Diária" rules="required">
                                        <v-text-field
                                            label="Data Início"
                                            v-model="filterDiaries.start_date"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            clearable
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="filterDiaries.start_date = null"
                                            :error-messages="errors"
                                        ></v-text-field>
                                        </validation-provider>
                                    </template>
                                    <v-date-picker
                                        v-model="filterDiaries.start_date"
                                        locale="pt-pt"
                                        @input="diariesStartDate = false;"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6">
                                    <v-menu
                                    v-model="diariesEndDate"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    :close-on-content-click="false"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <validation-provider v-slot="{ errors }" vid="end_date" name="Data Fim" rules="required">
                                        <v-text-field
                                            label="Data Fim"
                                            v-model="filterDiaries.end_date"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            clearable
                                            v-bind="attrs"
                                            v-on="on"
                                            @click:clear="filterDiaries.end_date = null"
                                            :error-messages="errors"
                                        ></v-text-field>
                                        </validation-provider>
                                    </template>
                                    <v-date-picker
                                        v-model="filterDiaries.end_date"
                                        locale="pt-pt"
                                        @input="diariesEndDate = false;"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-list-item>
                        <v-list-item>
                            <v-select
                                v-model="filterDiaries.work_id"
                                :items="works"
                                :item-text="item => 'Nome: ' + item.name + ' - Código: '+ item.code"
                                item-value="id"
                                menu-props="auto"
                                label="Obras"
                                prepend-inner-icon="mdi-account-hard-hat"
                                clearable
                            ></v-select>
                        </v-list-item>
                        <v-list-item>
                            <v-select
                                v-model="filterDiaries.status"
                                :items="diariesStatus"
                                item-text="name"
                                item-value="id"
                                menu-props="auto"
                                label="Estado"
                                prepend-inner-icon="mdi-list-status"
                                clearable
                            ></v-select>
                        </v-list-item>

                        <v-list-item v-if="!$root.session.hasRole(['encarregado'])">
                            <v-select
                                v-model="filterDiaries.commissioner_id"
                                :items="commissioners"
                                :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                                item-value="id"
                                menu-props="auto"
                                label="Encarregado"
                                prepend-inner-icon="mdi-account-hard-hat"
                                clearable
                            ></v-select>
                        </v-list-item>


                        <v-list-item v-if="!$root.session.hasRole(['dto'])">
                            <v-select
                                v-model="filterDiaries.engineer_id"
                                :items="engineers"
                                :item-text="item => 'Nome: ' + item.name + ' - Username: '+ item.username"
                                item-value="id"
                                menu-props="auto"
                                label="Engenheiro"
                                prepend-inner-icon="mdi-account-tie"
                                clearable
                            ></v-select>
                        </v-list-item>

                        <v-row
                            class="mt-10"
                            align="center"
                            justify="space-around"
                        >
                            <v-btn
                                color="primary"
                                type="submit"
                            >
                                Pesquisar
                            </v-btn>
                        </v-row>
                    </v-form>
                </v-list>
            </v-navigation-drawer>
        </v-card>
    </v-container>
</template>
<script>
import Dashboard from '@/api/Dashboard.js'
import DashboardV2 from '@/api/DashboardV2.js'
import User from '@/api/User.js'
import Diary from '@/api/Diary.js'
import Work from '@/api/Work.js'
import RendimentoMO from '@/views/dashboard/RendimentoMO.vue';
import RendimentoUO from '@/views/dashboard/RendimentoUO.vue';
import dayjs from 'dayjs'
import VueApexCharts from 'vue-apexcharts'

export default{
    name:"StatisticsTab",
    components:{
        RendimentoUO,
        RendimentoMO,
        VueApexCharts
    },
    data(){
        return{
            encarregado: null,
            statisticsFields: null,
            filters: {},
            loading: true,
            speedDial: false,
            openDiariesFilter: false,
            showDiariesGraph: false,
            filterDiaries:{
                engineer_id: null,
                commissioner_id:null,
                status: null,
                work_id: null,
                start_date:dayjs().subtract(7, 'days').format("YYYY-MM-DD"),
                end_date:dayjs().format("YYYY-MM-DD"),
                year: dayjs().format("YYYY"),
            },
            engineers: [],
            commissioners: [],
            diariesStatus: [],
            works: [],
            diariesStartDate: false,
            diariesEndDate: false,
            diariesPerMonth: []
        }
    },
    beforeMount(){
        let d = new Date()
        this.filterDiaries.year = d.getFullYear()
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        async fillBaseData(){
            await Dashboard.getStatistics(this.filters).then(({data}) => {
                this.encarregado = data.encarregado
                this.statisticsFields = data
            });

            this.diariesPerMonthSearch()
            
            await User.list().then(({data}) => {

                this.commissioners = data.filter(item => item.roles?.some(role => role.name == "encarregado"));                

                this.engineers = data.filter(item => item.roles?.some(role => role.name == "dto"));
            });

            Diary.listStatus().then(({data}) => {
                this.diariesStatus = Object.keys(data).map(item => {return {id: item, name: data[item]}});
            });

            Work.list().then(({data}) => {
                this.works = data;
            });

            this.loading = false
        },
        diariesPerMonthSearch() {           
            DashboardV2.getDiaryPerMonth(this.filterDiaries).then((resp)    =>  {
                this.diariesPerMonth = resp.data
            })
        },        
        resetDiariesFilters() {
            this.filterDiaries= {
                start_date:dayjs().subtract(7, 'days').format("YYYY-MM-DD"),
                end_date:dayjs().format("YYYY-MM-DD"),
                commissioner_id:null,
                overseer_id:null,
                engineer_id:null,
                status: null,
                year: dayjs().format("YYYY"),
            }
        },
    },
    computed:{
        diariesPerMonthSeries(){
            return [
                {
                    data: this.diariesPerMonth
                }
            ]
        },
        diariesPerMonthOptions(){
            return {
                chart: {
                    height: 350,
                    type: 'bar',
                    
                    },
                    colors: ["#E87204"],
                    plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: true,
                    }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val;
                        },
                    },
                    legend: {
                        show: false
                    },
                    xaxis: {
                        categories: [
                            [this.$getMonthAbreviado(0)],
                            [this.$getMonthAbreviado(1)],
                            [this.$getMonthAbreviado(2)],
                            [this.$getMonthAbreviado(3)],
                            [this.$getMonthAbreviado(4)],
                            [this.$getMonthAbreviado(5)],
                            [this.$getMonthAbreviado(6)],
                            [this.$getMonthAbreviado(7)],
                            [this.$getMonthAbreviado(8)],
                            [this.$getMonthAbreviado(9)],
                            [this.$getMonthAbreviado(10)],
                            [this.$getMonthAbreviado(11)],
                        ],
                    labels: {
                        style: {
                            colors: ["#000000"],
                            fontSize: '12px'
                        }
                    }
                }
            }
        },
        getYears(){
            let year = 2022
            let i = 0
            let date = new Date()
            let actual = date.getFullYear();

            let arrayYear = []
            for(i = 0; year <= actual; i++){
                arrayYear.push(year)
                year++
            }

            return arrayYear
        },
    }
}
</script>