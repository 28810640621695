<template>
    <section class="mt-16">
      <v-row>        
        <v-col cols="12">
            <WorkerCalendar />
        </v-col>
      </v-row>
    </section>
  </template>
  
  <script>
  import WorkerCalendar from '@/components/dashboard/internals/WorkerCalendar.vue'
  
  export default {
    name: "ColaboradorView",
    components:{
      WorkerCalendar
    },
    data() {
      return {
        
      };
    },
  };
  </script>
  