import BaseApi from "./BaseApi";

export default class CalendarEvents extends BaseApi{
    construct() {}
    
    static async list(params) {
        let url = CalendarEvents.getApiUrl() + "list";

        return CalendarEvents.getAxiosInstance().get(url,{params: params});
    }

    static async create(params) {
        return await CalendarEvents.getAxiosInstance().post(CalendarEvents.getApiUrl(),params);
    }

    static async delete(id) {
        return await CalendarEvents.getAxiosInstance().delete(CalendarEvents.getApiUrl() + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/calendar-events/";
    }
}