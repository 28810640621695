<template>
    <v-container fluid>
        <v-card elevation="0" outlined class="mb-2">
            <v-card-text>
                <v-row justify="center">
                    <v-col cols="1">
                        <v-file-input
                            v-model="fields.logo"
                            :rules="rules"
                            accept="image/png, image/jpeg, image/bmp"
                            prepend-icon="mdi-camera"
                            @change="onFileChange"
                            class="image-upload circle-btn"
                            dark
                        ></v-file-input>
                    </v-col>
                    <v-col cols="1" v-if="fields.logo" style="display: flex; flex-direction: row; gap: 1rem;">
                        <v-btn
                            color="success"
                            @click="submit"
                            :loading="loading"
                            :disabled="!fields.logo"
                            fab
                        >
                            <v-icon color="white">mdi-check</v-icon>
                        </v-btn>
                        <v-btn
                            color="error"
                            @click="removeImage"
                            :disabled="!fields.logo"
                            fab
                        >
                            <v-icon color="white">mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-container fluid>
                    <v-img v-if="img_url"
                    :key="img_url"
                        style="display: block; margin:auto; position: relative;"
                        :src="img_url"
                        ref="logoImage"
                        @load="loadedImage"
                        cover
                        :aspect-ratio="16/9"
                        class="center mt-2"
                        max-width="100%"
                        max-height="350px"
                    />
                </v-container>
            </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <SuccessSnackBar v-model="success" :message="'Foto cover inserida com sucesso'" />
    </v-container>
</template>
<script>
import Work from '@/api/Work.js'
import SuccessSnackBar from '@/components/ui/SuccessSnackBar.vue';

export default{
    name:"Photos",
    components:{
        SuccessSnackBar
    },
    props:['work_object'],
    data(){
        return{
            success: false,
            loading: true,
            fields:{
                logo: null
            },
            error:{
                show: false,
                message: '',
                title: null
            },
            logo_changed: false,
            img_url: null,
            rules: [
                value => !value || value.size < 2000000 || 'Image to big',
            ],
            imageObject:{
                width: null,
                height: null
            }
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        fillBaseData(){
            this.loading = false

            if(this.work_object.cover != null){
                this.img_url = this.work_object.cover
            }
        },
        loadedImage(){
            const img = new Image();
            img.src = this.$refs.logoImage.src;
            img.onload = () => {
                this.imageObject = img
                this.imageObject.width = img.width
                this.imageObject.height = img.height
            };
        },
        onFileChange(file){
            if(file == null)
                return

            let tamanho = 2000000; // 2 MB

            if(file.size >= tamanho){
                this.fields.logo = null
                this.error.message = 'Image bigger than 2MB'
                this.error.show = true
                return
            }

            this.logo_changed = true;

            const reader = new FileReader();

            reader.onload = e => {
                this.img_url = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        async submit(){
            const formData = new FormData();
            if(this.fields.logo != null)
                formData.append('logo', this.fields.logo)
            else return;



            await Work.uploadCover(formData, this.$route.params.id).then((response) => {
                this.fields.logo = null
                this.img_url = response.data.coverUrl

                this.success = true;

                this.loading = false;

                this.fillBaseData();

                this.$emit('reload')

                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                this.$refs.form.setErrors(err.response.data.errors);

                return;
                }

                this.error.title = 'ERROR: ' + " " + err.response.status;

                this.error.message = err.response.data.message;

            });
        },
        removeImage() {
            this.fields.logo = null;
            this.img_url = null;
        },
    }
}
</script>
<style>

.image-upload .v-input__control {
    display: none !important;
}

.circle-btn {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray;
    text-align: center;
}

.v-input__prepend-outer {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: -5px;
}
</style>