<template>
    <section > 
      
      
        <div style="position:relative">
          <v-btn @click="searchSubscribed" class="success"><v-icon class="mr-2">mdi-refresh</v-icon>Atualizar lista</v-btn>
        
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :loading="loading"
            :footer-props="tableFooter"
            :options.sync="options"
            locale="pt-pt"
          >
          
  
            
          </v-data-table>
          
        </div>
    </section>
  </template>
  <script>
  import QRCode from "@/api/QRCode.js";
  
  export default {
    props:{
      work_id: String,
      front_id: {
        typeof: String,
        default: null
      }
    },
    components: {

    },
    watch: {
      'options': {
        immediate: false,
        handler () {
          setTimeout(() => {this.searchSubscribed()}, 10);
        },
        deep: true,
      },
    },
    data: () => ({
      headers: [
        {
          text: 'Código',
          sortable: true,
          value: 'worker.code',
        },
        {
          text: 'Colaborador',
          sortable: true,
          value: 'worker.name',
        },
        {
          text: 'Criada a',
          sortable: true,
          value: 'created_at',
        },
      ],
      items: [],
      total: 0,
      loading: false,
      searching: false,
      tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
      },
      options: {},
      speedDial:false,
      filter: {
      }
    }),
    mounted(){

      this.fillBaseData()
    },
    methods:{
      fillBaseData() {
        if(localStorage["qr-code-subscribed-v2-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["qr-code-subscribed-v2-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
      searchSubscribed(event){
        if(!!event && event.type == 'submit'){
          this.options.page = 1;
        }
  
        this.loading = true;
  
        let filter = {...this.filter};
        
        let request = {
          sortBy: this.options.sortBy,
          sortDesc: this.options.sortDesc,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          work_id: this.work_id,
          front_id: this.front_id
        };
      
        Object.assign(request, filter);
  
        localStorage["qr-code-subscribed-v2-" + this.$root.session.id] = JSON.stringify({
          filter: this.filter,
          options: this.options,
        });
  
        QRCode.search(request).then(response => {
  
          let data = response.data;
  
          this.total = data.total;
  
          this.items = data.data.map((item) => {
  
            this.searching = false;
  
            return item;  
          });
  
          this.loading = false;
  
        });
      }
    },
    
  };
  </script>
  