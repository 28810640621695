import BaseApi from "./BaseApi";

export default class Files extends BaseApi{
    construct() {}
    
    static async get(id) {
        let url = Files.getApiUrl() + id

        return await Files.getAxiosInstanceDownload(url)
        //return await Files.getAxiosInstance().get(Files.getApiUrl(), {params: path});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/files_export/";
    }
}