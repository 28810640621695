var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-theme-provider',{attrs:{"root":""}},[_c('v-app',[_c('v-navigation-drawer',{staticStyle:{"z-index":"100"},attrs:{"app":"","mini-variant":_vm.showMini,"width":"300"},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{attrs:{"nav":""}},[(!_vm.showMini)?_c('v-container',{staticClass:"fill-height fill-width",attrs:{"fluid":""}},[(_vm.maintenance)?_c('v-img',{staticStyle:{"display":"block","margin-left":"auto","margin-right":"auto"},attrs:{"src":require("@/assets/Maintenance/manutencao.png"),"aspect-ratio":16/9,"contain":"","max-height":"100px","width":"100%"}}):(_vm.$root.$vuetify.theme.isDark)?_c('v-img',{staticStyle:{"display":"block","margin-left":"auto","margin-right":"auto"},attrs:{"src":require("@/assets/logo/geonorte_logo_dark.png"),"aspect-ratio":16/9,"contain":"","max-height":"100px","width":"100%"}}):_c('v-img',{staticStyle:{"display":"block","margin-left":"auto","margin-right":"auto"},attrs:{"src":require("@/assets/logo/geonorte_logo.jpg"),"aspect-ratio":16/9,"contain":"","max-height":"100px","width":"100%"}})],1):_vm._e(),(!_vm.showMini)?_c('v-divider',{staticClass:"mb-4"}):_vm._e(),(!_vm.showMini)?_c('v-container',{staticClass:"py-0 my-0 mb-4"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-card',{staticClass:"elevation-0"},[_c('div',{staticClass:"d-flex flex-no-wrap"},[_c('v-avatar',{staticClass:"ma-1",class:{'avatar-hover': _vm.hover, 'avatar-rotate': _vm.rotateAvatar},style:({ 
                      backgroundColor: '#FF7F00', /* Cor laranja */
                      border: '5px solid white', /* Borda branca */
                      borderRadius: '50%' /* Borda arredondada */
                    }),attrs:{"size":"65"},on:{"mouseover":_vm.startRotation,"mouseleave":_vm.stopRotation}},[_c('v-icon',{attrs:{"dark":"","x-large":""}},[_vm._v(" mdi-account-circle ")])],1),_c('div',{staticStyle:{"width":"100%"}},[_c('v-card-text',{staticClass:"text-caption text-center"},[(_vm.$root.session != null)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.$root.session.username))])]),(_vm.$root.session.hasRole(['encarregado', 'dto']))?_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('span',{staticClass:"text-caption font-weight-bold"},[_vm._v("Score:")]),_vm._v(_vm._s(_vm.$root.session.score)+" ")]):_vm._e()],1):_vm._e()],1)],1)],1)])],1)],1)],1):_vm._e(),(!_vm.showMini)?_c('v-divider',{staticClass:"mb-4"}):_vm._e(),(_vm.loading)?_vm._l((_vm.items.length),function(i){return _c('v-skeleton-loader',{key:i,attrs:{"type":"list-item"}})}):_vm._l((_vm.items),function(item){return [(item.items == 0)?_c('v-tooltip',{key:item.title,attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":item.link,"exact":item.exact}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])]):_c('v-tooltip',{key:item.title,attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-list-group',_vm._g(_vm._b({attrs:{"group":item.group},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)]},proxy:true}],null,true)},'v-list-group',attrs,false),on),[_vm._l((item.items),function(child){return [(child.has_permission)?_c('v-tooltip',{key:child.title,attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":child.link,"exact":child.exact}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(child.icon))])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(child.title)}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(child.title))])]):_vm._e()]})],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]})],2)],1),(!_vm.loading)?_c('v-app-bar',{attrs:{"dense":"","fixed":"","app":"","clipped-left":false}},[_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({on:{"click":_vm.openMenu}},'v-app-bar-nav-icon',attrs,false),on))]}}],null,false,2352910227)},[_c('span',[_vm._v("Menu")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","link":"","to":"/admin/notifications"}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"content":_vm.notifications,"value":_vm.notifications,"color":"error","overlap":""}},[_c('v-icon',{class:{'bell-pulse-rotate': _vm.notifications > 0},attrs:{"color":_vm.notifications > 0 ? 'secondary' : ''}},[_vm._v(" mdi-bell ")])],1)],1)]}}],null,false,4265587769)},[_c('span',[_vm._v("Notificações")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"link":"","to":'/admin/settings/my-user/' + _vm.$root.session.id,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,3253366343)},[_c('span',[_vm._v("Conta")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.toggleTheme}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.theme.dark ? "mdi-white-balance-sunny" : "mdi-weather-night"))])],1)]}}],null,false,569232028)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.theme.dark ? "Modo Claro" : "Modo Escuro"))])]),_c('v-tooltip',{attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-logout")])],1)]}}],null,false,3448513929)},[_c('span',[_vm._v("Sair")])])],1):_vm._e(),_c('v-main',[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_c('v-container',_vm._g({attrs:{"fluid":""}},_vm.$listeners),[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('router-view',{on:{"read-notifications":function($event){_vm.notifications = 0}}})],1)],1)],1)],1),_c('v-footer',{attrs:{"padless":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "),_c('strong',{staticClass:"primary--text"},[_vm._v("GPD V"+_vm._s(_vm.getVersion()))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }