function calcularHorasTrabalhadas(startHour, startMinute, finishedHour, finishedMinute) {
    // Junta as horas e minutos para formar os horários de início e fim
    const startTime = `${String(startHour).padStart(2, '0')}:${String(startMinute).padStart(2, '0')}`;
    const finishedTime = `${String(finishedHour).padStart(2, '0')}:${String(finishedMinute).padStart(2, '0')}`;

    // Converte os horários em objetos Date
    const startDate = new Date(`1970-01-01T${startTime}:00Z`);  // Data fictícia para facilitar os cálculos
    const finishedDate = new Date(`1970-01-01T${finishedTime}:00Z`);

    // Se o horário de término for antes do de início, adiciona 24 horas ao horário de término
    if (finishedDate < startDate) {
        finishedDate.setDate(finishedDate.getDate() + 1);  // Adiciona 1 dia (24 horas)
    }

    // Calcula a diferença em milissegundos
    const diffInMilliseconds = finishedDate - startDate;

    // Converte a diferença para minutos
    const totalMinutesWorked = diffInMilliseconds / 60000;  // 60000 milissegundos em 1 minuto

    // Converte minutos em horas e minutos
    const hoursWorked = Math.floor(totalMinutesWorked / 60);
    const minutesWorked = totalMinutesWorked % 60;

    return { hours: hoursWorked, minutes: Math.round(minutesWorked) };
}

export default calcularHorasTrabalhadas