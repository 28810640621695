<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="error"
            rounded
            v-bind="attrs"
            small
            v-on="on"
          ><v-icon class="mr-2">mdi-cancel</v-icon>Anular aprovação</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >Anular aprovação</v-toolbar>
            <v-card-text class="mt-4">
                <v-textarea
                    v-model="explanation"
                    dense outlined
                    label="Razão"
                />
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                class="success"
                @click="$emit('on-submit', {topic: topic, explanation: explanation}); dialog.value = false"
              >Submeter</v-btn>
              <v-btn
                class="error"
                @click="dialog.value = false"
              >Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
</template>
<script>
export default{
    name: "Disapprove",
    props:{
        topic: {
            typeof: String,
            default: null
        }
    },
    data(){
        return{
            explanation: null
        }
    }
}
</script>