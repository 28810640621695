<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-btn @click="openCamera">Abrir Câmera</v-btn>
            </v-col>
            <v-col cols="12">
                <canvas ref="canvas" style="display:none;"></canvas>
                <video ref="camera" autoplay playsinline width="400" height="300"></video>                
            </v-col>
            <v-col cols="12">
                <p v-if="qrCodeData">QR Code: {{ qrCodeData }}</p>
            </v-col>
        </v-row>
    </v-container>
  </template>
  
  <script>
  import jsQR from "jsqr";
  
  export default {
    name: "ReadQRCode",
    data() {
      return {
        qrCodeData: null,
      };
    },
    methods: {
      async openCamera() {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          this.$refs.camera.srcObject = stream;
          this.scanQRCode();
        } catch (error) {
          console.error("Erro ao acessar a câmera:", error);
        }
      },
      scanQRCode() {
        const video = this.$refs.camera;
        const canvas = this.$refs.canvas;
        const context = canvas.getContext("2d");
  
        video.addEventListener("play", () => {
          const scan = () => {
            if (video.paused || video.ended) return;
  
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);
  
            if (code) {
              this.qrCodeData = code.data;
              window.location.href = this.qrCodeData;
            } else {
              requestAnimationFrame(scan);
            }
          };
          requestAnimationFrame(scan);
        });
      },
    },
  };
  </script>
  