import BaseApi from "./BaseApi";

export default class Dashboard extends BaseApi{
    construct() {}

    static async worksOnGoing(params){
        let url = Dashboard.getApiUrl() + "works-on-going";

        return Dashboard.getAxiosInstance().get(url,{params: params});
    }

    static async worksFinished(params){
        let url = Dashboard.getApiUrl() + "works-finished";

        return Dashboard.getAxiosInstance().get(url,{params: params});
    }


    
    
    static async works(params) {
        let url = Dashboard.getApiUrl() + "works";

        return Dashboard.getAxiosInstance().get(url,{params: params});
    }

    static async worksCounters() {
        let url = Dashboard.getApiUrl() + "works/counters";

        return Dashboard.getAxiosInstance().get(url);
    }

    static async diariesCounters() {
        let url = Dashboard.getApiUrl() + "diaries/counters";

        return Dashboard.getAxiosInstance().get(url);
    }

    static async diaries(params) {
        let url = Dashboard.getApiUrl() + "diaries";

        return Dashboard.getAxiosInstance().get(url,{params: params});
    }

    static async getStatistics(params){
        let url = Dashboard.getApiUrl() + "statistics";
        
        return Dashboard.getAxiosInstance().get(url, {params: params});
    }

    static async getMaintenance(params){
        let url = Dashboard.getApiUrl() + "get-maintenance";
        
        return Dashboard.getAxiosInstance().get(url, {params: params});
    }

    static async getInspection(params){
        let url = Dashboard.getApiUrl() + "get-inspection";
        
        return Dashboard.getAxiosInstance().get(url, {params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/dashboards/";
    }

    static async workHand(params) {
        let url = Dashboard.getApiUrl() + "workhand";

        return Dashboard.getAxiosInstance().get(url,{params: params});
    }

    static async unit(params) {
        let url = Dashboard.getApiUrl() + "units";

        return Dashboard.getAxiosInstance().get(url,{params: params});
    }

    //\Route::name("admin.dashboards.statistics")->get("/", "Statistics");
}