import BaseApi from "./BaseApi";

export default class Category extends BaseApi{
    construct() {}
    
    static async list(params) {
        let url = Category.getApiUrl() + "list";

        return Category.getAxiosInstance().get(url,{params: params});
    }

    static async search(params) {
        return await Category.getAxiosInstance().get(Category.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await Category.getAxiosInstance().post(Category.getApiUrl(),params);
    }

    static async update(params, id) {
        return await Category.getAxiosInstance().put(Category.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await Category.getAxiosInstance().delete(Category.getApiUrl() + id);
    }

    static async find(id) {
        return await Category.getAxiosInstance().get(Category.getApiUrl() + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/categories/";
    }
}