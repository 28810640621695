<template>
    <Dialog 
      v-bind="$attrs"
      v-on="$listeners">
      <template #content>
        <v-alert
          dense outlined
          :type="type" prominent
          elevation="2" class="text-center"
        >
          <slot></slot>
        </v-alert>
      </template>
      <template #footer>
          <v-btn
            color="error"
            depressed
            @click="$emit('on-cancel')"
          >
            <v-icon class="mr-2">mdi-close</v-icon>Não
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="OnSubmit"
            :disabled="disableButton"
          >
            <v-icon class="mr-2">mdi-check</v-icon>Sim
          </v-btn>
      </template>
    </Dialog>
</template>

<script>
  import Dialog from '@/components/ui/Dialog.vue';
  export default {
    name: 'uiDialogConfirmation',
    props: {
      type: {
        type: String,
        default: 'info'
      },
    },
    data(){
      return{
        disableButton: false,
      }
    },
    components: {
      Dialog
    },
    methods:{
      OnSubmit(){
        this.disableButton = true
        this.$emit('on-submit')
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>