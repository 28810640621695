<template>
    <v-card>
        <validation-observer ref="form" v-slot="{ invalid }">
            <fieldset>
              <v-form @submit.prevent="editClient == null ? addClient() : updateClient()" :disabled="loading">
                <v-card-title class="primary white--text">
                    Adicionar cliente
                </v-card-title>
                <v-card-text class="mt-4">
                  <DialogSuccess :opened="success" @on-ok="success = false; $emit('close');" >
                    Cliente adicionado com sucesso
                  </DialogSuccess>
                  <v-row>
                    <v-col cols="12">
                        <validation-provider v-slot="{ errors }" vid="name" name="Name" rules="required">
                            <v-text-field
                                v-model="client.name"
                                prepend-inner-icon="mdi-account-details"
                                label="Nome *"
                                clearable
                                dense outlined
                                :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                        <validation-provider v-slot="{ errors }" vid="nif" name="nif" rules="|">
                            <v-text-field
                                v-model="client.nif"
                                prepend-inner-icon="mdi-ticket-confirmation-outline"
                                label="Nif"
                                clearable
                                dense outlined
                                :error-messages="errors"
                            ></v-text-field>
                        </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col><small>Por defeito o cliente vai aparecer como ativo. Se pretender eliminar o cliente, poderá faze-lo na lista quando fechar esta janela</small></v-col>                    
                  </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        depressed
                        @click="$emit('close')"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn :disabled="invalid" depressed color="primary" type="submit">
                    Gravar
                    </v-btn> 
                </v-card-actions>
                </v-form>
            </fieldset>            
        </validation-observer>
    </v-card>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Client from "@/api/Client.js";

export default {
    components:{
        DialogSuccess
    },
    props:{
        editClient: Object
    },
    mounted(){
        if(this.editClient != null)
            this.client.name = this.editClient.name
    },
    data(){
        return{
            loading: false,
            success:false,
            client:{
                name: '',
                deleted_client: false,
                nif: null
            }
        }
    },
    methods:{
        updateClient(){
            this.loading = true
            Client.update(this.client, this.editClient.id)
                .then(() => {
                    this.success = true;

                    this.loading = false;

                    this.$emit('close-update')
                });
        },
        addClient(){
            this.loading = true
            
            Client['create'](this.client).then(() => {

                this.success = true;

                this.loading = false;

                this.$emit('close-update')

                }).catch(err => {
                this.loading = false;

                if(err.response.status == 422) {

                    this.$refs.form.setErrors(err.response.data.errors);
                    
                    return;
                }

                this.error.title = "Erro " + err.response.status;

                this.error.message = err.response.data.message;

            });
        }
    }
}
</script>