import BaseApi from "./BaseApi";

export default class Permission extends BaseApi{
    construct() {}

    static async list(params) {
        return await Permission.getAxiosInstance().get(Permission.getApiUrl() + "list",{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/permissions/";
    }
}