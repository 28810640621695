<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-account-hard-hat</v-icon> <span>Registo de Colaborador</span>
      </h1>

      <div class="mt-16">
        <worker method="create"></worker>
      </div>
  </section>
</template>
<script>
import Worker from '@/components/workers/Worker'

export default {
  components: {
    Worker
  },
  beforeCreate(){
    document.title = "GPD - Registo de Colaboradores";
  },
  data: () => ({
    
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "workers.create"])) {
      this.$router.push('/admin');
    }

  },
  methods:{
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Colaboradores',
          disabled: false,
          to: '/admin/workers-settings/workers',
          exact: true,
        },
        {
          text: 'Registo de Colaborador',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
