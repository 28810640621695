<template>
    <div class="container">
        <label class="switch">
            <input type="checkbox" v-model="toggleLigado" @change="handleToggle">
            <span class="slider round"></span>
        </label>
        <span>{{ toggleText }}</span> 
    </div>
  </template>
  
  <script>
  export default {
    props: {
      toggleText: {
        type: String,
        required: true 
      },
      value: {
        type: Boolean,
        default: false 
      }
    },
    data() {
      return {
        toggleLigado: this.value
      };
    },
    methods: {
      handleToggle() {
        this.$emit('input', this.toggleLigado); 
      }
    },
    watch: {
      value(newValue) {
        this.toggleLigado = newValue; 
      }
    }
  };
  </script>
  
  <style scoped>

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 2rem;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider:before {
    transform: translateX(1.5rem);
  }
  
  </style>
  