var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",staticStyle:{"min-height":"450px"},attrs:{"fluid":""}},[_c('v-container',{staticClass:"ma-0 pa-0 fill-height"},[_c('v-speed-dial',{attrs:{"absolute":"","top":"","right":"","direction":"bottom","open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","x-large":"","fab":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},'v-btn',attrs,false),on),[(_vm.speedDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('span',[_vm._v("Opções")])])]},proxy:true}]),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[_c('v-tooltip',{attrs:{"right":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"success","large":""},on:{"click":function($event){return _vm.$emit('new-model', 'Mod007EQ')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-numeric-7-box")])],1)]}}])},[_c('span',[_vm._v("Criar Mod007EQ")])]),_c('v-tooltip',{attrs:{"right":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"warning","large":""},on:{"click":function($event){return _vm.$emit('new-model', 'Mod008EQ')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-numeric-8-box")])],1)]}}])},[_c('span',[_vm._v("Criar Mod008EQ")])]),_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"secondary","large":""},on:{"click":function($event){return _vm.$emit('new-model', 'Mod009EQ')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-numeric-9-box")])],1)]}}])},[_c('span',[_vm._v("Criar Mod009EQ")])]),_c('v-tooltip',{attrs:{"right":"","color":"orange"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"orange","large":""},on:{"click":function($event){return _vm.$emit('new-model', 'Mod010EQ')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-numeric-10-box")])],1)]}}])},[_c('span',[_vm._v("Criar Mod010EQ")])]),_c('v-tooltip',{attrs:{"right":"","color":"orange"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"orange","large":""},on:{"click":function($event){return _vm.$emit('new-model', 'Mod011EQ')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-numeric-1-box")])],1)]}}])},[_c('span',[_vm._v("Criar Mod011EQ")])])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.footer,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('IconView',{on:{"click":function($event){return _vm.$emit('open-model', item)}}})],1),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('IconRemove',{staticClass:"mr-2",on:{"on-submit":function($event){return _vm.deleteModel(item)}}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }