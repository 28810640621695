<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <v-form @submit.prevent="submit"  class="mb-16">
       <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>
      <fieldset :disabled="loading">
        <v-row>
          <v-col cols="12" md="4">
            <validation-provider v-slot="{ errors }" vid="code" name="Código" rules="required">
              <v-text-field
                v-model="fields.code"
                prepend-inner-icon="mdi-barcode"
                label="Código *"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="4">
            <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
              <v-text-field
                v-model="fields.name"
                prepend-inner-icon="mdi-form-textbox"
                label="Nome *"
                clearable
                :error-messages="errors"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="4">
            <validation-provider v-slot="{ errors }" vid="unit" name="Unidade" rules="required">
              <v-text-field
                v-if="false"
                v-model="fields.unit"
                prepend-inner-icon="mdi-form-textbox"
                label="Unidade *"
                clearable
                :error-messages="errors"
              ></v-text-field>
              <v-select
                  v-model="fields.unit_management_id"
                  :items="unitsManagement"
                  item-value="id"
                  :item-text="item => item.abbreviation"
                  menu-props="auto"
                  :error-messages="errors"
                  label="Unidade *"
                  prepend-inner-icon="mdi-form-textbox"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="mt-6" align="center" justify="space-around">
          <v-col align="center" justify="space-around">
            <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
              Gravar
            </v-btn>
          </v-col>
        </v-row>
      </fieldset>
    </v-form>
     <DialogSuccess :opened="success" @on-ok="onSuccess" >
        Unidade de Obra gravada com sucesso!
    </DialogSuccess>
  </validation-observer>
</template>
<script>
import Unit from "@/api/Unit.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import UnitsManagement from "@/api/UnitsManagement.js"

export default {
  components: {
    DialogSuccess
  },
  props: {
   unit: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
    unit: {
      immediate: true, 
      handler (val) {
        this.fields.theme = this.$themes[0];

        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;
      }
    },
  },
  mounted(){
    UnitsManagement.list().then(({data}) => {
          this.unitsManagement = data
      });
  },
  data: () => ({
    unitsManagement: [],
    fields: {
      id: null,
      code: null,
      unit_management_id:null,
      name:null,
      unit:null,
    },
    loading:false,
    error: {
      title: '',
      message: '',
    },
    success: false,
  }),
  methods:{
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/admin/works-settings/units');
    },
    submit() {

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        Unit[this.method](this.fields, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    
  }
};
</script>
