var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('span',{staticClass:"text-h6"},[_vm._v("Marcar todos como:")])]),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{staticClass:"success--text",attrs:{"small":"","outlined":"","rounded":""},on:{"click":_vm.marcarSede}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-warehouse")]),_vm._v(" Sede ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.itemsTotal,"loading":_vm.loading,"footer-props":_vm.footer,"options":_vm.options,"hide-default-footer":"","height":_vm.items.length >= 10 ? 500 : '',"fixed-header":"","locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.worker.code)+" - "+_vm._s(item.name)+" ")]}},{key:"item.work_id",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"items":_vm.works,"item-text":function (item) { return item.name + ' - ' + item.code; },"item-value":"id","menu-props":"auto","label":"Obra","clearable":""},model:{value:(item.work_id),callback:function ($$v) {_vm.$set(item, "work_id", $$v)},expression:"item.work_id"}})]}},{key:"item.sede",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.sede},model:{value:(item.sede),callback:function ($$v) {_vm.$set(item, "sede", $$v)},expression:"item.sede"}})]}},{key:"item.folga",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.folga},model:{value:(item.folga),callback:function ($$v) {_vm.$set(item, "folga", $$v)},expression:"item.folga"}})]}},{key:"item.conduril",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.conduril},model:{value:(item.conduril),callback:function ($$v) {_vm.$set(item, "conduril", $$v)},expression:"item.conduril"}})]}},{key:"item.casamento",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.casamento},model:{value:(item.casamento),callback:function ($$v) {_vm.$set(item, "casamento", $$v)},expression:"item.casamento"}})]}},{key:"item.falta_injustificada",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.falta_injustificada},model:{value:(item.falta_injustificada),callback:function ($$v) {_vm.$set(item, "falta_injustificada", $$v)},expression:"item.falta_injustificada"}})]}},{key:"item.ferias",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.ferias},model:{value:(item.ferias),callback:function ($$v) {_vm.$set(item, "ferias", $$v)},expression:"item.ferias"}})]}},{key:"item.quarentena",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.quarentena},model:{value:(item.quarentena),callback:function ($$v) {_vm.$set(item, "quarentena", $$v)},expression:"item.quarentena"}})]}},{key:"item.seguro",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.seguro},model:{value:(item.seguro),callback:function ($$v) {_vm.$set(item, "seguro", $$v)},expression:"item.seguro"}})]}},{key:"item.licenca_parental",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.licenca_parental},model:{value:(item.licenca_parental),callback:function ($$v) {_vm.$set(item, "licenca_parental", $$v)},expression:"item.licenca_parental"}})]}},{key:"item.teletrabalho",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.teletrabalho},model:{value:(item.teletrabalho),callback:function ($$v) {_vm.$set(item, "teletrabalho", $$v)},expression:"item.teletrabalho"}})]}},{key:"item.tribunal",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.tribunal},model:{value:(item.tribunal),callback:function ($$v) {_vm.$set(item, "tribunal", $$v)},expression:"item.tribunal"}})]}},{key:"item.luto",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.luto},model:{value:(item.luto),callback:function ($$v) {_vm.$set(item, "luto", $$v)},expression:"item.luto"}})]}},{key:"item.formacao",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.formacao},model:{value:(item.formacao),callback:function ($$v) {_vm.$set(item, "formacao", $$v)},expression:"item.formacao"}})]}},{key:"item.baixa",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.baixa},model:{value:(item.baixa),callback:function ($$v) {_vm.$set(item, "baixa", $$v)},expression:"item.baixa"}})]}},{key:"item.aniversario",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.oneSelected(item) && !item.aniversario},model:{value:(item.aniversario),callback:function ($$v) {_vm.$set(item, "aniversario", $$v)},expression:"item.aniversario"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }