import BaseApi from "./BaseApi";

export default class Unit extends BaseApi{
    construct() {}
    
    static async list(params) {
        let url = Unit.getApiUrl() + "list";

        return Unit.getAxiosInstance().get(url,{params: params});
    }

    static async create(params) {
        return await Unit.getAxiosInstance().post(Unit.getApiUrl(),params);
    }

    static async search(params) {
        return await Unit.getAxiosInstance().get(Unit.getApiUrl(),{params: params});
    }
    
    static async update(params, id) {
        return await Unit.getAxiosInstance().put(Unit.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await Unit.getAxiosInstance().delete(Unit.getApiUrl() + id);
    }

    static async find(id) {
        return await Unit.getAxiosInstance().get(Unit.getApiUrl() + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/units/";
    }
    
}