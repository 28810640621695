<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <Maintenance />
        </v-col>
        <v-col cols="12">
          <Inspection />
        </v-col>
        <v-col cols="12">
          <Warnings />
        </v-col>
      </v-row>
  
        
    </v-container>
</template>
<script>
import Maintenance from '@/components/dashboard/Others/Maintenance.vue'
import Inspection from '@/components/dashboard/Others/Inspection.vue'
import Warnings from '@/components/dashboard/Others/Warnings.vue'

export default{
    name:"OthersTab",
    components:{
      Maintenance,
      Inspection,
      Warnings
    },
    data(){
        return{
            loading: true
        }
    },
    mounted(){
      
    },
    methods:{
        
    }
}
</script>