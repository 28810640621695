<template>
  <div>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-form @submit.prevent="submit"  class="mb-16">
        <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
        <fieldset :disabled="loading">
          <v-row class="mt-2">
            <v-col cols="12" md="2">
              <validation-provider v-slot="{ errors }" vid="code" name="Código" rules="required">
                <v-text-field
                  v-model="fields.code"
                  prepend-inner-icon="mdi-barcode"
                  label="Código *"
                  dense outlined
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="4">
              <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
                <v-text-field
                  v-model="fields.name"
                  prepend-inner-icon="mdi-account-details"
                  label="Nome *"
                  dense outlined
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="3">
              <validation-provider v-slot="{ errors }" vid="phone_number" name="Telefone" rules="required">
                <v-text-field
                  v-model="fields.phone_number"
                  prepend-inner-icon="mdi-phone"
                  label="Telefone *"
                  dense outlined
                  clearable
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="menu"
                v-model="birthday_date"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fields.birthday"
                    dense outlined
                    label="Aniversário"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fields.birthday"
                  :active-picker.sync="activePicker"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                  min="1950-01-01"
                  @change="save"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="4">
                <v-checkbox
                  v-model="checkbox"
                  label="Associar empresa"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-select
                  :disabled="!checkbox"
                  v-model="fields.id_company"
                  :items="companies"
                  :item-text="item => item.name"
                  item-value="id"
                  menu-props="auto"
                  label="Empresa *"
                  prepend-inner-icon="mdi-factory"
                  clearable
                ></v-select>
              </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2">
              <validation-provider v-slot="{ errors }" vid="value" name="Valor" rules="min_value:0">
                <v-currency-field
                    v-model="fields.value"
                    prepend-inner-icon="mdi-currency-eur"
                    label="Custo/h"
                    @blur="fields.value = fields.value == 0 ? null : fields.value"
                    clearable
                    :error-messages="errors"
                  />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="4">
              <validation-provider v-slot="{ errors }" vid="category_id" name="Categoria" rules="required">
                <v-select
                    v-model="fields.category_id"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    menu-props="auto"
                    label="Categoria"
                    prepend-inner-icon="mdi-shape"
                    clearable
                    :error-messages="errors"
                ></v-select>
                <v-row justify="end" class="px-3">
                  <v-btn
                    color="secondary"
                    text
                    outlined
                    right
                    small
                    depressed
                    @click="creatingCategory = true"
                  >
                    Registar nova categoria
                  </v-btn>
                </v-row>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="2" v-if="method=='update'">
              <v-btn color="warning" @click="criarUserWorker">
                Criar conta de utilizador
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                Gravar
              </v-btn>
          </v-row>
        </fieldset>
      </v-form>
      <DialogSuccess :opened="success" @on-ok="onSuccess" >
        Colaborador gravado com sucesso!
      </DialogSuccess>
    </validation-observer>
    <v-dialog
        v-model="creatingCategory"
        persistent
        max-width="600px"
      >
        <v-card>
          <validation-observer ref="formCategory" v-slot="{ invalid }">
            <v-progress-linear v-if="loadingCategory"
            indeterminate
            class="global-loader"
            ></v-progress-linear>
            <fieldset :disabled="loadingCategory">
              <v-form @submit.prevent="submitCategory">
                <v-card-title>
                  <h2 class="secondary--text subtitle-1">
                    Categoria
                  </h2>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
                          <v-text-field
                            v-model="category.name"
                            prepend-inner-icon="mdi-form-textbox"
                            label="Nome *"
                            clearable
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider v-slot="{ errors }" vid="value" name="Custo" rules="required|min_value:0">
                          <v-currency-field
                              v-model="category.value"
                              prepend-inner-icon="mdi-currency-eur"
                              label="Custo *"
                              clearable
                              :error-messages="errors"
                            />
                          </validation-provider>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    depressed
                    @click="creatingCategory = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn :disabled="invalid" depressed color="primary" type="submit">
                    Gravar
                  </v-btn>            
                </v-card-actions>
              </v-form>
            </fieldset>
          </validation-observer>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Category from "@/api/Category.js";
import Company from "@/api/Company.js";
import Worker from "@/api/Worker.js";
import dayjs from 'dayjs'

export default {
  components: {
    DialogSuccess
  },
  props: {
   worker: Object,
   method: String,
   disabled: Boolean,
 },
 watch: { 
    birthday_date (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    worker: {
      immediate: true, 
      handler (val) {
        if(!val) {
          return;
        }

        let data = {...val};

        this.fields = data;

        this.fields.category_id = data.category.id;

        this.fields.id = data.id;

        if(data.company != null){
          this.fields.id_company = data.company.id
          this.checkbox = true
        }
      }
    },
  },
  beforeCreate(){
  },
  data: () => ({
    activePicker: null,
    creatingCategory: false,
    loadingCategory: false,
    birthday_date: false,
    fields: {
      id: null,
      code: dayjs().unix(),
      name:null,
      phone_number:null,
      value:null,
      category_id:null,
      id_company: null,
      deleted_worker: false
    },
    category: {
      name: null,
      value: null,
    },
    loading:false,
    error: {
      title: '',
      message: '',
    },
    categories: [],
    companies: [],
    success: false,
    checkbox:false,
  }),
  mounted(){
    this.fillBaseData();
  },
  methods:{
    save (date) {
      this.$refs.menu.save(date)
    },
    criarUserWorker(){
      this.$router.push('/admin/settings/users/create/from-worker/' + this.$route.params.id)
    },
    onSuccess() {
      if(this.fields.id != null) {
        this.success = false
        return
      }
      this.$router.push('/admin/workers-settings/workers');
    },
    fillBaseData(){
      Category.list().then(({data}) => {
        this.categories = data;
      });

      Company.list().then(({data}) => {
        this.companies = data;
      });
    },
    submitCategory(){
      this.$refs.formCategory.validate().then(() => {
        this.creatingCategory = true;

        Category.create(this.category).then(({data}) => {

          this.categories.push(data);

          this.creatingCategory = false;

          this.fields.category_id = data.id;

          this.category = {};
        });
      });
    },
    submit() {

      if(this.checkbox == false)
        this.fields.id_company = null

      this.$refs.form.validate().then((result) => {
        this.loading = true;
        
        if(!result){
          this.loading = false;
          return;
        }

        Worker[this.method](this.fields, this.fields.id).then(() => {

          this.success = true;

          this.loading = false;
          
        }).catch(err => {
          this.loading = false;

            if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
            }

            this.error.title = "Erro " + err.response.status;

            this.error.message = err.response.data.message;

          });
      })
    }
  },
  computed: {
    
  }
};
</script>
