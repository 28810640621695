<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <v-row class="mt-1">
        <v-col cols="12" md="6">
          <h1 class="primary--text">
            <v-icon color="primary">mdi-notebook</v-icon> <span>Edição de Diário</span>
          </h1>
        </v-col>
        <v-col cols="12" md="6" align="end" justify="end">
          <v-row align="end" justify="end">
            <v-col @click="previousDiary" cols="auto">
              <v-btn class="primary" small rounded>
                <v-icon>mdi-arrow-left</v-icon>Anterior
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="false">
              <span v-if="diary != null">{{getDate(diary.date)}}</span>
            </v-col>
            <v-col cols="auto">
              <v-btn class="primary" @click="nextDiary" small rounded>
                Próxima<v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="mt-16">
        <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>

        <template v-if="loading">
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-skeleton-loader
                type="button"
              ></v-skeleton-loader>
          </v-row>
        </template>
        <diary v-else method="update" :diary="diary" @simpleReload="simpleReload" @reloadAll="reloadAll" @reload="reload" :key="reloadAllComponent"></diary>
      </div>
  </section>
</template>
<script>
import Diary from '@/components/diaries/Diary'
import DiaryApi from "@/api/Diary.js";
import { getDiariesOffline } from '../../idb'
import dayjs from 'dayjs'

export default {
  components: {
    Diary
  },
  beforeCreate(){
    document.title = "GPD - Edição de Diário";
  },
  data: () => ({
      diary: {},
      loading:true,
      reloadAllComponent: 0
  }),
  async mounted(){
    if(!this.$root.session.hasPermission(["super", "diaries.update", 'diaries.read'])) {
      this.$router.push('/admin');
    }

    if(this.$route.name == "Offline Diary"){
      let diaries = await getDiariesOffline();


      diaries.forEach(element => {
        if(element.id == this.$route.params.id)
          this.diary = element
      });
      this.loading = false
      return;
    }

    this.findDiary(this.$route.params.id)    
  },
  methods:{
    findDiary(id){
      DiaryApi.find(id).then(({data}) => { this.diary = data; this.loading = false;});
    },
    nextDiary(){
      this.loading = true
      let data = {
        diary_id: this.$route.params.id
      }

      DiaryApi.getNext(data).then((resp)  =>  {
        if(resp.data == 0){
          this.loading = false
          return
        }
        
        window.location.href = '/admin/diaries/' + resp.data.id
      })
    },
    previousDiary(){
      this.loading = true
      let data = {
        diary_id: this.$route.params.id
      }

      DiaryApi.getPrevious(data).then((resp)  =>  {
        if(resp.data == 0){
          this.loading = false
          return
        }
        
        window.location.href = '/admin/diaries/' + resp.data.id
      })
    },
    getDate(dt){
      return dayjs(dt).format("YYYY-MM-DD");
    },
    reload(fields){
      DiaryApi.find(this.$route.params.id).then(({data}) => { 
        this.diary.machine_work_units = data.machine_work_units
        this.diary.worker_work_units = data.worker_work_units
        //this.diary.work_units = data.work_units

        fields.machine_work_units = data.machine_work_units
        fields.worker_work_units = data.worker_work_units
        //fields.work_units = data.work_units
        
        this.loading = false;
      });
    },
    reloadAll(){
      location.reload()
    },
    simpleReload(){
      this.findDiary(this.$route.params.id)
    }
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão de Partes Diárias',
          disabled: false,
          to: '/admin/diaries',
          exact: true,
        },
        {
          text: 'Edição de Diário',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
