import BaseApi from "./BaseApi";

export default class Import extends BaseApi{
    construct() {}
    
    static async create(params) {
        return await Import.getAxiosInstance().post(Import.getApiUrl(),params);
    }

    static async getTemplate(params) {
        let url = Import.getApiUrl() + 'template';

        return Import.getAxiosInstance().get(url, {params:params});
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/import/";
    }

}
