<template>
  
    <v-tooltip v-bind="$attrs" v-on="$listeners" style="z-index: 99999 !important;">
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <slot></slot>
        </span>
      </template>
      <v-row v-for="(text, index) in messages" :key="index">
        <v-col cols="12">
            <span>{{ text }}</span>
        </v-col>
      </v-row>
    </v-tooltip>
  
  </template>
  
  <script>
  export default {
    name: 'uiTooltipErrors',
    props: {
      messages: {
        type: Array,
        required: true,
      },
    },
    mounted(){
    }
  }
  </script>
  
  <style lang="scss" scoped>
  </style>
  