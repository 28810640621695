<template>
    <section>
        <v-row>
            <v-col>
                <div style="position:relative">
                    <v-container>
                        <v-speed-dial v-if="false"
                        absolute
                        v-model="speedDial"
                        top
                        right
                        direction="bottom"
                        :open-on-hover="true"
                        >
                        <template v-slot:activator>
                            <v-tooltip right color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    v-bind="attrs" v-on="on"
                                    v-model="speedDial"
                                    color="primary"
                                    dark
                                    x-large
                                    fab
                                    >
                                    <v-icon v-if="speedDial">
                                        mdi-close
                                    </v-icon>
                                    <v-icon v-else>
                                        mdi-dots-vertical
                                    </v-icon>
                                    </v-btn>
                                </template>
                                <span>Opções</span>
                            </v-tooltip>
                        </template>
                        <v-tooltip right color="secondary">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs" v-on="on"
                                fab
                                dark
                                small
                                color="secondary"
                                large
                                @click="searching = true"
                            >
                                <v-icon>mdi-filter</v-icon>
                            </v-btn>
                            </template>
                            <span>Pesquisar</span>
                        </v-tooltip>
                        </v-speed-dial>
                    </v-container>
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :server-items-length="total"
                        :loading="loading"
                        :footer-props="tableFooter"
                        :options.sync="options"
                        class="mt-16"
                        locale="pt-pt"
                        >
                        <template v-slot:item.created_at="{ item }">
                            {{ formatDate(item.created_at)}}
                        </template>

                        <template v-slot:item.actions="{item}">
                            <div v-if="canDeleteFunction(item)">
                                <IconRemove
                                    v-if="$root.session.hasPermission(['super', 'works.update'])"
                                    class="mr-2"
                                    @on-submit="deleteSubcontractor(item)"
                                />
                            </div>
                        </template>
                    </v-data-table>
                </div>
            </v-col>
        </v-row>
        <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
            <v-list>
            <v-list-item>
                <v-btn fab small color="secondary" @click="searching = false">
                <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-btn absolute right small color="error" @click="resetFields">
                Limpar Campos
                </v-btn>
            </v-list-item>
            </v-list>
            <v-list class="pt-10">
            <v-form @submit.prevent="searchWorks">
                
            

                <v-row
                class="mt-10"
                align="center"
                justify="space-around"
                >
                <v-btn
                color="primary"
                type="submit"
                >
                Pesquisar
                </v-btn>
                </v-row>
            </v-form>
            </v-list>
        </v-navigation-drawer>
    </section>
</template>
<script>
import Work from "@/api/Work.js";
import IconRemove from '@/components/ui/IconRemove.vue';

export default {
    components:{
        IconRemove
    },
    watch: {
        'options': {
        immediate: false,
        handler () {
            setTimeout(() => {this.searchWorks()}, 10);
        },
        deep: true,
        },
    },
    data: () => ({
        speedDialOptions:{
            logActions: null,
            users: null
        },
        speedDial: false,
        loading: false,
        items: [],
        searching: false,
        total: 0,
        tableFooter: {
        'items-per-page-options': [5, 10, 15, 20],
        'items-per-page-text': "Linhas por página"
        },
        options: {},
        filter: {
        },
        headers: [
            {
                text: 'Nome',
                sortable: false,
                value: 'subcontractor.name'
            },
            {
                text: 'NIF',
                sortable: false,
                value: 'subcontractor.nif'
            },
            {
                text: 'Data',
                sortable: false,
                value: 'subcontractor.created_at'
            },
            {
                text: 'Opções',
                sortable: false,
                value: 'actions'
            },
        ],
    }),
    mounted(){
    },
    methods:{
        canDeleteFunction(item){
            let flag = false
            let id_route = this.$route.params.id

            if(id_route == undefined) return false


            if(item.in_works.length == 0)
                return true

            item.in_works.forEach(element => {
                if(element == id_route)
                    flag = false
            });

            flag = true

            return flag
        },
        deleteSubcontractor(item){
            Work.removeSubcontractors(item.id)
                .then(() => {
                this.searchWorks();
            });
        },
        resetFields(){
            this.filter = {
            }
            this.searchWorks()
        },
        row_classes(i){
            if(i.readed == true)
                return '';
        },
        formatDate(d){
            return new Date(d).toLocaleString('pt-PT')
        },
        searchWorks(event){
            if(!!event && event.type == 'submit'){
                this.options.page = 1;
            }

            this.loading = true;

            let filter = {...this.filter};
            let request = {
                sortBy: this.options.sortBy,
                sortDesc: this.options.sortDesc,
                page: this.options.page,
                limit: this.options.itemsPerPage,
                work_id: this.$route.params.id
            };
            
            
            Object.assign(request, filter);

            localStorage["subcontractors-works-" + this.$root.session.id] = JSON.stringify({
                filter: this.filter,
                options: this.options,
            });
            

            Work.searchSubcontractors(request).then(response => {

                let data = response.data;

                this.total = data.total;

                this.items = data.data.map((item) => {


                    this.searching = false;

                    return item;
                });

                this.loading = false;

            });
        }
    }
}
</script>