<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-briefcase</v-icon> <span>Edição de Unidade de Obra</span>
      </h1>

      <div class="mt-16">
        <v-progress-linear v-if="loading"
          indeterminate
          class="global-loader"
        ></v-progress-linear>

        <template v-if="loading">
          <v-row>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="6">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="text"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="mt-6" align="center" justify="space-around">
            <v-skeleton-loader
                type="button"
              ></v-skeleton-loader>
          </v-row>
        </template>
        <unit v-else method="update" :unit="unit"></unit>
      </div>
  </section>
</template>
<script>
import Unit from '@/components/units/Unit'
import UnitApi from "@/api/Unit.js";

export default {
  components: {
    Unit
  },
  beforeCreate(){
    document.title = "GPD - Edição de Unidades de Obra";
  },
  data: () => ({
      unit: {},
      loading:true
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "units.update"])) {
      this.$router.push('/admin');
    }

    UnitApi.find(this.$route.params.id).then(({data}) => { this.unit = data; this.loading = false;});
  },
  methods:{
    
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão Unidades de Obra',
          disabled: false,
          to: '/admin/works-settings/units',
          exact: true,
        },
        {
          text: 'Edição de Unidades de Obra',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
