import BaseApi from "./BaseApi";

export default class DashboardV2 extends BaseApi{
    construct() {}


    static async getWorksTab(params){
        let url = DashboardV2.getApiUrl() + 'works-tab';
        
        return DashboardV2.getAxiosInstance().get(url, {params: params});
    }

    static async getWorksCounters(params){
        let url = DashboardV2.getApiUrl() + 'works-counters';
        
        return DashboardV2.getAxiosInstance().get(url, {params: params});
    }

    static async getDiaryCounters(params){
        let url = DashboardV2.getApiUrl() + 'diaries-counters';
        
        return DashboardV2.getAxiosInstance().get(url, {params: params});
    }

    static async getDiaryPerMonth(params){
        let url = DashboardV2.getApiUrl() + 'diaries-per-month';
        
        return DashboardV2.getAxiosInstance().get(url, {params: params});
    }

    static async getWorksTotalPerformance(params){
        let url = DashboardV2.getApiUrl() + 'works-total-performance';
        
        return DashboardV2.getAxiosInstance().get(url, {params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/dashboard-v2/";
    }

    
}
