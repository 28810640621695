import BaseApi from "./BaseApi";

export default class Configuration extends BaseApi{
    construct() {}
    
    static async get() {
        let url = Configuration.getApiUrl();

        return Configuration.getAxiosInstance().get(url);
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/configuration/";
    }
}
