import BaseApi from "./BaseApi";

export default class Machine extends BaseApi{
    construct() {}
    
    static async list(params) {
        let url = Machine.getApiUrl() + "list";

        return Machine.getAxiosInstance().get(url,{params: params});
    }

    static async breakdown(params){
        let url = Machine.getApiUrl() + "breakdown";

        return Machine.getAxiosInstance().post(url,params);
    }    

    static async getDieselPrice(){
        //Route::name("admin.machines.get-diesel-prices")->get("get-diesel-prices", "Diesel\GetDiesel");
        let url = Machine.getApiUrl() + 'get-diesel-prices';

        return Machine.getAxiosInstance().get(url);
    }

    static async deleteBreakdown(id) {
        return await Machine.getAxiosInstance().delete(Machine.getApiUrl() + "breakdown/" + id);
    }

    static async updateBreakdown(params, id) {
        return await Machine.getAxiosInstance().post(Machine.getApiUrl() + "breakdown/" + id,params);
    }

    static async findBreakdown(id) {
        return await Machine.getAxiosInstance().get(Machine.getApiUrl() + "breakdown/" + id);
    }

    static async checkAvailableMachine(params){
        return await Machine.getAxiosInstance().get(Machine.getApiUrl() + "check-machine-available", {params: params});
    }

    static async searchBreakdowns(params) {
        return await Machine.getAxiosInstance().get(Machine.getApiUrl() + "breakdown/search",{params: params});
    }

    static async search(params) {
        return await Machine.getAxiosInstance().get(Machine.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await Machine.getAxiosInstance().post(Machine.getApiUrl(),params);
    }

    static async update(params, id) {
        return await Machine.getAxiosInstance().put(Machine.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await Machine.getAxiosInstance().delete(Machine.getApiUrl() + id);
    }

    static async find(id) {
        return await Machine.getAxiosInstance().get(Machine.getApiUrl() + id);
    }

    static async exportEquipments(params) {
        let url = Machine.getApiUrl() + "export";

        return Machine.getAxiosInstance().post(url, params);
    }

    static async exportMonitoring(params) {
        let url = Machine.getApiUrl() + "monitoring/export";

        return Machine.getAxiosInstance().post(url, params);
    }

    static async exportAllMonitoring(params) {
        let url = Machine.getApiUrl() + "monitoring-all/export";

        return Machine.getAxiosInstance().post(url, params);
    }   

    static async getMachineWarnings(params){
        let url = Machine.getApiUrl() + "warnings/search";
        
        return Machine.getAxiosInstance().get(url, {params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/machines/";
    }
}