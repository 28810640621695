<template>
    <v-container>    
        <v-card>
            <v-progress-linear 
            v-if="loading"
            class="mt-2"
            indeterminate
            ></v-progress-linear>
          <v-card-title>Utilização de Recursos Humanos</v-card-title>
          <v-col cols="12" md="3">
          <Select
                v-model="choosenYear"
                :items="getYears"
                label="Ano *"
                prepend-inner-icon="mdi-clock"                
                @change="changeHumanYear"
            ></Select>
        </v-col>
          <v-card-text>           
            <v-row>
                <v-col cols="12">
                  <Chart
                    :key="humanResourceGraphKey"
                    id="first-chart"
                    :options="humanResourceGraphOptions"
                    :series="humanResourceGraphSeries"
                    height="250"
                  />
                </v-col>                
              </v-row>
              <v-row class="d-none d-sm-flex px-4 mx-4" v-if="false">
                <v-col cols="11">
                    <template>
                        <table style="width:100%;" class="text-caption">
                            <thead>                                
                                <th v-for="h in headers" :key="h.text">
                                    {{h.text}}
                                </th>                                                             
                            </thead>
                            <tbody style="text-align: center;">
                                <tr>
                                    <td v-for="i in getItems" :key="i.id">{{i.data.extra}}</td>
                                </tr>
                                <tr>
                                    <td v-for="i in getItems" :key="i.id">{{i.data.faults}}</td>
                                </tr>
                                <tr>
                                    <td v-for="i in getItems" :key="i.id">{{i.data.holidays}}</td>
                                </tr>
                                <tr>
                                    <td v-for="i in getItems" :key="i.id">{{i.data.horse}}</td>
                                </tr>
                                <tr>
                                    <td v-for="i in getItems" :key="i.id">{{i.data.production}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
    </v-container>
</template>
<script>
import Chart from '@/components/ui/Chart.vue';
import Dashboard from "@/api/Dashboard.js";
import Select from '@/components/inputs/Select'

export default {
    components:{
        Chart,
        Select
    },
    data(){
        return{
            loading: true,
            choosenYear: null,
            finishedGlobalWorksKey:0,
            globalWorksKey:0,
            tableType: 0,
            values:[
                {
                    text: "Horas extra / externos"
                },
                {
                    text: "Faltas / Folgas / Casa"
                },
                {
                    text: "Férias"
                },
                {
                    text: "Estaleiro"
                },
                {
                    text: "Produção"
                },
            ],
            dashboardStatistics: null,
            humanResourceGraphKey: 0,
        }
    },
    async beforeMount(){
        this.choosenYear = new Date().getFullYear()
        this.$emit('loading')
        await this.generateStats()
        this.$emit('loaded')
    },
    mounted(){
        this.loading = false
    },
    methods:{
        async generateStats(){
            let parent = this
            let req = {
                year: this.choosenYear
            }
            await Dashboard.getStatistics(req).then(({data}) => {
                parent.dashboardStatistics = data
            });
        }, 
        async changeHumanYear(){
            this.loading = true
            this.$emit('loading')
            await this.generateStats()
            this.loading = false
        }
    },
    computed:{
        getYears(){
            let appYear = 2022
            let items = []
            const currentYear = new Date().getFullYear();

            for (let i = appYear; i <= currentYear; i++) {
                items.push(i)
            }

            return items
        },
        getItems(){
            let items = []
            
            
            if(this.dashboardStatistics == null) return []

            //let parent = this

            this.dashboardStatistics.final_months.forEach(element => {
                if(items.length == 0){
                    /*items.push({
                        id: Math.floor(Math.random() * 99999) + 1,
                        data: {
                            'extra': parent.values[0].text,
                            'faults': parent.values[1].text,
                            'holidays': parent.values[2].text,
                            'horse': parent.values[3].text,
                            'production': parent.values[4].text
                        }
                    })*/
                    items.push({
                        id: Math.floor(Math.random() * 99999) + 1,
                        data: element.data
                    })
                    
                }else{                    
                    items.push({
                        id: Math.floor(Math.random() * 99999) + 1,
                        data: element.data
                    })
                }
            });
            
            return items
        },
        headers(){
            if(this.dashboardStatistics == null) return []

            let headers = []
            /*headers.push({
                    text:   '-',
                    value:  'extra'
                })*/
            this.dashboardStatistics.final_months.forEach(element => {
                headers.push({
                    text:   element.month,
                    value:  'extra'
                })
            });
            return headers
        },
        humanResourceGraphSeries(){
          if(this.dashboardStatistics == null)
            return []

          let extra = []
          let production = []
          let horse = []
          let faults = []
          let holidays = []
          this.dashboardStatistics.final_months.forEach(element => {
            extra.push(element.data.extra)
            production.push(element.data.production)
            horse.push(element.data.horse)
            faults.push(element.data.faults)
            holidays.push(element.data.holidays)
          });

          let returner = [{
            name: 'Horas Extra / Externos',
            data: extra
          }, 
          {
            name: 'Faltas / Folgas / Casa',
            data: faults
          }, 
          {
            name: 'Férias',
            data: holidays
          }, 
          {
            name: 'Estaleiro',
            data: horse
          }, 
          {
            name: 'Produção',
            data: production
          }]

          return returner
        },
        humanResourceGraphOptions(){
            let aux = [];

            if(this.getItems.length > 0){
            for(let i=0;i<12;i++){
                let t = []
                let total = 0
                t.push(this.$getMonthAbreviado(i))

                t.push(this.getItems[i].data.extra)
                total = total + this.getItems[i].data.extra

                t.push(this.getItems[i].data.faults)
                total = total + this.getItems[i].data.faults

                t.push(this.getItems[i].data.holidays)
                total = total + this.getItems[i].data.holidays

                t.push(this.getItems[i].data.horse)
                total = total + this.getItems[i].data.horse

                t.push(this.getItems[i].data.production)
                total = total + this.getItems[i].data.production

                t.push(total)

                aux.push(t)
            }
        }else{
            aux = [0,0,0,0,0,0,0,0,0,0,0,0]
        }


          return {
              colors: this.colors,
              chart: {
              type: 'bar',
              stacked: true,
              height: 350
              },  
              plotOptions: {
                  bar: {
                      borderRadius: 4,
                      horizontal: false,
                  }
                  },
                  dataLabels: {
                  enabled: false
                  },
                  xaxis: {
              type: 'text',
              categories: aux,
            },
            legend: {
              position: 'right',
              offsetY: 40
            },
            fill: {
              opacity: 1
            },
            responsive: [
                {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                    bar: {
                        horizontal: false
                    }
                    },
                    legend: {
                        position: "top"
                    }
                }
                }
            ]
           
          } 
        },
        globalWorkOptions(){
            return {
                chart: {
                type: 'bar',
                height: 350
                },
                plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: true,
                }
                },
                dataLabels: {
                    enabled: true,
                    offsetX: -6,
                    textAnchor: 'end',
                    formatter: function (val) {
                        if(val == 0)
                            return ''
                        return val + '%'
                    },
                    style: {
                        fontSize: '12px',
                        colors: ['#fff'],
                    }
                },
                xaxis: {
                    categories: ['Total'],
                }
            }
        },
        globalWorkSeries(){
            if(this.dashboardStatistics == null)
                return []
                
            return [{
                data: [this.dashboardStatistics.average_works_course]
            }]
        },
        finishedGlobalWorkSeries(){
            if(this.dashboardStatistics == null)
                return []
                
            return [{
                data: [this.dashboardStatistics.average_works_finished]
            }]
        }
    }
}
</script>