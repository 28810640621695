<template>
  <section class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-account-hard-hat</v-icon> <span>Gestão de Fornecedores</span>
      </h1>
      <v-icon color="primary">
        mdi-eye
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Visualizar

      <v-icon color="red" class="ml-4">
        mdi-delete
      </v-icon><v-icon class="ml-2" small>mdi-forward</v-icon>
      Remover

      <div style="position:relative">
        <v-switch
            style="position:absolute"
            v-model="filter.deleted_company"
            color="success"
            @change="searchCompanies"
            label="Mostrar eliminados"
          ></v-switch>
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>                 
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  @click="openModalNewCompany()"
                  v-if="$root.session.hasPermission(['super', 'companies.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16"
          locale="pt-pt"
        >

        <template v-slot:item.created_at="{ item }">
              {{ getTableDate(item.created_at)}}
          </template>
  
          <template v-slot:item.updated_at="{ item }">
              {{ getTableDate(item.updated_at)}}
          </template>
  
          <template v-slot:item.actions="{ item }">
            <IconView
                v-if="$root.session.hasPermission(['super', 'companies.update'])"
                class="mr-2"
                @click="viewCompany(item)"
              />
            <IconRemove
                v-if="$root.session.hasPermission(['super', 'companies.delete'])"
                class="mr-2"
                :reverse="filter.deleted_company"
                @on-submit="deleteCompany(item)"
              />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetCompanies">
              Limpar Campos
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchCompanies">

            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-barcode"
                label="Nome"
                clearable
              ></v-text-field>
            </v-list-item>
            
            <v-list-item>
              <v-text-field
                v-model="filter.nif"
                prepend-inner-icon="mdi-form-textbox"
                label="NIF"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.email"
                prepend-inner-icon="mdi-form-textbox"
                label="Email"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.phone"
                prepend-inner-icon="mdi-form-textbox"
                label="Contato"
                clearable
              ></v-text-field>
            </v-list-item>


            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>

      <DialogSuccess :opened="approved" @on-ok="approved = false" >
        Empresa atualizada com sucesso
      </DialogSuccess>
        <v-dialog
          v-model="addCompany"
          max-width="800px"
        >
          <AddCompany 
            v-if="addCompany"
            :editCompany="company_selected"
            @close="addCompany = false"
            @close-update="fillBaseData()"
          />
        </v-dialog>
      
  </section>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import AddCompany from '@/components/companies/Add.vue';
import Company from "@/api/Company.js";
import dayjs from 'dayjs';

export default {
  components: {
    IconRemove,
    IconView,
    DialogSuccess,
    AddCompany
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchCompanies()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "GPD - Gestão de Fornecedores";

  },
  data: () => ({
    addCompany: false,
    exporting: false,
    approved: false,
    company_selected: null,
    headers: [
      {
          text: 'Nome',
          sortable: true,
          value: 'name',
        },
        {
          text: 'NIF',
          sortable: true,
          value: 'nif',
        },
        {
          text: 'Email',
          sortable: true,
          value: 'email',
        },
        {
          text: 'Contato',
          sortable: true,
          value: 'phone',
        },
        {
          text: 'Criada a',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Última modificação a',
          sortable: true,
          value: 'updated_at',
        },
        {
          text: 'Opções',
          sortable: false,
          value: 'actions',
        }
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      name: null,
      niff: null,
      email: null,
      phone: null
    },
    categories: [],
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "workers.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{    
    openModalNewCompany(){
        this.company_selected = null
        this.addCompany = true
    },
    getTableDate(date) {
      return date != null ? dayjs(date).toDate().toLocaleDateString() : "-";
    },
    fillBaseData() {
        this.addCompany = false
        if(localStorage["companies_v2-" + this.$root.session.id] != undefined){
  
          let data = JSON.parse(localStorage["companies_v2-" + this.$root.session.id]);
  
          this.filter = data.filter;
  
          this.options = data.options;
        }
      },
    searchCompanies(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["companies_v2-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Company.search(request).then(response => {

        let data = response.data;
  
        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });
        

        this.loading = false;

      });
    },
    resetCompanies()
    {
      this.filter = {
      };

      this.searchCompanies();
    },
    viewCompany(item){
      this.company_selected = item
      this.addCompany = true
    },
    deleteCompany(item){
      if(this.filter.deleted_company)
          item.deleted_company = false;
        else
          item.deleted_company = true
  
        Company.update(item, item.id)
          .then(() => {
            this.searchCompanies();
          });
      },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
        {
          text: 'Gestão de Fornecedores',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
