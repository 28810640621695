<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-progress-linear v-if="loading"
          indeterminate
    ></v-progress-linear>
    <v-toolbar
          dark
          color="primary"
    >
        <v-toolbar-title>Modelo 007 EQ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
        <v-btn
            icon
            dark
            @click="$emit('close')"
        >
        <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-toolbar-items>
    </v-toolbar>
    <v-card >
        <v-card-text v-if="!loading">
            <v-row align="center">
                <v-col cols="12" 
                        v-for="component in components" 
                        :key="component.key" 
                        :md="component.cols"
                        :class="component.class"
                >
                    <v-text-field v-if="component.type == 'textfield'"
                        :label="component.label"
                        :placeholder="component.placeholder"
                        v-model="component.value"
                    ></v-text-field>

                    <label v-if="component.type == 'label'">
                        {{component.label}}
                    </label>

                    <label v-if="component.type == 'label_html'" v-html="component.label">
                       
                    </label>

                    <v-checkbox
                        v-if="component.type == 'checkbox'"
                        v-model="component.value"
                        :label="component.label"
                    ></v-checkbox>

                    <v-btn
                        style="display: block; margin: auto;"
                        v-if="component.type == 'button_check'"
                        v-model="component.value"
                        @click="component.value ? component.value = false : component.value = true"
                        :class="component.value ? 'primary' : ''"
                        :label="component.label"
                    >{{component.label}}</v-btn>

                    <v-textarea v-if="component.type == 'textarea'"
                        v-model="component.value"
                        outlined
                    >
                    </v-textarea>

                    <v-menu
                        v-if="component.type == 'date'"
                        v-model="component.menu_model"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="component.value"
                                :label="component.label"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="component.value"
                            @input="component.menu_model = false"
                        ></v-date-picker>
                    </v-menu>

                    <v-dialog
                        v-if="component.type == 'time'"
                        ref="dialog"
                        v-model="timeModal"
                        :return-value.sync="component.value"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="component.value"
                            :label="component.label"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-time-picker
                        format="24hr"
                        v-if="timeModal"
                        v-model="component.value"
                        full-width
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="timeModal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs['dialog'].save(component.value)"
                        >
                            OK
                        </v-btn>
                        </v-time-picker>
                    </v-dialog>
                    

                    <v-divider v-if="component.type == 'divider'"></v-divider>

                </v-col>
            </v-row>
        </v-card-text>
        
        <v-speed-dial
            v-model="fab"
            class="mb-8 mr-4"
            style="position: fixed; bottom: 0; right: 0;"
            :open-on-hover="hover"
            :transition="transition"
            >
            <template v-slot:activator>
                <v-btn
                v-model="fab"
                color="warning"
                dark
                fab
                >
                <v-icon v-if="fab">
                    mdi-close
                </v-icon>
                <v-icon v-else>
                    mdi-cog-outline
                </v-icon>
                </v-btn>
            </template>
            <Tooltip
                class="my-4 mr-4"
                color="primary"
                text="Gravar"
                top
            >
                <v-btn
                    fab
                    dark
                    small
                    color="primary"
                    @click="submit()"
                >
                    <v-icon>mdi-content-save-all</v-icon>
                </v-btn>
            </Tooltip>
            <Tooltip
                class="my-4 mr-4"
                color="error"
                text="Exportar PDF"
                top
            >
                <v-btn
                    fab
                    dark
                    small
                    @click="exportModel('PDF')"
                    color="error"
                >
                    <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
            </Tooltip>
            <Tooltip
                v-if="false"
                class="my-4 mr-4"
                color="success"
                text="Exportar Excel"
                top
            >
                <v-btn
                    fab
                    dark
                    small
                    color="success"
                    @click="exportModel('Excel')"
                >
                    <v-icon>mdi-file-excel-box</v-icon>
                </v-btn>
            </Tooltip>
            </v-speed-dial>
        <DialogSuccess :opened="success" @on-ok="$emit('close')" >
            Modelo gravado com sucesso
        </DialogSuccess>
    </v-card>
    </v-dialog>
</template>
<script>
import Models from '@/api/Models.js'
import Export from '@/api/Export.js'
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Tooltip from '@/components/ui/Tooltip.vue';

export default{
    name: "Mod007EQ",
    components:{
        DialogSuccess,
        Tooltip
    },
    props:{
        machine:{
            typeof: Object,
            default: null,
        },
        model_id: {
            typeof: String,
            default: null
        }
    },
    data(){
        return{
            timeModal: false,
            fab: false,
            hover: false,
            transition: 'slide-y-reverse-transition',
            loading: true,
            dialog: true,
            method: 'create',
            components: [],
            success:false,
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{
        exportModel(type){
            let data = {
                type: type,
                model: 'Mod007EQ',
                model_id: this.model_id
            }

            Models.export(data).then(async (resp) => {
                let caminho = data.type == 'PDF' ? resp.data.object.url : resp.data.object
                let remover = data.type == 'PDF' ? resp.data.object.path : resp.data.object
                window.open(caminho, '_blank')
                Export.delete(remover)
                .then(() => {
                    
                });
            });
        },
        submit(){
            let data = {
                machine_id: this.$route.params.id,
                model_name: 'Mod007EQ',
                metadata: this.components
            }
            Models[this.method](data, this.model_id).then(async () => {
                this.success = true

            });
        },
        async fillBaseData(){
            if(this.model_id != null){
                this.method = 'update'
                await Models.find(this.model_id)
                .then((resp) => {
                    this.components = JSON.parse(resp.data.metadata)
                    
                });
            }
            else this.constructComponent()

            this.loading = false
        },
        constructComponent(){
            this.components = [
            {
                type: "textfield",
                key: "designacao",
                placeholder: "",
                label: "Designação",
                value: this.machine.name,
                show_both: true,
                header: 'normal-60',
                table_body: 'header',
                cols: 8,
                class: ""
            },
            {
                type: "textfield",
                key: "numero",
                placeholder: "",
                label: "Número",
                value: this.machine.code,
                show_both: true,
                header: 'normal-20',
                table_body: 'header',
                cols: 2,
                class: ""
            },
            {
                type: "textfield",
                key: "ano_fabrico",
                placeholder: "",
                label: "Ano fabrico",
                value: this.machine.manufacture_year,
                show_both: true,
                header: 'normal-20',
                table_body: 'header',
                cols: 2,
                class: ""
            },
            {
                type: "textfield",
                key: "marca",
                placeholder: "",
                label: "Marca",
                value: "",
                show_both: true,
                header: 'normal-60',
                table_body: 'header',
                cols: 8,
                class: ""
            },
            {
                type: "textfield",
                key: "modelo",
                placeholder: "",
                label: "Modelo",
                value: "",
                show_both: true,
                header: 'normal-20',
                table_body: 'header',
                cols: 2,
                class: ""
            },
            {
                type: "textfield",
                key: "n_serie",
                placeholder: "",
                label: "Nº série",
                value: this.machine.serial_number,
                show_both: true,
                header: 'normal-20',
                table_body: 'header',
                cols: 2,
                class: ""
            },
            {
                type: "textfield",
                key: "local_insp",
                placeholder: "",
                label: "Local de Inspeção",
                value: "",
                show_both: true,
                local: true,
                header: 'normal-40',
                table_body: 'local',
                cols: 6,
                class: ""
            },
            {
                type: "checkbox",
                key: "checkbox_initial",
                placeholder: "",
                label: "Inicial",
                local: true,
                value: "",
                show_both: true,
                header: 'normal-20',
                table_body: 'local',
                cols: 2,
                class: ""
            },
            {
                type: "checkbox",
                key: "checkbox_periodica",
                placeholder: "",
                label: "Periódica",
                local: true,
                value: "",
                show_both: true,
                header: 'normal-20',
                table_body: 'local',
                cols: 2,
                class: ""
            },
            {
                type: "checkbox",
                key: "checkbox_extra",
                placeholder: "",
                label: "Extraordinária",
                local: true,
                value: "",
                show_both: true,
                header: 'normal-20',
                table_body: 'local',
                cols: 2,
                class: ""
            },


            
            {
                type: "label",
                key: "id_equipamento",
                placeholder: "",
                label: "Identificação do equipamento",
                value: "",
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                cols: 7,
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_equipamento_c",
                placeholder: "",
                label: "C",
                value: "",
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                cols: 1,
                class: "d-none d-lg-block d-none d-lg-block orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_equipamento_nc",
                placeholder: "",
                label: "NC",
                value: "",
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                cols: 1,
                class: "d-none d-lg-block d-none d-lg-block orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_equipamento_na",
                placeholder: "",
                label: "NA",
                value: "",
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                cols: 1,
                class: "d-none d-lg-block d-none d-lg-block orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_equipamento_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                cols: 2,
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_equipamento_text_1",
                placeholder: "",
                label: "Presença de placa de identificação / Marcação CE",
                value: "",
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                cols: 7,
                class: ""
            },
            {
                type: "button_check",
                key: "id_equipamento_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                cols: 1,
                class: ""
            },
            {
                type: "button_check",
                key: "id_equipamento_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                cols: 1,
                class: ""
            },
            {
                type: "button_check",
                key: "id_equipamento_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                cols: 1,
                class: ""
            },
            {
                type: "textfield",
                key: "id_equipamento_text_field",
                placeholder: "",
                label: "Obs",
                value: "",
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                cols: 2,
                class: ""
            },
            {
                type: "label",
                key: "id_sistemas_comando",
                placeholder: "",
                label: "Sistemas de comando ",
                value: "",
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                cols: 7,
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sistemas_comando_c",
                placeholder: "",
                label: "C",
                value: "",
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                cols: 1,
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sistemas_comando_nc",
                placeholder: "",
                label: "NC",
                value: "",
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                cols: 1,
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sistemas_comando_na",
                placeholder: "",
                label: "NA",
                value: "",
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                cols: 1,
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sistemas_comando_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                cols: 2,
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sistemas_comando_text_1",
                placeholder: "",
                label: "Identificação dos comandos / Estado dos comandos ",
                value: "",
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                cols: 7,
                class: ""
            },
            {
                type: "button_check",
                key: "id_sistemas_comando_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                cols: 1,
                class: ""
            },
            {
                type: "button_check",
                key: "id_sistemas_comando_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                cols: 1,
                class: ""
            },
            {
                type: "button_check",
                key: "id_sistemas_comando_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                cols: 1,
                class: ""
            },
            {
                type: "textfield",
                key: "id_sistemas_comando_text_field",
                placeholder: "",
                label: "Obs",
                value: "",
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                cols: 2,
                class: ""
            },
            {
                type: "label",
                key: "id_arranque_do_sistema",
                placeholder: "",
                label: "Arranque do equipamento ",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_arranque_do_sistema_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_arranque_do_sistema_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_arranque_do_sistema_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_arranque_do_sistema_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_arranque_do_sistema_text_1",
                placeholder: "",
                label: "Arranques intempstivos",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_arranque_do_sistema_check_box_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_arranque_do_sistema_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_arranque_do_sistema_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_arranque_do_sistema_text_field",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "label",
                key: "id_paragem_equipamento",
                placeholder: "",
                label: "Paragem do equipamento",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_paragem_equipamento_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_paragem_equipamento_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_paragem_equipamento_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_paragem_equipamento_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_paragem_equipamento_text_1",
                placeholder: "",
                label: "Paragem de emergência (se disco corte paragem < 10seg)",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_paragem_equipamento_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_paragem_equipamento_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_paragem_equipamento_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_paragem_equipamento_text_field_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_1",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            {
                type: "label",
                key: "id_paragem_equipamento_text_2",
                placeholder: "",
                label: "Corte geral",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_paragem_equipamento_checkbox_4",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_paragem_equipamento_checkbox_5",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_paragem_equipamento_checkbox_6",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_paragem_equipamento_text_field_2",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            
            {
                type: "label",
                key: "id_estabilidade_rutura",
                placeholder: "",
                label: "Estabilidade e rutura",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_estabilidade_rutura_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_estabilidade_rutura_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_estabilidade_rutura_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_estabilidade_rutura_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },

            
            {
                type: "label",
                key: "id_estabilidade_rutura_text_1",
                placeholder: "",
                label: "Estrutura em geral",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_estabilidade_rutura_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_estabilidade_rutura_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_estabilidade_rutura_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_estabilidade_rutura_obs_text_field_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_2",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_estabilidade_rutura_text_2",
                placeholder: "",
                label: "Montagem equipamento - tem meios adequados de fixação e/ou estabilidade adequada ",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_estabilidade_rutura_checkbox_4",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_estabilidade_rutura_checkbox_5",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_estabilidade_rutura_checkbox_6",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_estabilidade_rutura_obs_text_field_2",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "label",
                key: "id_projecoes_emanacoes",
                placeholder: "",
                label: "Projeções e emanações",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_projecoes_emanacoes_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_projecoes_emanacoes_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_projecoes_emanacoes_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_projecoes_emanacoes_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },

            
            
            {
                type: "label",
                key: "id_projecoes_emanacoes_text_1",
                placeholder: "",
                label: "Proteções contra projeção de objetos",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_projecoes_emanacoes_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_projecoes_emanacoes_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_projecoes_emanacoes_obs_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_projecoes_emanacoes_obs_text_field_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            

            {
                type: "label",
                key: "id_risco_mecanico",
                placeholder: "",
                label: "Risco de contacto mecânico",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_risco_mecanico_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_risco_mecanico_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_risco_mecanico_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_risco_mecanico_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                cols: 2,
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },

            

            
            
            {
                type: "label",
                key: "id_risco_mecanico_text_1",
                placeholder: "",
                label: "Proteção dos órgãos moveis / outros riscos sem proteção",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico__checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_risco_mecanico_obs_textfield_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            
            {
                type: "divider",
                key: "id_divider_3",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            

            
            
            {
                type: "label",
                key: "id_risco_mecanico_text_2",
                placeholder: "",
                label: "Tampas / portas de acesso / dispositivos de retenção",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_4",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_5",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_6",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_risco_mecanico_obs_textfield_2",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            
            
            {
                type: "divider",
                key: "id_divider_4",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            

            
            
            {
                type: "label",
                key: "id_risco_mecanico_text_3",
                placeholder: "",
                label: "Paragem da máquina na abertura das proteções móveis / Rearme",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_7",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_8",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_9",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_risco_mecanico_obs_textfield_3",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            
            
            {
                type: "divider",
                key: "id_divider_5",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            

            
            
            {
                type: "label",
                key: "id_risco_mecanico_text_4",
                placeholder: "",
                label: "Dispositivos de encravamento –são adequados e sem possibilidade de anular através de ação voluntaria (D.L. n.º 103/08 – Ponto 1.4.2.2)",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_10",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_11",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_risco_mecanico_checkbox_12",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_risco_mecanico_obs_textfield_4",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            

            

            {
                type: "label",
                key: "id_iluminacao_temperatura",
                placeholder: "",
                label: "Iluminação e temperatura",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_iluminacao_temperatura_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_iluminacao_temperatura_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_iluminacao_temperatura_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_iluminacao_temperatura_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            

            
            
            {
                type: "label",
                key: "id_iluminacao_temperatura_text_1",
                placeholder: "",
                label: "Proteções térmicas em zonas de temperaturas altas  ",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_iluminacao_temperatura_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_iluminacao_temperatura_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_iluminacao_temperatura_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_iluminacao_temperatura_obs_textfield_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            

            

            

            {
                type: "label",
                key: "id_dispositivos_alerta",
                placeholder: "",
                label: "Dispositivos de alerta",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_dispositivos_alerta_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_dispositivos_alerta_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_dispositivos_alerta_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_dispositivos_alerta_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            
            
            {
                type: "label",
                key: "id_dispositivos_alerta_textfield_1",
                placeholder: "",
                label: "Dispositivos de alerta do estado ativo, estado bloqueio, disparo",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_dispositivos_alerta_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_dispositivos_alerta_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_dispositivos_alerta_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_dispositivos_alerta_obs_textfield_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            

            {
                type: "label",
                key: "id_eletric_risk_fire",
                placeholder: "",
                label: "Riscos elétricos, incêndio e explosão",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_eletric_risk_fire_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_eletric_risk_fire_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_eletric_risk_fire_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_eletric_risk_fire_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            
            {
                type: "label",
                key: "id_eletric_risk_fire_textfield_1",
                placeholder: "",
                label: "Isolamento cablagens, invólucros, quadros elétricos, tomadas",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_eletric_risk_fire_obs_textfield_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_6",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_eletric_risk_fire_textfield_2",
                placeholder: "",
                label: "Proteção adequada – fusíveis, disjuntores, térmicos, interruptor diferencial",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_4",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_5",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_6",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_eletric_risk_fire_obs_textfield_2",
                placeholder: "",
                label: "Obs",
                value: "B)",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_7",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            
            {
                type: "label",
                key: "id_eletric_risk_fire_textfield_3",
                placeholder: "",
                label: "Ligação à terra de todas as massas metálicas / Borne Terra",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_7",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_8",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_9",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_eletric_risk_fire_obs_textfield_3",
                placeholder: "",
                label: "Obs",
                value: "B)",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_8",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_eletric_risk_fire_textfield_4",
                placeholder: "",
                label: "Fixação das cablagens e outros órgãos",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_10",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_11",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_12",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_eletric_risk_fire_obs_textfield_4",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_9",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_eletric_risk_fire_textfield_5",
                placeholder: "",
                label: "Ligações elétricas, bornes, tomadas, secções cablagens",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_13",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_14",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_15",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_eletric_risk_fire_obs_textfield_5",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_10",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_eletric_risk_fire_textfield_6",
                placeholder: "",
                label: "Ensaios elétricos – continuidade, terra, isolamento, tensão",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_16",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_17",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_eletric_risk_fire_checkbox_18",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_eletric_risk_fire_obs_textfield_6",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            
            {
                type: "label",
                key: "id_fontes_energia",
                placeholder: "",
                label: "Fontes de energia",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_fontes_energia_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_fontes_energia_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_fontes_energia_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_fontes_energia_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            
            {
                type: "label",
                key: "id_fontes_energia_textfield_1",
                placeholder: "",
                label: "Fugas sistemas de óleo / hidráulico / combustível ",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_fontes_energia_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_fontes_energia_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_fontes_energia_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_fontes_energia_obs_textfield_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_13",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_fontes_energia_textfield_2",
                placeholder: "",
                label: "Válvula limitadora de pressão",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_fontes_energia_checkbox_4",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_fontes_energia_checkbox_5",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_fontes_energia_checkbox_6",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_fontes_energia_obs_textfield_2",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_14",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_fontes_energia_textfield_3",
                placeholder: "",
                label: "Fixação das baterias / órgãos elétricos / Corte de Corrente ",
                value: "",
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                cols: 7,
                class: ""
            },
            {
                type: "button_check",
                key: "id_fontes_energia_checkbox_7",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_fontes_energia_checkbox_8",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_fontes_energia_checkbox_9",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_fontes_energia_obs_textfield_3",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            
            {
                type: "label",
                key: "id_sinalizacao_seguranca",
                placeholder: "",
                label: "Sinalização de segurança",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sinalizacao_seguranca_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sinalizacao_seguranca_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sinalizacao_seguranca_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_sinalizacao_seguranca_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            
            {
                type: "label",
                key: "id_sinalizacao_seguranca_textfield_1",
                placeholder: "",
                label: "Acesso interdito a pessoas não autorizadas",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_sinalizacao_seguranca_obs_textfield_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_15",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_sinalizacao_seguranca_textfield_2",
                placeholder: "",
                label: "Sinalização de reservatórios de água, combustível, óleos, etc.",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_4",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_5",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_6",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_sinalizacao_seguranca_obs_textfield_2",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_16",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
             
            {
                type: "label",
                key: "id_sinalizacao_seguranca_textfield_3",
                placeholder: "",
                label: "Sinalização de bateria  ",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_7",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_8",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_9",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_sinalizacao_seguranca_obs_textfield_3",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_17",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_sinalizacao_seguranca_textfield_4",
                placeholder: "",
                label: "Sinalização de outros riscos (quadro elétrico, perigo de corte, esmagamento, temperatura, etc.)",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_10",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_11",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_12",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_sinalizacao_seguranca_obs_textfield_4",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_18",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
               
            {
                type: "label",
                key: "id_sinalizacao_seguranca_textfield_5",
                placeholder: "",
                label: "Sinalização dos pontos de elevação / amarração / fixação",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_13",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_14",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_sinalizacao_seguranca_checkbox_15",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_sinalizacao_seguranca_obs_textfield_5",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            
            {
                type: "label",
                key: "id_documentacao",
                placeholder: "",
                label: "Documentação",
                value: "",
                cols: 7,
                show_both: false,
                header: 'header-60',
                table_body: 'body',
                class: "orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_documentacao_c",
                placeholder: "",
                label: "C",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_documentacao_nc",
                placeholder: "",
                label: "NC",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_documentacao_na",
                placeholder: "",
                label: "NA",
                value: "",
                cols: 1,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },
            {
                type: "label",
                key: "id_documentacao_obs",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'header-10',
                table_body: 'body',
                class: "d-none d-lg-block orange white--text font-weight-bold"
            },

            
            {
                type: "label",
                key: "id_documentacao_textfield_1",
                placeholder: "",
                label: "Manual em Português",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_1",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_2",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_3",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_documentacao_obs_textfield_1",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_19",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_documentacao_textfield_2",
                placeholder: "",
                label: "Planos de manutenção",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_4",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_5",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_6",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_documentacao_obs_textfield_2",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_20",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_documentacao_textfield_3",
                placeholder: "",
                label: "Registo da última manutenção",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_7",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_8",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_9",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_documentacao_obs_textfield_3",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_21",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },

            
            
            {
                type: "label",
                key: "id_documentacao_textfield_4",
                placeholder: "",
                label: "Marcação e declaração CE em português (após 1995)",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_10",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_11",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_12",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_documentacao_obs_textfield_4",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_22",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            
            {
                type: "label",
                key: "id_documentacao_textfield_5",
                placeholder: "",
                label: "Esquemas elétricos",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_13",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_14",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_15",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_documentacao_obs_textfield_5",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "divider",
                key: "id_divider_23",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            {
                type: "label",
                key: "id_documentacao_textfield_6",
                placeholder: "",
                label: "Esquemas hidráulicos",
                value: "",
                cols: 7,
                show_both: false,
                header: 'normal-60',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_16",
                placeholder: "",
                label: "C",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_17",
                placeholder: "",
                label: "NC",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "button_check",
                key: "id_documentacao_checkbox_18",
                placeholder: "",
                label: "NA",
                value: null,
                cols: 1,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },
            {
                type: "textfield",
                key: "id_documentacao_obs_textfield_6",
                placeholder: "",
                label: "Obs",
                value: "",
                cols: 2,
                show_both: false,
                header: 'normal-10',
                table_body: 'body',
                class: ""
            },

            {
                type: "divider",
                key: "id_divider_24",
                placeholder: "",
                label: "",
                value: "",
                cols: 12,
                class: ""
            },
            {
                type: "label",
                key: "id_observations",
                placeholder: "",
                label: "Observações",
                value: "",
                show_both: false,
                header: 'header-100',
                table_body: 'observations',
                cols: 12,
                class: "font-weight-bold"
            },
            
            {
                type: "textarea",
                key: "id_obs_textarea",
                placeholder: "",
                label: "",
                value: "",
                show_both: false,
                header: 'normal-100',
                table_body: 'observations',
                cols: 12,
                class: ""
            },

            {
                type: "label_html",
                key: "id_point_a",
                placeholder: "",
                label: "A)&emsp;Deve ser assegurada a correta instalação e fixação do equipamento para impedir riscos para o operador.",
                value: "",
                cols: 12,
                show_both: false,
                header: 'normal-100',
                table_body: 'note-1',
                class: "font-weight-medium"
            },
            {
                type: "label_html",
                key: "id_point_b",
                placeholder: "",
                label: "B)&emsp;<u>Se gerador móvel:</u> este equipamento só deve ser utilizado com a ligação à terra do elétrodo que o acompanha. Se potência superior a 10KVA deve ter proteção diferencial de 30mA. <br/>&emsp;&emsp;<u>Se gerador fixo:</u> este equipamento só pode ser usado com a ligação adequada à terra realizada por eletricista, com medição e registo do valor de terra e proteção. <br/>&emsp;&emsp;<u>Diferencial de 30mA:</u> Deve ser assegurado em obra a vedação do equipamento para garantir um perímetro de segurança bem identificado. ",
                value: "",
                show_both: false,
                header: 'normal-100',
                table_body: 'note-1',
                cols: 12,
                class: "font-weight-medium"
            },

            
            {
                type: "label",
                key: "id_resultado",
                placeholder: "",
                label: "Resultado",
                value: "",
                cols: 12,
                show_both: false,
                header: 'header-100',
                table_body: 'result',
                class: "orange white--text font-weight-bold text-center"
            },

            {
                type: "label",
                key: "id_equipamento_pergunta",
                placeholder: "",
                label: "O equipamento pode ser utilizado pelos trabalhadores?",
                value: "",
                show_both: false,
                header: 'normal-80',
                table_body: 'result-2',
                cols: 8,
                class: "text-center"
            },

            {
                type: "button_check",
                key: "id_equipamento_pergunta_checkbox_1",
                placeholder: "",
                label: "Sim",
                value: "",
                show_both: false,
                header: 'normal-20',
                table_body: 'result-2',
                cols: 2,
                class: "text-center"
            },

            {
                type: "button_check",
                key: "id_equipamento_pergunta_checkbox_2",
                placeholder: "",
                label: "Não",
                value: "",
                show_both: false,
                header: 'normal-20',
                table_body: 'result-2',
                cols: 2,
                class: "text-center"
            },

            {
                type: "date",
                key: "id_data_limite_correcao",
                placeholder: "",
                label: "Data limite para correção",
                value: "",
                cols: 4,
                class: "text-center",
                show_both: true,
                header: 'normal-30',
                table_body: 'dates',
                menu_model: false
            },

            {
                type: "date",
                key: "id_data_implementacao",
                placeholder: "",
                label: "Data de implementação da correção",
                value: "",
                cols: 4,
                class: "text-center",
                show_both: true,
                header: 'normal-30',
                table_body: 'dates',
                menu_model: false
            },

            {
                type: "textfield",
                key: "id_rubrica",
                placeholder: "",
                label: "Rubrica (Quem efetuou a correção)",
                value: "",
                cols: 4,
                show_both: true,
                header: 'normal-30',
                table_body: 'dates',
                class: "text-center"
            },

            {
                type: "label_html",
                key: "id_nota",
                placeholder: "",
                label: "<strong>NOTA I:</strong> Esta inspeção foi efetuada no âmbito do DL n.º 50/2005, de 25 de fevereiro – Capítulo II e normas aplicáveis. ",
                value: "",
                cols: 12,
                show_both: true,
                header: 'normal-100',
                table_body: 'dates',
                class: "text-center"
            },

            {
                type: "label",
                key: "id_proxima_inspecao",
                placeholder: "",
                label: "Próxima inspeção",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "orange white--text font-weight-bold text-center"
            },

            {
                type: "date",
                key: "id_data_proxima_inspecao",
                placeholder: "",
                label: "Data próxima inspeção",
                value: "",
                cols: 6,
                class: "text-center",
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                menu_model: false
            },

            {
                type: "label",
                key: "id_operador",
                placeholder: "",
                label: "Operador",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "d-none d-lg-block mt-4 orange white--text font-weight-bold text-center"
            },

            {
                type: "label",
                key: "id_inspetor",
                placeholder: "",
                label: "Inspetor",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "d-none d-lg-block mt-4 orange white--text font-weight-bold text-center"
            },
            {
                type: "textfield",
                key: "id_operador_nome",
                placeholder: "",
                label: "Nome operador",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: ""
            },
            {
                type: "textfield",
                key: "id_inspetor_nome",
                placeholder: "",
                label: "Nome inspetor",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: ""
            },
            {
                type: "textfield",
                key: "id_operador_assinatura",
                placeholder: "",
                label: "Assinatura operador",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: ""
            },
            {
                type: "textfield",
                key: "id_inspetor_assinatura",
                placeholder: "",
                label: "Assinatura inspetor",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: ""
            },

            
            {
                type: "label",
                key: "id_data",
                placeholder: "",
                label: "Data",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "d-none d-lg-block mt-4 orange white--text font-weight-bold text-center"
            },
            {
                type: "label",
                key: "id_hora",
                placeholder: "",
                label: "Hora",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "d-none d-lg-block mt-4 orange white--text font-weight-bold text-center"
            },

            {
                type: "date",
                key: "id_data_assinatura",
                placeholder: "",
                label: "Data",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "text-center",
                menu_model: false
            },

            {
                type: "time",
                key: "id_hora_assinatura",
                placeholder: "",
                label: "Hora",
                value: "",
                cols: 6,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "text-center",
                menu_model: false
            },
            {
                type: "label_html",
                key: "id_nota_2",
                placeholder: "",
                label: "<strong>NOTA II:</strong> Fica excluída da emissão deste relatório qualquer responsabilidade de mau uso e/ou não cumprimento das normas de utilização do equipamento constantes do respetivo manual de utilização / operador.",
                value: "",
                cols: 12,
                show_both: false,
                header: 'normal-100',
                table_body: 'dates',
                class: "text-center"
            },
        ];
        }
    }
}
</script>