var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mt-16"},[_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"large":"","items":_vm.crumbs,"divider":"/"}}),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("Gestão de logs")]),_c('v-row',[_c('v-col',[_c('div',{staticStyle:{"position":"relative"}},[_c('v-container',[_c('v-speed-dial',{attrs:{"absolute":"","top":"","right":"","direction":"bottom","open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","x-large":"","fab":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},'v-btn',attrs,false),on),[(_vm.speedDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('span',[_vm._v("Opções")])])]},proxy:true}]),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"secondary","large":""},on:{"click":function($event){_vm.searching = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v("Pesquisar")])])],1)],1),_c('v-data-table',{staticClass:"mt-16",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}}])})],1)])],1)],1)],1)],1),_c('v-navigation-drawer',{staticClass:"pt-6",attrs:{"fixed":"","right":"","hide-overlay":"","width":"520"},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){_vm.searching = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"absolute":"","right":"","small":"","color":"error"},on:{"click":_vm.resetFields}},[_vm._v(" Limpar Campos ")])],1)],1),_c('v-list',{staticClass:"pt-10"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchLogs.apply(null, arguments)}}},[_c('v-list-item',[_c('v-autocomplete',{attrs:{"items":_vm.speedDialOptions.logActions,"prepend-inner-icon":"mdi-barcode","label":"Tipo"},model:{value:(_vm.filter.logActions),callback:function ($$v) {_vm.$set(_vm.filter, "logActions", $$v)},expression:"filter.logActions"}})],1),_c('v-list-item',[_c('v-autocomplete',{attrs:{"items":_vm.speedDialOptions.users,"prepend-inner-icon":"mdi-text","item-text":function (item) { return item.username; },"item-value":"id","label":"Utilizador"},model:{value:(_vm.filter.logUsers),callback:function ($$v) {_vm.$set(_vm.filter, "logUsers", $$v)},expression:"filter.logUsers"}})],1),_c('v-row',{staticClass:"mt-10",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" Pesquisar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }