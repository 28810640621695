<template>
  <div>
    <apexcharts
      :key="chartKey"
      :height="height"
      :options="getOptions"
      :series="series"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'uiChart',
  components: {
    apexcharts: VueApexCharts,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    height: {
      type: String,
      default: '400',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartKey: 0,
    }
  },
  watch: {
    '$vuetify.theme.isDark': {
      handler() {
        this.chartKey += 1
      }
    }
  },
  computed: {
    getOptions() {
      // eslint-disable-next-line no-undef
      return _.merge(this.baseOptions, this.options)
    },
    baseOptions() {
      return {
        theme: {
          mode: this.$vuetify.theme.isDark ? 'dark' : 'light',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            enabled: true,
            inverseColors: true,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.95,
            opacityTo: 0.75,
            stops: [0, 100],
          },
        },
        markers: {
          size: 6,
          hover: {
            size: 6,
            sizeOffset: 3,
          },
        },
        chart: {
          background: this.$vuetify.theme.isDark ? '#1E1E1E' : '#FFF',
          toolbar: {
            show: true,
            tools: {
              download: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
            autoSelected: 'zoom',
          },
        },
        tooltip: {
          enabled: true,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          offsetY: 10,
        },
      }
    },
  },
}
</script>

<style scoped></style>
