<template>
    <v-card height="600px">
        <v-row v-if="loading">
            <v-col cols="12">
                <p>Loading...</p>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" md="2" v-for="(item, index) in images" :key="index">
                <v-img
                    style="display: block; margin: auto;"
                    contain
                    width="150"
                    :src="getImage(item)"
                />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import Work from '@/api/Work.js'

export default {
    name: "WorkImages",
    data() {
        return {
            loading: true,
            images: []
        }
    },
    mounted() {
        this.fillBaseData()
    },
    methods: {
        getImage(item){
            let params = {
                id: item
            }
            Work.getSingleImage(params, params.id).then((resp)  =>  {
                return resp.data
            })
        },
        fillBaseData() {
            let params = {
                id: this.$route.params.id
            }

            Work.loadImages(params, params.id).then((resp) => {
                console.log("Imagens:", resp.data); // Verifique o conteúdo aqui

                // Filtra apenas os URLs das imagens
                if (resp.data && Array.isArray(resp.data)) {
                    this.images = resp.data.map(item => item.url || item); // Adicione esta linha
                } else {
                    console.error("Formato inesperado de resposta:", resp.data);
                    this.images = [];
                }

                this.loading = false;
            }).catch(error => {
                console.error("Erro ao carregar imagens:", error);
                this.loading = false;
            });
        }
    }
}
</script>
