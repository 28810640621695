var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.itemsTotal,"loading":_vm.loading,"footer-props":_vm.footer,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.document",fn:function(ref){
var item = ref.item;
return [(item.file_path != null)?_c('IconExport',{staticClass:"mr-2",attrs:{"text":"Download"},on:{"click":function($event){return _vm.downloadFile(item.file_path)}}}):_vm._e()]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$root.session.hasPermission(['super', 'breakdown.update']))?_c('v-btn',{attrs:{"x-small":"","text":""},on:{"click":function($event){return _vm.$emit('view', item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-eye")]),_vm._v(" "+_vm._s(item.code)+" ")],1):_vm._e()],1)],1)]}},{key:"item.corrective",fn:function(ref){
var item = ref.item;
return [(item.corrective != null)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"x-small":"","text":""},on:{"click":function($event){return _vm.$emit('view', item.corrective)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-eye")]),_vm._v(" "+_vm._s(item.corrective.breakdown_date)+" ")],1)],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$root.session.hasPermission(['super', 'breakdown.delete']))?_c('IconRemove',{staticClass:"mr-2",on:{"on-submit":function($event){return _vm.deleteIntervention(item)}}}):_vm._e()]}}])}),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":function($event){_vm.success=false;}}},[_vm._v(" Intervenção removida com sucesso! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }